import React, { useEffect, useState } from 'react'
import AutocompleteField from '../AutocompleteField'
import { Grid, Tab, Tabs, Chip } from '@material-ui/core'
import ReportDisplay from './reportDisplay'
import * as reportActions from '../../actions/ReportActions'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { useSetFrameHeaderMid } from '../AppFrame'

export default function ReferrerReports() {
  const [autoCompleteReferrerList, setAutoCompleteReferrerList] = useState([])
  const [selectedReferrerID, setSelectedReferrerID] = useState<number | null>(
    null
  )
  const [selectedReportHandle, setSelectedReportHandle] = useState<
    string | null
  >(null)
  const [reportList, setReportList] = useState([])
  const [reportURL, setReportURL] = useState<string | null>(null)
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    setSelectedReportHandle(null)
    setReportList([])
    setReportURL(null)
    loadReferrerList()
  }, [])

  useEffect(() => {
    if (!selectedReferrerID) return
    setSelectedReportHandle(null)
    setReportList([])
    setReportURL(null)
    loadReportsByReferrerID(selectedReferrerID)
  }, [selectedReferrerID])

  useEffect(() => {
    if (!selectedReferrerID || !selectedReportHandle) {
      setReportURL(null)
      return
    }
    loadReportData(selectedReferrerID, selectedReportHandle)
  }, [selectedReferrerID, selectedReportHandle])

  useSetFrameHeaderMid(() => {
    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: '0.5rem',
        }}>
        <AutocompleteField
          label="Referrer"
          value={selectedReferrerID}
          onChange={(orgID: any) => {
            setSelectedReportHandle(null)
            setSelectedReferrerID(orgID || null)
          }}
          options={autoCompleteReferrerList}
          PassthroughProps={{
            defaultValue: null,
            getOptionSelected: (v: any): boolean =>
              +v.value === selectedReferrerID,
          }}
          style={{ width: 320 }}
        />
        {reportList.map((r: any) => (
          <div key={r.Handle}>
            <Chip
              label={r.Name}
              color={r.Handle === selectedReportHandle ? 'primary' : 'default'}
              onClick={() => setSelectedReportHandle(r.Handle)}
            />
          </div>
        ))}
      </div>
    )
  }, [
    selectedReferrerID,
    autoCompleteReferrerList,
    reportList,
    selectedReportHandle,
  ])

  function loadReferrerList() {
    return reportActions
      .getReportableReferrers()
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setAutoCompleteReferrerList(
          res.Data.map((rfr: any) => ({
            name: rfr.ReferrerName,
            value: rfr.ReferrerID,
          }))
        )
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar('Failed loading list of referrers', SnackbarTypeError)
      })
  }

  function loadReportsByReferrerID(rfrID: number) {
    return reportActions
      .getReportsByReferrerID(rfrID)
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setReportList(res.Data)
        setSelectedReportHandle(res.Data[0].Handle)
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar(
          'Failed loading reports for selected referrer',
          SnackbarTypeError
        )
      })
  }

  function loadReportData(rfrID: number, reportHandle: string) {
    return reportActions
      .getReferrerReport(rfrID, reportHandle)
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setReportURL(res.Data.URL)
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar('Failed loading report', SnackbarTypeError)
      })
  }

  return <>{reportURL && <ReportDisplay reportURL={reportURL} />}</>
}
