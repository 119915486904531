import React, { useState, useEffect } from 'react'
import { ackActivity } from '../../actions/ActivityActions'
import { redactConversationMessageByID } from '../../actions/ConversationActions'
import { UserAvatar } from '../UserAvatar'
import { APIErrorResponse, APIResponse } from '../../services/ApiService'
import { getUserInfo } from '../../services/UserService'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import { MentionDisplayer } from '../Mention'
import { ConversationMessage, ConversationUser } from './types'
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core'
import { Done as IconDone, DoneAll as IconDoneAll } from '@material-ui/icons'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'

export const StyledMessageCard = styled(Card)`
  margin-bottom: 15px;
  width: 100%;
  background: #f7f7f7 !important;

  &:last-of-type {
    margin-bottom: 0;
  }

  [class*='convo-card'] {
    background: transparent;
    padding: 0.75rem;
  }

  .convo-card-header,
  .convo-card-content {
    border-bottom: 1px dashed #ddd;
  }

  .convo-card-content {
    padding: 0.75rem 1.5rem;
  }
`

const StyledCardActions = styled(CardActions)`
  justify-content: space-between;

  .inlined {
    display: inline-flex;
    align-items: center;
  }

  .icon-ackd {
    margin-right: 0.35rem;
    color: rgba(0, 0, 0, 0.6);
  }
`

interface props {
  message: ConversationMessage

  refreshConvo(): void

  canRedact?: boolean
}

export default function DisplayConversationMessage({
  message,
  refreshConvo = () => {}, // no-op
  canRedact = false,
}: props & Partial<any>): React.ReactElement {
  const [ackd, setAckd] = useState<boolean>(false)
  const { show: showSnackbar, showForDuration } = useSnackbar()

  const ackHandler = () => {
    ackActivity({ id: message.ActivityID })
      .then(() => {
        setAckd(true)
      })
      .catch(() => {
        showSnackbar(
          'Someone has already acknowledged this activity, please try refreshing the page or wait for it to update.',
          SnackbarTypeError
        )
        return null
      })
  }

  const postedByUser = (authorId: number) => {
    const userInfo: any = getUserInfo()

    return userInfo && userInfo.ID === authorId
  }

  const needsAck = (canAck: boolean, ackd: boolean) => {
    return canAck && !ackd
  }

  const hasBeenAckd = (
    ackdOn: string | undefined,
    ackdBy: ConversationUser | undefined
  ) => {
    return ackdOn && ackdBy && ackdBy.ID
  }

  const getAckdName = (ackdBy: ConversationUser | undefined) => {
    if (!ackdBy) return ''
    if (postedByUser(ackdBy.ID)) return 'you'
    return `${ackdBy.FirstName} ${ackdBy.LastName}`
  }

  function doRedactMessage() {
    if (!confirm('Are you sure you want to remove this message?')) {
      return
    }
    redactConversationMessageByID(message.ID)
      .then(() => {
        showForDuration('Message redacted OK', SnackbarTypeSuccess, 3000)
        refreshConvo && refreshConvo()
      })
      .catch((err) => {
        if (err?.Error?.[0]) {
          showForDuration(`${err.Error[0]}`, SnackbarTypeError, 3000)
          return
        }
        showForDuration(`Error redacting message`, SnackbarTypeError, 3000)
      })
  }

  const renderActions = () => {
    if (message.CanAck && needsAck(message.CanAck, ackd)) {
      return (
        <StyledCardActions className="convo-card-actions">
          <Button size="small" color="primary" onClick={ackHandler}>
            Acknowledge
          </Button>
        </StyledCardActions>
      )
    }

    if (hasBeenAckd(message.AckdOn, message.AckdBy)) {
      const displayAckdOn = dateTime
        .parse(message.AckdOn)
        .local()
        .format(dateTime.formats.Calendar)

      return (
        <StyledCardActions className="convo-card-actions">
          <div className="inlined">
            <IconDoneAll className="icon-ackd" fontSize="inherit" />
            <Typography variant="caption">
              Acknowledged by {getAckdName(message.AckdBy)} {displayAckdOn}
            </Typography>
          </div>
        </StyledCardActions>
      )
    }

    return (
      <StyledCardActions className="convo-card-actions">
        <div className="inlined">
          <IconDone className="icon-ackd" fontSize="inherit" />
          <Typography variant="caption">
            {ackd
              ? 'Acknowledged by you just now.'
              : 'Sent and awaiting acknowledgement.'}
          </Typography>
        </div>
        {canRedact && (
          <Button size="small" color="secondary" onClick={doRedactMessage}>
            Remove
          </Button>
        )}
      </StyledCardActions>
    )
  }

  if (!!message.RedactedAt) {
    return (
      <StyledMessageCard>
        <CardContent>(Message removed)</CardContent>
      </StyledMessageCard>
    )
  }

  return (
    <StyledMessageCard>
      <CardHeader
        className="convo-card-header"
        avatar={
          <UserAvatar
            firstName={message.User.FirstName}
            lastName={message.User.LastName}
            imgUrl={message.User.Avatar}
          />
        }
        title={`${message.User.FirstName} ${message.User.LastName} - ${message.User.Email}`}
        subheader={
          <small>
            {dateTime
              .parse(message.CreatedAt)
              .local()
              .format(dateTime.formats.Calendar)}
          </small>
        }
      />
      <CardContent className="convo-card-content">
        <MentionDisplayer content={message.Raw} />
      </CardContent>
      {renderActions()}
    </StyledMessageCard>
  )
}
