import React, { useContext, useEffect, useState } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { Snackbar } from '@material-ui/core'
import zcLogo from '../assets/zc-logo-2020.png'

const inlineStyle = {
  background: '#f1f1f1',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const GOZERO_CONFIG = 'gozero_config'
const CLIENT_CONFIGURATION_VERSION = 'config_client_version'
const URL_PARAM_ENCR_KEY = 9

const checkInterval = 60000
const sensitiveUrlParams = ['q']

let initialized = false
// this will be set to true if we detect an incident. After the incident is resolved, the page will refresh.
let goZEROLockedDown = false

window._zcConfig = window._zcConfig || {}

export const ConfigContext = React.createContext({})
export const useConfig = () => useContext(ConfigContext)

export const ConfigProvider = ({ children, ...initOptions }) => {
  const [adminAppUrl, setAdminAppUrl] = useState()
  const [baseUrl, setBaseUrl] = useState()
  const [auth0ClientID, setAuth0ClientID] = useState()
  const [auth0Domain, setAuth0Domain] = useState()
  const [serverlessBaseUrl, setServerlessBaseUrl] = useState()
  const [s3BaseUrl, setS3BaseUrl] = useState()
  const [gitHash, setGitHash] = useState()
  const [env, setEnv] = useState()
  const [status, setStatus] = useState()

  const prevClientID = localStorage.getItem('config_client_id')
  const configClientID = prevClientID ? prevClientID : uuidv1()
  localStorage.setItem('config_client_id', configClientID)

  const prevConfigurationVersion = localStorage.getItem('config_client_version')
  const clientConfigurationVersion = prevConfigurationVersion
    ? prevConfigurationVersion
    : '0'
  localStorage.setItem('config_client_version', clientConfigurationVersion)

  let timer

  function initConfig() {
    clearTimeout(timer)

    function queueConfigCheck() {
      timer = setTimeout(initConfig, checkInterval)
    }

    const {
      REACT_APP_ENV,
      REACT_APP_AUTH0_DOMAIN,
      REACT_APP_AUTH0_CLIENT_ID,
      REACT_ADMIN_APP_URL,
      REACT_APP_BASE_URL,
      REACT_APP_SERVERLESS_BASE_URL,
      REACT_APP_CURRENT_HASH,
    } = process.env
    setGitHash(REACT_APP_CURRENT_HASH)
    setEnv(REACT_APP_ENV)
    fetch(
      `https://config.zero.health/config?application_id=tzc-gozero&environment=${REACT_APP_ENV}&configuration=${REACT_APP_ENV}&client_id=${configClientID}&config_version=${clientConfigurationVersion}`
    )
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem(
          CLIENT_CONFIGURATION_VERSION,
          res.ConfigurationVersion
        )
        const decoded = atob(res.Content)
        let config

        // the Content will be empty if we are not receiving any new configuration data
        if (decoded === '') {
          const rawLocal = localStorage.getItem(GOZERO_CONFIG) || ''
          if (rawLocal === '') {
            //clear the local version to attempt to get the "latest" from appconfig
            localStorage.setItem(CLIENT_CONFIGURATION_VERSION, '0')
            throw new Error('Failed loading configuration')
          }
          // By this point, we know that if initialized is true, that the app's configuration state has been successfully
          // set at least once, and the incoming configuration is **unchanged** - so instead of setting all the state
          // values again (and causing a rerender), we can return here without making any data mutations.
          if (initialized === true) {
            queueConfigCheck()
            return
          }
          config = JSON.parse(rawLocal)
        } else {
          // the Content will be included if we are recieving new configuration data, in that case, update the local version of the configuration.
          config = JSON.parse(decoded)
          localStorage.setItem(GOZERO_CONFIG, decoded)
        }

        // Status check first
        if (!config.status) {
          throw new Error(
            'Failed loading configuration, or invalid configuration status'
          )
        }
        // check if we had a previous incident and are recovering from it. Refresh in that case.
        if (config.status.hasIncident === false && goZEROLockedDown) {
          console.debug('refreshing after previous incident...')
          window.location.reload()
          return
        }

        if (config.status.hasIncident && config.status.level === 'error') {
          setStatus(config.status)
          goZEROLockedDown = true
          queueConfigCheck()
          return
        }

        initialized = true
        setAdminAppUrl(REACT_ADMIN_APP_URL || config.ADMIN_APP_URL)
        setBaseUrl(REACT_APP_BASE_URL || config.BASE_URL)
        localStorage.setItem(
          'gozero_base_url',
          REACT_APP_BASE_URL || config.BASE_URL
        ) // hacky, but would otherwise require a massive refactor of the way we interact with the API
        setAuth0ClientID(REACT_APP_AUTH0_CLIENT_ID || config.AUTH0_CLIENT_ID)
        setAuth0Domain(REACT_APP_AUTH0_DOMAIN || config.AUTH0_DOMAIN)
        setServerlessBaseUrl(
          REACT_APP_SERVERLESS_BASE_URL || config.SERVERLESS_BASE_URL
        )
        localStorage.setItem(
          'serverless_base_url',
          REACT_APP_SERVERLESS_BASE_URL || config.SERVERLESS_BASE_URL
        )
        setS3BaseUrl(REACT_APP_BASE_URL || config.S3_BASE_URL)
        localStorage.setItem(
          's3_base_url',
          REACT_APP_BASE_URL || config.S3_BASE_URL
        )
        setStatus(config.status)
        queueConfigCheck()
      })
      .catch((err) => {
        initialized = false // will cause all config values in state to be reloaded
        setStatus({
          hasIncident: true,
          level: 'error',
          msg: 'We are currently experiencing a service outage, check back here for more updates.',
        })
        console.log('Config initialization error: ', err)
        queueConfigCheck()
      })
  }

  // This is a dev helper method, so that you can call "_zcConfig.refresh()" from the console; and doesn't
  // hurt to keep this in here for future purposes if you need to provide support to someone (it can help
  // w/ tracking down potential config issues)
  Object.assign(window._zcConfig, {
    refresh: initConfig,
  })

  useEffect(() => {
    if (!initialized) {
      initConfig()
    }
    // eslint-disable-next-line
  }, [])

  if (!status) {
    document.documentElement.classList.toggle('unready', true)
    return <div />
  }

  let warningMessage
  if (status.hasIncident) {
    // If its an error level incident, set the body class then return early (ie. nuke
    // rendering the rest of the app; just show the error and effectively "disable" the
    // entire app)
    if (status.level === 'error') {
      document.documentElement.classList.toggle('unready', true)
      return (
        <div style={inlineStyle}>
          <div style={{ display: 'inline-block', maxWidth: '440px' }}>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '5px solid #ddd',
                padding: '2rem',
              }}>
              <img
                src={zcLogo}
                alt="ZC Logo"
                style={{ maxWidth: '50%', display: 'inline-block' }}
              />
            </div>
            <h3>Our Apologies.</h3>
            <p>goZERO is currently unavailable, please check back later.</p>
            <p>{status.msg}</p>
          </div>
        </div>
      )
    }
    // If we get here, it should be a warning level status; just take the message...
    warningMessage = status.msg
  }

  document.documentElement.classList.toggle('unready', false)

  return (
    <ConfigContext.Provider
      value={{
        adminAppUrl,
        baseUrl,
        auth0Domain,
        auth0ClientID,
        serverlessBaseUrl,
        s3BaseUrl,
        gitHash,
        env,
        sensitiveUrlParams,
        URL_PARAM_ENCR_KEY,
      }}>
      {warningMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          message={warningMessage}></Snackbar>
      ) : null}
      {children}
    </ConfigContext.Provider>
  )
}
