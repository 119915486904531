import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  CardContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Chat, HelpOutline } from '@material-ui/icons'
import { UnsavedChanges } from '../UnsavedChanges'
import { post } from '../../services/ApiService'
import useApi from '../../hooks/useApi'
import { useAuth } from '../../providers/Auth'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import Mention, { parseMentions } from '../Mention'
import usePolling from '../../hooks/usePolling'
import { Conversation, ConversationMessage, Entity } from './types'
import DisplayConversationMessage, { StyledMessageCard } from './message'

const CONVERSATION_TYPE_PRIVATE_BUNDLER = 'private_bundlers'
const CONVERSATION_TYPE_PRIVATE_ORG = 'private_organization'
const CONVERSATION_TYPE_BUNDLER_REFERRER = 'bundlers_referrers'
const CONVERSATION_TYPE_BUNDLER_ORG = 'bundlers_orgs'

const TabStyles = styled.div`
  .MuiTabs-scrollButtons {
    color: #364cd5;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
`

const StyledConvoTooltipIcon = styled(HelpOutline)`
  height: 15px !important;
  width: 15px !important;
  color: #a3a3a3;
`

const StyledConversation = styled(Paper)`
  padding: 1rem;
`

interface props {
  referralRequestId?: number | null
  referralId?: number | null
}

export default function ConversationsTabs({
  referralRequestId,
  referralId,
}: props): React.ReactElement | null {
  const [curComment, setCurComment] = useState<string>('')
  const [currentConversationID, setCurrentConversationID] = useState<number>(0)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { zeroUser } = useAuth()

  const getBaseRoute = () => {
    if (referralRequestId) {
      return `/referral_request/${referralRequestId}`
    } else if (referralId) {
      return `/referral/${referralId}`
    }
  }

  const { data: conversations, refetch } = useApi({
    route: `${getBaseRoute()}/conversations`,
  })

  usePolling({
    duration: 20000,
    callback: React.useCallback(() => {
      return refetch()
    }, []),
  })

  useEffect(() => {
    if (
      currentConversationID === 0 &&
      conversations &&
      conversations.length > 0
    ) {
      setCurrentConversationID(conversations[0].ConversationID)
    }
  }, [conversations])

  const handleConversationTabChange = (e: any, val: number) => {
    setCurrentConversationID(val)
  }

  const handleCommentChange = (value: string) => {
    setCurComment(value)
  }

  const handleSendMessage = () => {
    const parsed = parseMentions(curComment)
    const conversationId = currentConversationID
    const body = {
      ConversationID: conversationId,
      Content: parsed.Content,
      Raw: parsed.Raw,
      MentionedUserIDs: parsed.UserIDs,
    }

    if (!body.Content) {
      showSnackbar(
        'Conversation message cannot be empty',
        SnackbarTypeError,
        3000
      )
      return
    }

    post(`${getBaseRoute()}/conversation/${conversationId}/message`, body)
      .then(() => {
        refetch()
      })
      .catch((err: any) => console.error(err))
      .finally(() => {
        setCurComment('')
      })
  }

  const getConvoLabel = (type: string, entities: Entity) => {
    let tooltipTitle, convoTitle
    switch (type) {
      case CONVERSATION_TYPE_BUNDLER_ORG:
        tooltipTitle = `A conversation between ${entities.Bundler} and ${entities.Organization}`
        convoTitle = `${entities.Bundler} and ${entities.Organization}`
        break
      case CONVERSATION_TYPE_BUNDLER_REFERRER:
        tooltipTitle = `A conversation between ${entities.Bundler} and ${entities.Referrer}`
        convoTitle = `${entities.Bundler} and ${entities.Referrer}`
        break
      case CONVERSATION_TYPE_PRIVATE_ORG:
        tooltipTitle = `A private conversation within ${entities.Organization}`
        convoTitle = `${entities.Organization}`
        break
      case CONVERSATION_TYPE_PRIVATE_BUNDLER:
        tooltipTitle = `A private conversation within ${entities.Bundler}`
        convoTitle = `${entities.Bundler}`
        break
      default:
        tooltipTitle = ''
        convoTitle = ''
        break
    }
    return (
      <span>
        {convoTitle}{' '}
        <Tooltip title={tooltipTitle}>
          <StyledConvoTooltipIcon fontSize="small" />
        </Tooltip>
      </span>
    )
  }

  const renderConversation = () => {
    if (!conversations) return <div />

    return (
      <StyledConversation>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {conversations.map((convo: Conversation) => {
              if (convo.ConversationID === currentConversationID) {
                return (
                  <Grid item xs={12} key={'add-comment'}>
                    <Grid item xs={12}>
                      {renderAddComment(convo)}
                      {attachUnsentMessageIndicator()}
                    </Grid>
                    <Grid item xs={12} key={convo.ConversationID}>
                      {convo.Messages &&
                        convo.Messages.map((msg: ConversationMessage) => {
                          return (
                            <DisplayConversationMessage
                              key={msg.ID}
                              message={msg}
                              refreshConvo={refetch}
                              canRedact={
                                zeroUser?.ID === msg.User?.ID && !!zeroUser?.ID
                              }
                            />
                          )
                        })}

                      {(!convo.Messages || convo.Messages.length === 0) && (
                        <StyledMessageCard>
                          <CardContent style={{ textAlign: 'center' }}>
                            <Chat color="secondary" />
                            <Typography variant="caption">
                              Be the first to start the conversation.
                            </Typography>
                          </CardContent>
                        </StyledMessageCard>
                      )}
                    </Grid>
                  </Grid>
                )
              }
              return <div key={convo.ConversationID} />
            })}
          </Grid>
        </Grid>
      </StyledConversation>
    )
  }

  const attachUnsentMessageIndicator = () => {
    return (
      <UnsavedChanges
        isDirty={curComment !== ''}
        message="You have an unsent message, do you want to continue?"
      />
    )
  }

  const renderAddComment = (convo: Conversation) => {
    const placeholder =
      "Write a new message. Use @ and the person's name to mention someone."
    return (
      <Mention
        active
        handleSend={handleSendMessage}
        data={convo.Users}
        placeholder={placeholder}
        onChange={handleCommentChange}
      />
    )
  }

  const renderTabs = () => {
    if (!conversations) return <div />
    const rendered = conversations.map((convo: Conversation) => {
      const label = getConvoLabel(convo.ConversationType, convo.EntityNames)
      return (
        <Tab
          key={convo.ConversationID}
          value={convo.ConversationID}
          label={label}
          style={{ fontSize: '12px' }}
        />
      )
    })
    return rendered || <Tab value={0} label={'Blank'} />
  }

  if (!conversations) return null

  return (
    <TabStyles>
      <Tabs
        value={currentConversationID || conversations[0].ConversationID}
        onChange={handleConversationTabChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto">
        {renderTabs()}
      </Tabs>
      <div>{renderConversation()}</div>
    </TabStyles>
  )
}
