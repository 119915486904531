import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from '@material-ui/core'
import useApi from '../../hooks/useApi'
import * as T from './types'
import referralStatuses from './statuses'
import styled from 'styled-components'

const DialogContainer = styled(DialogContent)`
  width: 600px;
`

interface ContainerProps {
  showModal: boolean
  setShowModal: (arg: boolean) => void
  form: T.ReferralForm

  submit(): void

  status: number | null
  updating?: boolean
}

interface DialogProps {
  showModal: boolean
  setShowModal: (arg: boolean) => void
  form: T.ReferralForm

  submit(): void

  initReason: string | null
}

const CancelDeclineDialog = ({
  showModal,
  setShowModal,
  form,
  submit,
  status,
}: ContainerProps) => {
  const [initReason, setInitReason] = useState<string | null>(null)
  useEffect(() => {
    if (!showModal) return setInitReason(null)
    status === referralStatuses.REFERRAL_STATUS_CANCELLED
      ? setInitReason(form.data.CancelReason)
      : null
    status === referralStatuses.REFERRAL_STATUS_DECLINED
      ? setInitReason(form.data.DeclineReason)
      : null
  }, [showModal])

  return (
    <>
      {status === referralStatuses.REFERRAL_STATUS_CANCELLED && (
        <CancelDialog
          showModal={showModal}
          setShowModal={setShowModal}
          form={form}
          submit={submit}
          initReason={initReason}
        />
      )}
      {status === referralStatuses.REFERRAL_STATUS_DECLINED && (
        <DeclineDialog
          showModal={showModal}
          setShowModal={setShowModal}
          form={form}
          submit={submit}
          initReason={initReason}
        />
      )}
    </>
  )
}

const CancelDialog = ({
  showModal,
  setShowModal,
  form,
  submit,
  initReason,
}: DialogProps) => {
  const otherReason = 'Other'
  const [reason, setReason] = useState<string>('')
  const [other, setOther] = useState<string>('')
  const [cancelReasons, setCancelReasons] = useState<Array<string>>()

  const { data: reasons } = useApi({
    route: `/referral_cancel_reasons`,
  })

  useEffect(() => {
    if (!reasons || !reasons.length) return
    setCancelReasons(reasons)
    setDefaults()
  }, [reasons, initReason])

  const setDefaults = () => {
    if (!cancelReasons || !initReason) return
    if (cancelReasons.includes(initReason)) {
      setReason(initReason)
      setOther('')
    }
    if (!cancelReasons.includes(initReason)) {
      setReason(otherReason)
      setOther(initReason)
    }
  }

  const handleClose = () => {
    setDefaults()
    form.setData({ ...form.data, CancelReason: initReason })
    setShowModal(false)
  }

  const handleChange = (e: any) => {
    setReason(e.target.value)
    setOther('')
    form.setData({ ...form.data, CancelReason: e.target.value })
  }

  const handleChangeOther = (e: any) => {
    setOther(e.target.value)
    form.setData({ ...form.data, CancelReason: e.target.value })
  }

  return (
    <Dialog open={showModal}>
      <DialogTitle>Reason for Cancellation</DialogTitle>
      <DialogContainer>
        <DialogContentText>
          Please select one of the reasons below:
        </DialogContentText>
        <RadioGroup value={reason} onChange={handleChange}>
          {cancelReasons &&
            cancelReasons.map((r: string) => (
              <FormControlLabel
                key={r}
                value={r}
                label={r}
                control={<Radio />}
              />
            ))}
          <FormControlLabel
            value={otherReason}
            label={otherReason}
            control={<Radio />}
          />
        </RadioGroup>
        {reason === otherReason && (
          <TextField
            value={other}
            onChange={handleChangeOther}
            multiline
            fullWidth
            placeholder="Please provide additional information..."
            minRows={4}
          />
        )}
      </DialogContainer>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={submit}
          color="secondary"
          disabled={!reason || (reason === otherReason && !other)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DeclineDialog = ({
  showModal,
  setShowModal,
  form,
  submit,
  initReason,
}: DialogProps) => {
  const otherReason = 'Other'
  const [reason, setReason] = useState<string>('')
  const [other, setOther] = useState<string>('')
  const [declineReasons, setDeclineReasons] = useState<Array<string>>()

  const { data: reasons } = useApi({
    route: `/referral_decline_reasons`,
  })

  useEffect(() => {
    if (!reasons || !reasons.length) return
    setDeclineReasons(reasons)
    setDefaults()
  }, [reasons, initReason])

  const setDefaults = () => {
    if (!declineReasons || !initReason) return
    if (declineReasons.includes(initReason)) {
      setReason(initReason)
      setOther('')
    }
    if (!declineReasons.includes(initReason)) {
      setReason(otherReason)
      setOther(initReason)
    }
  }

  const handleClose = () => {
    setDefaults()
    form.setData({ ...form.data, DeclineReason: initReason })
    setShowModal(false)
  }

  const handleChange = (e: any) => {
    setReason(e.target.value)
    setOther('')
    form.setData({ ...form.data, DeclineReason: e.target.value })
  }

  const handleChangeOther = (e: any) => {
    setOther(e.target.value)
    form.setData({ ...form.data, DeclineReason: e.target.value })
  }

  return (
    <Dialog open={showModal}>
      <DialogTitle>Reason Referral was Declined</DialogTitle>
      <DialogContainer>
        <DialogContentText>
          Please select one of the reasons below:
        </DialogContentText>
        <RadioGroup value={reason} onChange={handleChange}>
          {declineReasons &&
            declineReasons.map((r: string) => (
              <FormControlLabel
                key={r}
                value={r}
                label={r}
                control={<Radio />}
              />
            ))}
          <FormControlLabel
            value={otherReason}
            label={otherReason}
            control={<Radio />}
          />
        </RadioGroup>
        {reason === otherReason && (
          <TextField
            value={other}
            onChange={handleChangeOther}
            multiline
            fullWidth
            placeholder="Please provide additional information..."
            minRows={4}
          />
        )}
      </DialogContainer>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={submit}
          color="secondary"
          disabled={!reason || (reason === otherReason && !other)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelDeclineDialog
