import * as api from '../services/ApiService'
import { getErrorMsg } from './utils'
import { ShapeInvoiceLineData } from '../components/ReferralInvoiceForm/InvoiceLineTable2/addEditLine'

export const GET_REFERRAL_INVOICE_SUCCESS = 'GET_REFERRAL_INVOICE_SUCCESS'
export const GET_REFERRAL_INVOICE_ERROR = 'GET_REFERRAL_INVOICE_ERROR'
export const GET_REFERRAL_INVOICE = 'GET_REFERRAL_INVOICE'
export const GET_REFERRAL_INVOICES_SUCCESS = 'GET_REFERRAL_INVOICES_SUCCESS'
export const GET_REFERRAL_INVOICES_ERROR = 'GET_REFERRAL_INVOICES_ERROR'
export const GET_REFERRAL_INVOICES = 'GET_REFERRAL_INVOICES'
export const GET_REFERRAL_INVOICE_STATUSES_SUCCESS =
  'GET_REFERRAL_INVOICE_STATUSES_SUCCESS'
export const GET_REFERRAL_INVOICE_STATUSES_ERROR =
  'GET_REFERRAL_INVOICE_STATUSES_ERROR'
export const GET_REFERRAL_INVOICE_STATUSES = 'GET_REFERRAL_INVOICE_STATUSES'
export const POST_REFERRAL_INVOICE = 'POST_REFERRAL_INVOICE'
export const POST_REFERRAL_INVOICE_SUCCESS = 'POST_REFERRAL_INVOICE_SUCCESS'
export const POST_REFERRAL_INVOICE_ERROR = 'POST_REFERRAL_INVOICE_ERROR'

export const ISSUE_REFERRAL_INVOICE = 'ISSUE_REFERRAL_INVOICE'
export const ISSUE_REFERRAL_INVOICE_SUCCESS = 'ISSUE_REFERRAL_INVOICE_SUCCESS'
export const ISSUE_REFERRAL_INVOICE_ERROR = 'ISSUE_REFERRAL_INVOICE_ERROR'

// files
export const CLEAR_REFERRAL_INVOICE_FILES = 'CLEAR_REFERRAL_INVOICE_FILES'
export const GET_REFERRAL_INVOICE_FILES = 'GET_REFERRAL_INVOICE_FILES'
export const GET_REFERRAL_INVOICE_FILES_SUCCESS =
  'GET_REFERRAL_INVOICE_FILES_SUCCESS'
export const GET_REFERRAL_INVOICE_FILES_ERROR =
  'GET_REFERRAL_INVOICE_FILES_ERROR'
export const DEL_REF_INVOICE_FILE = 'DEL_REF_INVOICE_FILE'
export const DEL_REF_INVOICE_FILE_SUCCESS = 'DEL_REF_INVOICE_FILE_SUCCESS'
export const DEL_REF_INVOICE_FILE_ERR = 'DEL_REF_INVOICE_FILE_ERR'

/**
 * Get an array of all the billing entities associated with an organization.
 * This model includes data like Billing Address and TIN
 */
export const getBillingEntities = ({
  organizationIDs = <any>[],
  IDs = <any>[],
}): any => {
  return api.get('/billing_entity', {
    filter: {
      organizationIDs,
      IDs,
    },
  })
}

/**
 * Add a line to a referral invoice
 */
export const postReferralInvoiceLine = (payload: ShapeInvoiceLineData): any => {
  return api.post(
    `/referral_invoice/${payload.ReferralInvoiceID}/referral_invoice_line`,
    payload
  )
}

export const putReferralInvoiceLine = (payload: ShapeInvoiceLineData) => {
  return api.put(
    `/referral_invoice/${payload.ReferralInvoiceID}/referral_invoice_line/${payload.ID}`,
    payload
  )
}

export const deleteReferralInvoiceLine = (
  referralInvoiceID: number,
  referralInvoiceLineID: number
) => {
  return api.del(
    `/referral_invoice/${referralInvoiceID}/referral_invoice_line/${referralInvoiceLineID}`
  )
}

export const getOrg = ({ id }: { id: number }) => {
  return api.get(`/organization/${id}`)
}

export const getReferralInvoice = (params: { id: number }) => {
  return api.get(`/referral_invoice/${params.id}`)
}

export const getReferralInvoiceSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_INVOICE_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralInvoiceError = (err: any) => {
  return {
    type: GET_REFERRAL_INVOICE_ERROR,
    payload: getErrorMsg(err),
    meta: {
      notification: {
        body: 'There was a problem loading the referral invoice, please try again or contact your administrator',
        level: 'error',
      },
    },
  }
}

export const getReferralInvoices = (params: any) => {
  return api.get('/referral_invoice', params)
}

export const getReferralInvoicesSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_INVOICES_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralInvoicesError = (err: any) => {
  return {
    type: GET_REFERRAL_INVOICES_ERROR,
    payload: getErrorMsg(err),
  }
}

export const getReferralInvoiceStatuses = () => {
  return api.get('/referral_invoice_analytics/statuses')
}

export const getReferralInvoiceStatusesSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_INVOICE_STATUSES_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralStatusesError = (err: any) => {
  return {
    type: GET_REFERRAL_INVOICE_STATUSES_ERROR,
    payload: getErrorMsg(err),
  }
}

export const getReferralExistingInvoices = (ReferralID: string) => {
  return api.get(`/referral/${ReferralID}/existing_invoices`)
}

/* POST */
export const postReferralInvoice = (ID: string) => {
  return api.post(`/referral/${ID}/referral_invoice`)
}

export const postReferralInvoiceSuccess = (data: any) => {
  return {
    type: POST_REFERRAL_INVOICE_SUCCESS,
    payload: data.Data,
  }
}

export const postReferralInvoiceError = (err: any) => {
  return {
    type: POST_REFERRAL_INVOICE_ERROR,
    payload: getErrorMsg(err),
  }
}

export const getReferralInvoiceFileDownloadUrl = ({
  refInvoiceId,
  fileId,
}: any) => {
  return api.get(`/referral_invoice/${refInvoiceId}/presign_download/${fileId}`)
}

export const clearRefFiles = () => {
  return {
    type: CLEAR_REFERRAL_INVOICE_FILES,
  }
}

export const getReferralInvoiceFiles = (refInvoiceId: any) => {
  return api.get(`/referral_invoice/${refInvoiceId}/file`)
}

export const getReferralInvoiceFilesSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_INVOICE_FILES_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralInvoiceFilesError = (data: any) => {
  const { Meta } = data
  return {
    type: GET_REFERRAL_INVOICE_FILES_ERROR,
    payload: data.Error,
    meta: {
      notification: {
        body: `Error getting files for this referral. Try to load the page again or contact your administrator with this code: ${Meta.RequestId}`,
        level: 'error',
      },
    },
  }
}

export const saveReferralInvoiceFile = (params: any) => {
  const { id, refInvoiceId, body } = params

  return api.put(`/referral_invoice/${refInvoiceId}/file/${id}`, body)
}

export const saveNewReferralInvoiceFile = ({ refInvoiceId, body }: any) => {
  return api.post(`/referral_invoice/${refInvoiceId}/file`, body)
}

export const delRefInvoiceFile = ({ refInvoiceId, id }: any) => {
  return api.del(`/referral_invoice/${refInvoiceId}/file/${id}`)
}

export const delRefInvoiceFileErr = (err: any) => {
  const { Meta } = err
  return {
    type: DEL_REF_INVOICE_FILE_ERR,
    payload: err.Error,
    meta: {
      notification: {
        body: `Error deleting file. Try again or contact your administrator with this code: ${Meta.RequestId}`,
        level: 'error',
      },
    },
  }
}

export const delRefInvoiceFileSuccess = (id: any) => {
  return (data: any) => {
    return {
      type: DEL_REF_INVOICE_FILE_SUCCESS,
      payload: data.Data,
    }
  }
}

export const getPresignedUrlForUpload = (notifier: any) => {
  return (file: any, cb: any) => {
    const params = {
      objectName: file.name,
      contentType: file.type,
      make_unique: true,
    }

    api
      .get('/presign_file', params)
      .then((data: any) => {
        if (data && data.Data) {
          cb({ signedUrl: data.Data.Url })
          notifier({ S3Key: data.Data.S3Key })
        } else {
          console.error('No signedUrl on response')
        }
      })
      .catch((error: any) => {
        console.error(error)
      })
  }
}
