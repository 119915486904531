import React, { useEffect, useState, useRef, useMemo } from 'react'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../hooks/useSnackbar'
import {
  getReferralInvoice,
  deleteReferralInvoiceLine,
} from '../../../actions/ReferralInvoiceActions'
import styled from 'styled-components'
import { Button, IconButton, Checkbox } from '@material-ui/core'
import { Edit as IconEdit } from '@material-ui/icons'
import { ReferralInvoice } from '../types'
import dateTime from '../../../utils/dateTime'
import formatters from '../../../utils/formatters'
import DesignSuite24 from '../../DesignSuite24'
import ConfirmDialog from '../../ConfirmDialog'
import AddEditLine from './addEditLine'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

const StyledInvoiceLinesTable = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 18px -7px rgba(0, 0, 0, 0.5);
  border: 1px solid #e5e5e5;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    thead {
      background: #f5f5f5;

      th {
        white-space: nowrap;
        padding: 1rem 0.5rem;
        font-size: 92%;
      }
    }

    tbody {
      td {
        padding: 0.5rem;
        border-bottom: 1px solid #eee;

        &.nowrap {
          white-space: nowrap;
        }
      }
    }

    tfoot {
      background: #f5f5f5;
      text-align: center;

      td {
        padding: 1rem;
      }
    }
  }
`

export interface Props {
  referralInvoiceID: number
  isEditable: boolean
}

export default React.forwardRef(function InvoiceLineTable2(
  { referralInvoiceID, isEditable }: Props,
  ref: any
): React.ReactElement | null {
  const [referralInvoiceData, setReferralInvoiceData] =
    useState<ReferralInvoice | null>(null)
  const [checkedLines, setCheckedLines] = useState<any>({})
  const [checkedIDs, setCheckedIDs] = useState<any[]>([])
  const { show: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const refEdit = useRef<any>(null)

  React.useImperativeHandle(
    ref,
    () => ({
      refresh: loadReferralInvoice,
    }),
    []
  )

  const lines = useMemo(() => {
    if (!referralInvoiceData) return []
    return referralInvoiceData.Lines || []
  }, [referralInvoiceData])

  const invoiceTotal = useMemo(() => {
    let total = 0
    lines.forEach((l: any) => {
      total += Number(l.Cost)
    })
    return formatters.formatCurrency(total)
  }, [lines])

  useEffect(() => {
    if (!referralInvoiceID) return
    loadReferralInvoice()
  }, [referralInvoiceID])

  useEffect(() => {
    setCheckedIDs(
      Object.keys(checkedLines)
        .filter((k) => checkedLines[k])
        .map(Number)
    )
  }, [checkedLines])

  function loadReferralInvoice() {
    return getReferralInvoice({ id: referralInvoiceID })
      .then((res: any) => {
        if (res.error) throw res
        setReferralInvoiceData(res.Data)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Error loading referral invoice' })
      )
  }

  function doDeleteLines() {
    ConfirmDialog({
      content:
        'Are you sure you want to delete the selected lines from this invoice?',
      onConfirm: () => {
        Promise.all(
          checkedIDs.map((id) =>
            deleteReferralInvoiceLine(referralInvoiceID, id)
          )
        )
          .then((res) => {
            if (res.some((r) => r.error)) {
              showSnackbar('Failed to delete some lines', SnackbarTypeError)
              return
            }
            showSnackbar('Lines deleted OK', SnackbarTypeSuccess)
            setCheckedLines({})
          })
          .then(loadReferralInvoice)
      },
    })
  }

  return (
    <>
      <StyledInvoiceLinesTable>
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  size="small"
                  onChange={(ev: any) => {
                    setCheckedLines(
                      lines.reduce((acc: any, line: any) => {
                        acc[line.ID] = ev.target.checked
                        return acc
                      }, {})
                    )
                  }}
                />
              </th>
              <th>CPT/Bundle Code</th>
              <th>CPT Modifier</th>
              <th>Cost</th>
              <th>Date Of Service</th>
              <th>Diagnosis Pointer</th>
              <th>POS Code</th>
              <th>Units</th>
              <th>DRG</th>
              <th>Description</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line: any) => {
              return (
                <tr key={`${line.ID}-row`}>
                  <td className="cell nowrap">
                    <Checkbox
                      size="small"
                      checked={!!checkedLines?.[line.ID]}
                      disabled={!isEditable}
                      onChange={(ev: any) => {
                        setCheckedLines({
                          ...checkedLines,
                          [line.ID]: ev.target.checked,
                        })
                      }}
                    />
                    {isEditable && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          refEdit.current?.editLineData(line)
                        }}>
                        <IconEdit fontSize="inherit" />
                      </IconButton>
                    )}
                  </td>
                  <td className="cell">
                    <span>{line.CptCode}</span>
                    {line.FeeSchedulePriceID && (
                      <small
                        style={{
                          fontSize: '95%',
                          display: 'flex',
                          alignItems: 'center',
                          background: '#0059c91c',
                          borderRadius: 4,
                          padding: '3px 5px',
                          whiteSpace: 'nowrap',
                          columnGap: 3,
                          marginTop: 3,
                        }}>
                        <DesignSuite24.CommonIcons.IconCheck
                          style={{ color: 'green' }}
                          fontSize="inherit"
                        />
                        <span style={{ fontSize: '86%' }}>Linked Bundle</span>
                      </small>
                    )}
                  </td>
                  <td className="cell">{line.CPTModifierCode}</td>
                  <td className="cell">
                    {formatters.formatCurrency(line.Cost)}
                  </td>
                  <td className="cell">
                    {dateTime.parse(line.DateOfService).format()}
                  </td>
                  <td className="cell">{line.DiagnosisPointer}</td>
                  <td className="cell">{line.PlaceOfServiceCode}</td>
                  <td className="cell">{line.Units}</td>
                  <td className="cell">{line.DRGCode}</td>
                  <td className="cell descr">{line.Description}</td>
                  <td className="cell last">{line.Notes}</td>
                </tr>
              )
            })}
            {lines.length === 0 && (
              <tr>
                <td colSpan={12}>No invoice lines configured.</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td>
                {checkedIDs.length > 0 && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={doDeleteLines}>
                    Delete
                  </Button>
                )}
              </td>
              <td colSpan={11}>
                Invoice Total: <strong>{invoiceTotal}</strong>
              </td>
            </tr>
          </tfoot>
        </table>

        <AddEditLine
          hideTrigger
          ref={refEdit}
          referralInvoice={referralInvoiceData}
          onSave={() => {
            loadReferralInvoice()
          }}
        />
      </StyledInvoiceLinesTable>
    </>
  )
})
