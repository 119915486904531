import React from 'react'
import { Grid, Typography } from '@material-ui/core'
// import FormRow from './FormRow'
import TextField from '../TextField'
import PhoneField from '../PhoneField'
import PhoneExtField from '../PhoneExtField'
import { ReferralForm } from './types'
import { ReferralRequestForm } from '../ReferralRequestForm/types'
import styled from 'styled-components'

const StyledContainer = styled.div`
  max-width: 460px;
`

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
}

const PhysicianDetailsForm: React.FC<Props> = ({ form, showErrors }) => {
  const disabled =
    form.data.EntityName === 'referral'
      ? !form.data.Edit || !!form.data.ArchivedAt
      : !form.data.ContentEditable

  return (
    <StyledContainer>
      <Typography variant="h5" style={{ marginBottom: '0.5rem' }}>
        <span className="header-snazzy">Ordering Physician Details</span>
      </Typography>

      <Grid container spacing={2} style={{ marginBottom: '0.5rem' }}>
        <Grid item>
          <div style={{ maxWidth: 180 }}>
            <TextField
              label="Name"
              value={form.data.OrderingPhysName || ''}
              onChange={form.setters.OrderingPhysName}
              disabled={disabled}
            />
          </div>
        </Grid>
        <Grid item>
          <div style={{ maxWidth: 152 }}>
            <PhoneField
              label="Phone"
              value={form.data.OrderingPhysPhone || ''}
              onChange={form.setters.OrderingPhysPhone}
              error={showErrors ? form.errors.OrderingPhysPhone : null}
              disabled={disabled}
            />
          </div>
        </Grid>
        <Grid item>
          <div style={{ maxWidth: 90 }}>
            <PhoneExtField
              label="Ph Ext."
              value={form.data.OrderingPhysPhoneExt || ''}
              onChange={form.setters.OrderingPhysPhoneExt}
              error={showErrors ? form.errors.OrderingPhysPhoneExt : null}
              disabled={disabled}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Email"
            value={form.data.OrderingPhysEmail || ''}
            onChange={form.setters.OrderingPhysEmail}
            error={showErrors ? form.errors.OrderingPhysEmail : null}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneField
            label="Fax"
            value={form.data.OrderingPhysFax || ''}
            onChange={form.setters.OrderingPhysFax}
            error={showErrors ? form.errors.OrderingPhysFax : null}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default PhysicianDetailsForm
