import React from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, Tab, Paper, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Users from './Users'
import PracticeFacilities from './PracticeFacilities'
import OrganizationNavigation from './OrganizationNavigation'
import PermissionDenied from '../PermissionDenied'
import useQueryParams from '../../hooks/useQueryParams'
import { useSetFrameHeaderLeft, useFrameHeightLock } from '../AppFrame'
import * as T from './types'

const UserManagementContent = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;

  .content-area {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
`

const StyledUserManagement = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;

  .org-list {
    width: 100%;
    max-width: 300px;
    height: 100%;
    // margin: 1rem;
    padding: 0 1rem 1rem;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    box-shadow: -16px 0px 26px -30px inset;
    background: rgb(241, 241, 241);

    .org-search-box {
      padding: 1rem 0;
      background: rgb(241, 241, 241);
      position: sticky;
      top: 0;
      z-index: 2;
    }

    ul {
      padding: 0;
    }
  }
`

const LoadingUserManagment = () => {
  return (
    <div style={{ padding: '30px' }}>
      <Skeleton variant="text" width={200} height={40} />
      <div style={{ height: '10px' }} />
      <Skeleton variant="rect" width={800} height={300} />
    </div>
  )
}

const TabContent: React.FC<{ index: number; children: any[] }> = ({
  index,
  children,
}) => {
  return (
    <div>
      {/* <Paper elevation={3} style={{ padding: '10px', paddingTop: '5px' }}> */}
      {children[index] || null}
      {/* </Paper> */}
    </div>
  )
}

const UserManagement = () => {
  const hist = useHistory()
  const loc = useLocation()
  const { params, setParams } = useQueryParams()
  const [selectedOrg, setSelectedOrg] = React.useState<T.UserOrganization>()

  useFrameHeightLock(() => true, [])

  useSetFrameHeaderLeft(() => (
    <Typography variant="h5">User Management</Typography>
  ))

  const currentTabIndex = params.tab || 0
  const setCurrentTabIndex = (index: number) => setParams({ tab: index })

  React.useEffect(() => {
    if (selectedOrg) {
      hist.push({
        pathname: `/user_management/organization/${selectedOrg.ID}`,
        search: loc.search,
      })
    }
  }, [selectedOrg])

  return (
    <StyledUserManagement>
      <div className="org-list">
        <OrganizationNavigation
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
        />
      </div>
      {selectedOrg ? (
        <UserManagementContent>
          <div className="content-area">
            <Typography variant="h4" style={{ marginBottom: '1rem' }}>
              {selectedOrg.Name}
            </Typography>
            {selectedOrg.CanManageOtherUsers ? (
              <>
                <Paper variant="outlined" style={{ marginBottom: '1.5rem' }}>
                  <Tabs
                    value={currentTabIndex}
                    onChange={(_evt, value: number) =>
                      setCurrentTabIndex(value)
                    }
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab label="Users" />
                    <Tab label="Practice Facilities" />
                  </Tabs>
                </Paper>
                <TabContent index={currentTabIndex}>
                  <Users organizationId={selectedOrg.ID} />
                  <PracticeFacilities organizationId={selectedOrg.ID} />
                </TabContent>
              </>
            ) : (
              <PermissionDenied />
            )}
          </div>
        </UserManagementContent>
      ) : (
        <LoadingUserManagment />
      )}
    </StyledUserManagement>
  )
}

export default UserManagement
