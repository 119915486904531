import React from 'react'
import {
  TextField as MUITextField,
  StandardTextFieldProps,
} from '@material-ui/core'
import styled from 'styled-components'

const StyledTextField = styled(MUITextField)`
  input[disabled],
  textarea[disabled] {
    background: #f1f1f1;
    opacity: 0.8;
  }
`

interface Props extends Omit<StandardTextFieldProps, 'onChange' | 'error'> {
  onChange(val: string): void
  error?: string | null | undefined
}

export default React.forwardRef(function TextField(props: Props, ref: any) {
  return (
    <StyledTextField
      {...props}
      ref={ref}
      variant="outlined"
      size="small"
      maxRows={20}
      fullWidth={props?.fullWidth === false ? false : true}
      onChange={(evt: any) => props.onChange(evt.target.value)}
      error={!!props.error}
      helperText={props.error || undefined}
    />
  )
})
