import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { List, ListItem, Drawer } from '@material-ui/core'
import useApi from '../../hooks/useApi'
import useDebounce from '../../hooks/useDebounce'
import SearchField from '../SearchField'
import { UserOrganization } from './types'

// const SubNavigationDrawer = styled(Drawer)`
//   // @keyframes slideIn {
//   //   from {
//   //     width: 0px;
//   //   }
//   //   to {
//   //     width: 240px;
//   //   }
//   // }

//   z-index: 0;
//   width: 240px;
//   // animation-duration: 1s;
//   // animation-name: slideIn;

//   .MuiDrawer-paperAnchorLeft {
//     position: absolute;
//     top: -5px;
//     left: -16px;
//     width: 240px;
//     height: 94vh;
//     padding-top: 5px;
//     // animation-duration: 1s;
//     // animation-name: slideIn;
//   }
// `

interface OrgListProps {
  organizations: UserOrganization[]
  filterText: string
  selectedOrgId: number
  setSelectedOrg: (org: UserOrganization) => void
}

// this should only rerender when debounced filter text changes
const OrganizationList: React.FC<OrgListProps> = React.memo(
  ({ filterText, organizations, selectedOrgId, setSelectedOrg }) => {
    const filteredOrgs = organizations.filter((org: any) => {
      if (!filterText.length) return true
      return org.Name.toLowerCase().includes(filterText.toLowerCase())
    })

    return (
      <List>
        {filteredOrgs.map((org: UserOrganization) => (
          // <div key={org.ID}>
          <ListItem
            key={org.ID}
            button
            selected={selectedOrgId === org.ID}
            onClick={() => setSelectedOrg(org)}
            component="li">
            {org.Name}
          </ListItem>
          // </div>
        ))}
      </List>
    )
  }
)

interface OrgNavProps {
  selectedOrg: UserOrganization
  setSelectedOrg: (val: UserOrganization) => void
}

const OrganizationNavigation: React.FC<any> = ({
  selectedOrg,
  setSelectedOrg,
}) => {
  const params: any = useParams()
  const { data, loading } = useApi({
    route: `/user-management/user-organizations`,
  })

  const [filterText, setFilterText] = React.useState<string>('')
  const debouncedFilterText = useDebounce(filterText, 500)

  // sets default selected org (once)
  React.useEffect(() => {
    if (data && !loading) {
      const orgFromParams: string | undefined = data.find(
        (org: any) => org.ID === +params.organizationId
      )
      const defaultOrg = orgFromParams || data[0]
      setSelectedOrg(defaultOrg)
    }
  }, [data, loading])

  // if (!selectedOrg) return null

  return (
    // <SubNavigationDrawer variant="permanent">
    <div>
      <div className="org-search-box">
        <span
          style={{
            fontWeight: 'bold',
            display: 'block',
            paddingBottom: '0.35rem',
          }}>
          Organizations
        </span>
        <SearchField
          useV2Style
          label=""
          value={filterText}
          onChange={setFilterText}
        />
      </div>
      {selectedOrg && (
        <OrganizationList
          selectedOrgId={selectedOrg.ID}
          setSelectedOrg={setSelectedOrg}
          organizations={data}
          filterText={debouncedFilterText}
        />
      )}
    </div>
    // </SubNavigationDrawer>
  )
}

export default OrganizationNavigation
