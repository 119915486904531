import React from 'react'
import { Grid, SortDirection, Typography } from '@material-ui/core'
import DataTable from '../DataTable'
import { ReferralInvoiceModel } from '../../models/referralInvoice'
import useApi from '../../hooks/useApi'
import { useReferralInvoiceList } from './hooks'
import ReferralInvoiceFilter from './ReferralInvoiceFilters'
import useQueryParams from '../../hooks/useQueryParams'
import { useSetFrameHeaderLeft, useFrameHeightLock } from '../AppFrame'
import styled from 'styled-components'

const StyledReferralInvoiceList = styled.div`
  height: 100%;
  display flex;

  .referral-invoice-filters {
    padding: 1rem;
    width: 240px;
  }

  .data-table-wrapper {
    margin: 0 !important;
    height: 100%;

    .data-table-wrapper-l2 {
      height: 100%;

      table {
        max-height: 100%;

        thead {
          position: sticky;
          top: 0;
          background: #e9eef9;
          z-index: 2;
          white-space: nowrap;
        }

        th, td {
          padding: 0.5rem;
        }
      }

      .data-table-footer {
        position: sticky;
        bottom: 0;
        background: #fff;
        width: 100%;
        border-top: 1px solid #e0e0e0;
        z-index: 2;
      }
    }
  }
`

const ReferralInvoiceList: React.FC = () => {
  const { params, setParams } = useQueryParams()
  const { data, meta, refetch, loading } = useReferralInvoiceList()

  const { data: statuses } = useApi({
    route: '/referral_invoice_statuses',
  })

  useFrameHeightLock(() => true, [])

  useSetFrameHeaderLeft(() => <Typography variant="h5">Invoices</Typography>)

  const onChangePage = (change: any) => {
    const { range, page, pageSize } = change
    const newParams = { ...params, page, pageSize, range }
    setParams(newParams)
    refetch(newParams)
  }

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    const newParams = { ...params, sort: [col, dir] }
    setParams(newParams)
    refetch(newParams)
  }

  const sortable = params.sort
    ? { col: params.sort[0], dir: params.sort[1] }
    : undefined

  if (!data || !meta || !statuses) return null

  return (
    <StyledReferralInvoiceList>
      {/* <Grid container justify="space-between">
        <Grid item xs={10}> */}
      <ReferralInvoiceFilter refetchReferralInvoices={refetch} />
      {/* </Grid>
      </Grid> */}
      <DataTable
        keyProp="ID"
        data={data}
        columns={ReferralInvoiceModel(statuses)}
        count={meta.Total}
        initPage={params.page || 1}
        initPageSize={params.pageSize || 25}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangePage}
        sortHandler={sortColumns}
        sortable={sortable}
        loading={loading}
        allowEditing={false}
      />
    </StyledReferralInvoiceList>
  )
}

export default ReferralInvoiceList
