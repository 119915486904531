import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import {
  Cancel as IconCancel,
  Edit as IconEdit,
  List as IconList,
  Save as IconSave,
} from '@material-ui/icons'
import {
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import papa from 'papaparse'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { defaultTableRowColorer } from '../../utils'

interface SortableType {
  col: string
  dir: SortDirection
}

interface EnhancedTableHeadProps {
  columns: { [key: string]: any }
  rowCount: number
  sortable?: SortableType
  sortHandler?: (
    data: {
      col: string
      dir: 'ASC' | 'DESC' | SortDirection | false
    },
    range?: [number, number] | undefined
  ) => void
  isCheckable: boolean
  toggleCheckAll: (event: any) => any
  hasActions: boolean
  canEdit: boolean
  pageSize: number
  page: number
  allChecked: boolean
}

const EnhancedTableHead = ({
  columns,
  sortHandler,
  sortable,
  isCheckable,
  hasActions,
  canEdit,
  toggleCheckAll,
  pageSize,
  page,
  allChecked,
  rowCount,
}: EnhancedTableHeadProps) => {
  const filteredCols = Object.keys(columns).filter((col, i) => {
    const { details } = columns[col]
    if (details && details.hidden) return false
    return true
  })

  const onClickSort =
    (col: string, dir: SortDirection, range: [number, number]) => () => {
      if (typeof sortHandler !== 'undefined') {
        sortHandler({ col, dir }, range)
      }
    }

  const _getPageRange = (page: number, pageSize: number): [number, number] => {
    return [page === 1 ? 1 : (page - 1) * pageSize + 1, pageSize * page]
  }

  return (
    <TableHead>
      <TableRow>
        {isCheckable && rowCount ? (
          <TableCell padding="checkbox">
            <Checkbox
              onChange={(ev) => toggleCheckAll(ev)}
              checked={allChecked}
            />
          </TableCell>
        ) : null}
        {hasActions ? <TableCell padding="checkbox">Actions</TableCell> : null}
        {canEdit ? <TableCell padding="checkbox">Edit</TableCell> : null}

        {filteredCols.map((col, i) => {
          const { name, details } = columns[col]
          const colSpan = details && details.colSpan ? details.colSpan : 1
          const sortEnabled = !!(details && details.sortName)

          // Sorting is NOT enabled
          if (!sortEnabled) {
            return (
              <TableCell
                key={i}
                align="left"
                padding="default"
                colSpan={colSpan}>
                {name}
              </TableCell>
            )
          }

          // Sorting IS enabled
          const range = _getPageRange(page, pageSize)
          return (
            <TableCell
              key={i}
              align="left"
              padding="default"
              colSpan={colSpan}
              sortDirection={sortable && sortable.dir ? sortable.dir : false}>
              <TableSortLabel
                active={sortable && sortable.col === details.sortName}
                direction={sortable && sortable.dir ? sortable.dir : 'asc'}
                onClick={onClickSort(
                  details.sortName,
                  sortable && sortable.dir === 'asc' ? 'desc' : 'asc',
                  range
                )}>
                {name}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const styles: any = (theme: any) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  clickable: {
    cursor: 'pointer',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  btnExportable: {
    position: 'absolute',
    top: '100%',
    left: 0,
    marginTop: '1rem',
  },
  exportableLink: {
    textDecoration: 'underline !important',
    cursor: 'pointer',
  },
})

interface DataTableProps {
  classes: ClassNameMap<string>
  error?: any | null
  columns: any
  data: any[]
  getError?: string
  onRowClick?: (...args: any[]) => any
  rowOptsApplier?: (...args: any[]) => any
  sortable: SortableType | undefined
  sortHandler?: (
    data: {
      col: string
      dir: 'ASC' | 'DESC' | SortDirection | false
    },
    range?: [number, number] | undefined
  ) => void
  pagination?: boolean
  clientPagination?: boolean
  csvExportable?: boolean
  csvExportableFilename?: string
  rowsPerPage?: number[]
  checkHandler?: ((...args: any[]) => any) | null
  rowActions?: any[]
  onEditRow?: (...args: any[]) => any
  onSaveRow?: (...args: any[]) => any
  keyProp?: string | any[] | null
  loading?: boolean
  onChangePage: (...args: any[]) => any
  onChangeRowsPerPage: (...args: any[]) => any
  count?: number
  allowEditing: boolean
  isRowEditable?: (row: any) => boolean
  onSave?: (...args: any[]) => any
  initPage: number
  initPageSize: number
  customToolbar?: any
  isRowCheckable?: ((row: any) => boolean) | null
  rowColor?: (row: any) => string | null | undefined
  LeftFooterItems?: React.ReactElement | null
}

const DataTable = ({
  classes,
  columns,
  data,
  getError,
  sortable,
  sortHandler,
  pagination = true,
  csvExportable = false,
  csvExportableFilename = 'export.csv',
  rowsPerPage = [10, 25, 50],
  error = null,
  checkHandler = null,
  rowActions = [],
  count,
  allowEditing = true,
  keyProp = null,
  loading = false,
  clientPagination,
  rowOptsApplier,
  isRowEditable = (row: any) => {
    return true
  },
  onChangePage = () => {},
  onChangeRowsPerPage = () => {},
  initPage,
  initPageSize,
  customToolbar,
  onEditRow = () => {},
  onSaveRow = () => {},
  onSave,
  onRowClick,
  isRowCheckable = null,
  rowColor = defaultTableRowColorer,
  LeftFooterItems = null,
}: DataTableProps) => {
  const [isCheckable, setIsCheckable] = useState<boolean>(
    isFunction(checkHandler)
  )
  const [initialized, setInitialized] = useState<boolean>(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [editingRows, setEditingRows] = useState<any>({})
  const [checkedItems, setCheckedItems] = useState<any>({})
  const [validationMessages, setValidationMessages] = useState<any>({})
  const [allChecked, setAllChecked] = useState<boolean>(false)

  useEffect(() => {
    if (
      Object.keys(checkedItems).length &&
      Object.keys(checkedItems).length === data.length
    )
      return setAllChecked(true)
    setAllChecked(false)
  }, [checkedItems])

  useEffect(() => {
    if (!initialized && initPage && initPageSize) {
      setPage(initPage)
      setPageSize(initPageSize)
      setInitialized(true)
    }
  }, [initPage, initPageSize])

  useEffect(() => {
    setCheckedItems({})
    setEditingRows({})
  }, [data])

  const _getPageRange = (page: number, pageSize: number) => {
    return [page === 1 ? 1 : (page - 1) * pageSize + 1, pageSize * page]
  }

  const handleChangePage = (_event: any, page: number) => {
    const nonIndexPage = page + 1
    setPage(nonIndexPage)
    setEditingRows({})
    onChangePage(
      {
        page: nonIndexPage,
        pageSize,
        range: _getPageRange(nonIndexPage, pageSize),
      },
      sortable
    )
    uncheckAllRows()
  }

  const handleChangeRowsPerPage = (event: any) => {
    const _pageSize = event.target.value
    setPageSize(_pageSize)
    onChangeRowsPerPage(
      { _pageSize, page, range: _getPageRange(page, _pageSize) },
      sortable
    )
    uncheckAllRows()
  }

  const renderNoResults = () => {
    return (
      <TableRow>
        <TableCell variant="footer" colSpan={10}>
          No Results
        </TableCell>
      </TableRow>
    )
  }

  const _getRowKey = (row: any) => {
    // To avoid display bugs - default to making the rowKey a random number if
    // keyProp isn't set
    if (!keyProp) {
      return `${Math.round(Math.random() * 1000000000)}`
    }
    // Sometimes need compound properties to form a unique key; so keyProp
    // *can* be an array
    if (isArray(keyProp)) {
      return keyProp
        .map((k) => {
          return row[k]
        })
        .join('_')
    }
    // Normally its just a string mapping to a rowColumn though...
    return row[keyProp]
  }

  const _handleToggleRowCheck = (key: string | number, row: any) => {
    if (checkedItems[key]) {
      setCheckedItems(omit(checkedItems, key))
      handleCheckboxCheck(omit(checkedItems, key))
      return
    }
    setCheckedItems({ ...checkedItems, [key]: row })
    handleCheckboxCheck({ ...checkedItems, [key]: row })
  }

  const _toggleCheckAll = (ev: any) => {
    if (ev.target.checked) {
      const all: any = {}
      data.forEach((row) => {
        const include = isRowCheckable ? isRowCheckable(row) : true
        if (include) {
          all[_getRowKey(row)] = row
        }
      })
      setCheckedItems(all)
      handleCheckboxCheck(all)
      return
    }
    uncheckAllRows()
  }

  const handleCheckboxCheck = (
    rowsByObjKey: Pick<any, symbol> | ArrayLike<unknown>
  ) => {
    if (checkHandler) {
      checkHandler(Object.values(rowsByObjKey))
    }
  }

  // When an event occurs that should cause the table to deselect all currently
  // checked rows (ie. changing a page, a search, etc), this will update the state
  // AND propagate that to the checkHandler prop (which is either a no-op, or
  // something the parent component passes in, expecting to receive the list of
  // checked rows every time it changes)
  const uncheckAllRows = () => {
    setCheckedItems({})
    isFunction(checkHandler) && checkHandler([])
  }

  const handleEditCell = (
    rowKey: string | number,
    col: any,
    valProperty: string | number
  ) => {
    return (e: any) => {
      // todo: bit hacky...
      let val
      if (typeof e === 'string') {
        val = e
      } else {
        val = valProperty ? e.target[valProperty] : e.target.value
      }
      setEditingRows({
        ...editingRows,
        [rowKey]: {
          ...editingRows[rowKey],
          [col]: val,
        },
      })
    }
  }

  const toggleEdit = (rowKey: any, row: any) => {
    return () => {
      setEditingRows({
        ...editingRows,
        [rowKey]: row,
      })
      onEditRow(row, rowKey)
    }
  }

  const onCancelEdit = (rowKey: string | number) => {
    return () => {
      setEditingRows({
        ...editingRows,
        [rowKey]: undefined,
      })
      setValidationMessages({})
    }
  }

  const _onSaveRow = (rowKey: string | number) => {
    return () => {
      const rowToSave = editingRows[rowKey]

      // Make sure validations aren't still around before saving
      if (
        Object.keys(validationMessages) &&
        Object.keys(validationMessages).length === 0
      ) {
        onSaveRow(rowToSave, rowKey)
          .then((res: any) => {
            // with a good save result, we will remove it from the list of rows being edited
            setEditingRows({
              ...editingRows,
              [rowKey]: undefined,
            })
          })
          .catch((err: any) => {
            console.error('Error saving row on datatable, ', err)
          })
      }
    }
  }

  const renderRowActions = () => {
    if (rowActions.length > 0) {
      return (
        <TableCell padding="checkbox">
          <IconButton
            onClick={() => {
              console.debug(
                'You need to implement the rowActions feature on datatable to make this do something'
              )
            }}>
            <IconList fontSize="inherit" />
          </IconButton>
        </TableCell>
      )
    }

    return null
  }

  const renderRowCancel = (rowKey: any, row: any, editing: any) => {
    if (isFunction(onEditRow) && allowEditing) {
      if (editing) {
        return (
          <TableCell padding="checkbox">
            <IconButton onClick={onCancelEdit(rowKey)}>
              <IconCancel fontSize="inherit" />
            </IconButton>
          </TableCell>
        )
      }
    }

    return null
  }

  const renderRowEditSave = (
    rowKey: any,
    row: any,
    editing: any,
    isRowEditable: (arg0: any) => any
  ) => {
    if (isFunction(onEditRow) && allowEditing) {
      if (!editing) {
        const editBtn = (
          <IconButton onClick={toggleEdit(rowKey, row)}>
            <IconEdit fontSize="inherit" />
          </IconButton>
        )

        // Make sure row passes the isRowEditable check before rendering edit
        return (
          <TableCell padding="default">
            {isRowEditable(row) ? editBtn : <div />}
          </TableCell>
        )
      } else {
        return (
          <TableCell padding="default">
            <IconButton onClick={_onSaveRow(rowKey)}>
              <IconSave fontSize="inherit" />
            </IconButton>
          </TableCell>
        )
      }
    }

    return null
  }

  const renderEditCell = ({ row, details, rowKey, col, index }: any) => {
    const value = row[col]
    if (!details) {
      return (
        <TableCell key={index} padding="default" align="left">
          {value}
        </TableCell>
      )
    }

    const { editable, EditComponent, valProperty, editComponentOpts } = details
    if (editable && EditComponent !== undefined) {
      const opts = editComponentOpts || {}
      const valProp = valProperty || 'value'
      const valProps = {
        [valProp]: value,
      }

      const handleEditComponentBlur = (event: any) => {
        if (details.validators) {
          let failedValidations = false

          // Check to see if any of the required validations fail
          _.forEach(details.validators, (value, key) => {
            if (value && value.isValid) {
              const successfulValidation = value.isValid(event.target.value)
              if (!successfulValidation) {
                failedValidations = true
                setValidationMessages({
                  ...validationMessages,
                  [index]: value.msg,
                })
              }

              // Handle the case where no validations fail
              if (!failedValidations) {
                const tempMessagesObject: any = { ...validationMessages } // so we keep state immutable
                delete tempMessagesObject[index] // Remove the message we no longer want to show

                // Set state with the validation message for this index removed
                setValidationMessages({ ...tempMessagesObject })
              }
            }
          })
        }
      }

      return (
        <TableCell key={index} padding="default" align="left">
          <EditComponent
            {...opts}
            {...valProps}
            error={
              validationMessages[index] !== undefined &&
              validationMessages[index] != null
            }
            helperText={
              validationMessages[index] ? validationMessages[index] : ''
            }
            key={index}
            onChange={handleEditCell(rowKey, col, valProperty)}
            onBlur={handleEditComponentBlur}
          />
        </TableCell>
      )
    }

    return renderCell({ details, row, col, index })
  }

  const renderCell = ({ details, row, col, index }: any) => {
    if (!details) {
      const content = row[col]
      return (
        <TableCell key={index} padding="default" align="left">
          {content}
        </TableCell>
      )
    }

    // notice: detailsNoFormat contains all of the 'details' properties from the model definition - *except* dataFormat AND
    // sortName, because they're extracted in the destructuring syntax used below. All the detailsNoFormat properties will be spread
    // onto the <TableCell />. We're pulling out sortName because the <TableCell/> component will throw a warning about an unknown
    // prop if we pass it in; so sortName is purposefully unused anywhere.
    const {
      dataFormat,
      sortName,
      editable,
      EditComponent,
      align,
      ...detailsNoFormat
    } = details
    const content = dataFormat ? dataFormat(row[col], row, index) : row[col]
    return (
      <TableCell
        key={index}
        padding="default"
        align={align ? align : 'left'}
        {...detailsNoFormat}>
        {content}
      </TableCell>
    )
  }

  const _onRowClick = (event: any, row: any) => {
    const { target } = event

    if (target && target.type === 'checkbox') {
      return
    }

    // custom property that can be set on child's event.currentTarget
    if (event.target && event.target.preventRowClick) {
      return
    }

    if (!onRowClick) return

    return onRowClick(event, row)
  }

  const renderResultRows = () => {
    const clickableRow = !!onRowClick

    // Correctly slice up the data to display in the table for pagination
    const slicedData = data.slice(
      page !== 1 ? (page - 1) * pageSize : 0,
      page !== 1 ? (page - 1) * pageSize + pageSize : pageSize
    )

    const pagedData = clientPagination ? slicedData : data

    if (pagedData.length === 0) {
      return renderNoResults()
    }

    return pagedData.map((row) => {
      let rowOpts = {}
      if (rowOptsApplier && rowOptsApplier(row) !== null) {
        rowOpts = rowOptsApplier(row)
      }
      const rowKey = _getRowKey(row)
      const editing = editingRows[rowKey] !== undefined
      const rowCheckDisabled = isRowCheckable ? !isRowCheckable(row) : false

      return (
        <TableRow
          {...rowOpts}
          className={clickableRow ? classes.clickable : ''}
          // style={ row.ArchivedAt ? { background: '#ededed' } : undefined }
          style={{ background: rowColor(row) ?? undefined }}
          hover
          tabIndex={-1}
          onClick={(event: any) => _onRowClick(event, row)}
          key={rowKey}>
          {isCheckable && !editing ? (
            <TableCell padding="checkbox">
              <Checkbox
                disabled={rowCheckDisabled}
                checked={!!checkedItems[rowKey]}
                onChange={() => _handleToggleRowCheck(rowKey, row)}
              />
            </TableCell>
          ) : null}
          {renderRowActions()}
          {renderRowCancel(rowKey, row, editing)}
          {renderRowEditSave(rowKey, row, editing, isRowEditable)}
          {Object.keys(columns).map((col, ix) => {
            const { details } = columns[col]
            if (details && details.hidden) return false
            if (editing)
              return renderEditCell({
                row: editingRows[rowKey],
                details,
                rowKey,
                col,
                index: ix,
              })
            return renderCell({ details, row, col, index: ix })
          })}
        </TableRow>
      )
    })
  }

  const renderError = () => {
    return (
      <TableRow>
        <TableCell variant="footer">
          {getError}. If you need help, please report this to
          support@zero.health
        </TableCell>
      </TableRow>
    )
  }

  const formatData = (data: any) => {
    return data.map((row: any) => {
      return Object.keys(row).map((key) => {
        if (columns[key].details && columns[key].details.dataFormat) {
          return columns[key].details.dataFormat(row, row)
        }

        return row[key]
      })
    })
  }

  const exportCSV = (ev: any) => {
    const formatted = formatData(data)

    const asCSV = papa.unparse(formatted)
    const b = new Blob([asCSV], {
      type: 'text/csv;charset=utf-8',
    })
    const dlURL = URL.createObjectURL(b)
    ev.target.setAttribute('href', dlURL)
    ev.target.setAttribute('download', csvExportableFilename)
  }

  let pageIndex = page - 1

  // make sure we dont end up on a page that wont have any data
  if (count && count - page * pageSize <= pageSize * -1) {
    if (page !== 1) {
      handleChangePage({}, 0)
      pageIndex = 0
    }
  }

  return (
    <Paper
      className={`${classes.root} data-table-wrapper`}
      style={{ position: 'relative' }}
      elevation={4}>
      {customToolbar}
      <div className={`${classes.tableWrapper} data-table-wrapper-l2`}>
        {loading ? <LinearProgress /> : <div />}
        <Table
          // size="small"
          // padding="none"
          className={classes.table}
          aria-labelledby="tableTitle">
          <EnhancedTableHead
            sortable={sortable}
            sortHandler={sortHandler}
            rowCount={data.length}
            columns={columns}
            canEdit={isFunction(onEditRow) && allowEditing}
            hasActions={!isEmpty(rowActions)}
            isCheckable={isCheckable}
            toggleCheckAll={_toggleCheckAll}
            pageSize={pageSize}
            page={page}
            allChecked={allChecked}
          />
          <TableBody>
            {error !== null ? renderError() : renderResultRows()}
          </TableBody>
          <TableFooter className="data-table-footer" style={{ border: 'none' }}>
            <tr style={{ border: 'none', padding: 0 }}>
              <td colSpan={1000} style={{ border: 'none', padding: 0 }}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item xs={12} md="auto">
                    {!!LeftFooterItems && (
                      <div style={{ padding: '1rem' }}>{LeftFooterItems}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md="auto">
                    {pagination && (
                      <TablePagination
                        component={'div'}
                        rowsPerPageOptions={rowsPerPage}
                        count={count!}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        onPageChange={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    )}
                  </Grid>
                </Grid>
              </td>
            </tr>
          </TableFooter>
        </Table>
        {csvExportable ? (
          <div className={classes.btnExportable}>
            <button
              key={csvExportableFilename}
              className={classes.exportableLink}
              onClick={exportCSV}>
              Export CSV
            </button>
          </div>
        ) : null}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(DataTable)
