/**
 * All of the colors used throughout the application
 *
 * @todo: SD-2591: move to constants in the design suite; and also add those
 * to the theme provider
 */
export const COLORS = {
  EMPLOYERS: {
    RASPBERRY: '#ff008a',
    CHERRY: '#ff0000',
    ORANGE_SLICE: '#ffa500',
    TUSCANY: '#ffe100',
  },
  PROVIDERS: {
    BLUEBERRY: '#0059c9',
    EGGPLANT: '#7c17b9',
    FUSCIA: '#e800a9',
    VIOLET: '#d100ad',
  },
  MEMBERS: {
    BLUE_JEANS: '#00397e',
    OCEAN: '#007ec0',
    CYAN: '#00c5ff',
    BLUE_SKIES: '#009eff',
  },
  BW: {
    INK_BLACK: '#00000a',
    GUNMETAL: '#3f3f40',
    CLOUDS: '#e6e7e8',
    WHITE: '#FFFFFF',
  },
  DEV_APP_BAR: '#c4ff9f', // mint
  STG_APP_BAR: '#ff9fd3', // strawberry
}
