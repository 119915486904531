import React from 'react'
import * as styler from '@material-ui/core/styles'

const theme = styler.createTheme({
  typography: {
    fontFamily: 'Open Sans',
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontWeight: 700,
      },
    },
    MuiDivider: {
      root: {
        margin: 10,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#0059c91c',
      },
    },
  },
})

export default function ZeroThemeProvider({
  children,
}: any): React.ReactElement | null {
  return <styler.ThemeProvider theme={theme}>{children}</styler.ThemeProvider>
}
