import React, { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import { useConfig } from '../../providers/Config'
import * as api from '../../services/ApiService'

interface NetworkDownProps {
  down: boolean
  showMsg: boolean
}

export const NetworkDown = ({ down, showMsg }: NetworkDownProps) => {
  const getNetworkMsg = () => {
    if (down)
      return 'Having issues connecting to the server. Please check your internet connection'
    return 'Network connectivity restored. You may want to refresh the page.'
  }
  const msg = getNetworkMsg()

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showMsg}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{msg}</span>}
    />
  )
}

export const NetworkDownContainer = () => {
  const [down, setDown] = useState(false)
  const [showMsg, setShowMsg] = useState(false)

  useEffect(() => {
    api.setNetworkDownHandler((req: any) => {
      handleNetworkDown()
    })
    api.setNetworkUpHandler((up: any) => {
      handleNetworkUp()
    })
  }, [])

  const pingApi = () => {
    //@ts-ignore  --- will have to update Config.js for this
    const { baseUrl } = useConfig()
    try {
      fetch(`${baseUrl}/public/healthcheck`)
        .then(handleNetworkUp)
        .catch(handleFailedPing)
    } catch {
      console.warn(
        'Using an outdated browser, network connectivity monitoring will not work - please update or switch to Chrome/Firefox'
      )
    }
  }

  const handleNetworkDown = () => {
    if (!down) pingApi()
    setDown(true)
    setShowMsg(true)
  }

  const handleNetworkUp = () => {
    setTimeout(() => setShowMsg(false), 4000)
    setDown(false)
  }

  const handleFailedPing = () => {
    setTimeout(pingApi, 2000)
  }

  return <NetworkDown down={down} showMsg={showMsg} />
}
