import React from 'react'
import DesignSuite24 from '../DesignSuite24'
import styled from 'styled-components'
import { Typography, IconButton, Button } from '@material-ui/core'
import useLocalStorageCache from '../../hooks/useLocalStorageCache'

const StyledAnnouncement = styled.div`
  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    max-height: 350px;

    background-image: linear-gradient(
        246deg,
        rgba(234, 234, 234, 0.04) 0%,
        rgba(234, 234, 234, 0.04) 33.3%,
        rgba(69, 69, 69, 0.04) 33.3%,
        rgba(69, 69, 69, 0.04) 66.6%,
        rgba(189, 189, 189, 0.04) 66.6%,
        rgba(189, 189, 189, 0.04) 99.89999999999999%
      ),
      linear-gradient(
        81deg,
        rgba(126, 126, 126, 0.05) 0%,
        rgba(126, 126, 126, 0.05) 33.3%,
        rgba(237, 237, 237, 0.05) 33.3%,
        rgba(237, 237, 237, 0.05) 66.6%,
        rgba(74, 74, 74, 0.05) 66.6%,
        rgba(74, 74, 74, 0.05) 99.89999999999999%
      ),
      linear-gradient(
        14deg,
        rgba(3, 3, 3, 0.08) 0%,
        rgba(3, 3, 3, 0.08) 33.3%,
        rgba(156, 156, 156, 0.08) 33.3%,
        rgba(156, 156, 156, 0.08) 66.6%,
        rgba(199, 199, 199, 0.08) 66.6%,
        rgba(199, 199, 199, 0.08) 99.89999999999999%
      ),
      linear-gradient(
        323deg,
        rgba(82, 82, 82, 0.06) 0%,
        rgba(82, 82, 82, 0.06) 33.3%,
        rgba(179, 179, 179, 0.06) 33.3%,
        rgba(179, 179, 179, 0.06) 66.6%,
        rgba(212, 212, 212, 0.06) 66.6%,
        rgba(212, 212, 212, 0.06) 99.89999999999999%
      ),
      linear-gradient(
        32deg,
        rgba(70, 70, 70, 0.02) 0%,
        rgba(70, 70, 70, 0.02) 33.3%,
        rgba(166, 166, 166, 0.02) 33.3%,
        rgba(166, 166, 166, 0.02) 66.6%,
        rgba(53, 53, 53, 0.02) 66.6%,
        rgba(53, 53, 53, 0.02) 99.89999999999999%
      ),
      linear-gradient(
        38deg,
        rgba(129, 129, 129, 0.09) 0%,
        rgba(129, 129, 129, 0.09) 33.3%,
        rgba(38, 38, 38, 0.09) 33.3%,
        rgba(38, 38, 38, 0.09) 66.6%,
        rgba(153, 153, 153, 0.09) 66.6%,
        rgba(153, 153, 153, 0.09) 99.89999999999999%
      ),
      linear-gradient(
        63deg,
        rgba(51, 51, 51, 0.02) 0%,
        rgba(51, 51, 51, 0.02) 33.3%,
        rgba(12, 12, 12, 0.02) 33.3%,
        rgba(12, 12, 12, 0.02) 66.6%,
        rgba(158, 158, 158, 0.02) 66.6%,
        rgba(158, 158, 158, 0.02) 99.89999999999999%
      ),
      linear-gradient(
        227deg,
        rgba(63, 63, 63, 0.03) 0%,
        rgba(63, 63, 63, 0.03) 33.3%,
        rgba(9, 9, 9, 0.03) 33.3%,
        rgba(9, 9, 9, 0.03) 66.6%,
        rgba(85, 85, 85, 0.03) 66.6%,
        rgba(85, 85, 85, 0.03) 99.89999999999999%
      ),
      linear-gradient(
        103deg,
        rgba(247, 247, 247, 0.07) 0%,
        rgba(247, 247, 247, 0.07) 33.3%,
        rgba(93, 93, 93, 0.07) 33.3%,
        rgba(93, 93, 93, 0.07) 66.6%,
        rgba(208, 208, 208, 0.07) 66.6%,
        rgba(208, 208, 208, 0.07) 99%
      ),
      linear-gradient(0deg, #0b91d7, #6efc29);

    .content {
      text-align: center;

      h1 {
        color: #fff;
        margin: 0 0 1rem;
        padding: 1rem 0 0;
        font-size: 450%;
        font-weight: 900;
        text-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
      }
    }

    .closer {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  section {
    padding: 3rem;

    p {
      margin-bottom: 1rem;
    }
  }
`

export default function ReleaseAnnouncement() {
  const [hasViewed, setHasViewed] = useLocalStorageCache(
    'releaseAnnounce2024Dec',
    false
  )
  const isOpen = !hasViewed

  return (
    <DesignSuite24.Dialog
      maxWidth="lg"
      open={isOpen}
      onClose={() => setHasViewed(true)}
      noContentPadding>
      <StyledAnnouncement>
        <header>
          <IconButton className="closer" onClick={() => setHasViewed(true)}>
            <DesignSuite24.CommonIcons.IconClose />
          </IconButton>
          <div className="content">
            <h1>goZERO 2024 Release</h1>
            <Button
              variant="contained"
              size="large"
              onClick={() => setHasViewed(true)}>
              Close And Continue
            </Button>
          </div>
        </header>
        <section>
          <Typography variant="body1">
            goZERO is getting a fresh new look for the new year! We’ve delivered
            the same functionality and information you’re used to seeing, but in
            a more effective and thoughtful way. Hopefully you will notice some
            performance improvements as well. If you’re curious, try using the
            “Start Tour” feature at the top of your screen.
          </Typography>
          <Typography variant="body1">
            <strong>We would love to hear your feedback.</strong> Drop us an
            email at{' '}
            <a href="mailto:providers@zero.health">providers@zero.health</a> any
            time.
          </Typography>
        </section>
      </StyledAnnouncement>
    </DesignSuite24.Dialog>
  )
}
