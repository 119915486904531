import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  Typography,
  SvgIconTypeMap,
  SvgIconProps,
} from '@material-ui/core'
import {
  CheckCircle as IconCheckCircle,
  Create as IconCreate,
  CallReceived as IconCallReceived,
  Done as IconDone,
  Send as IconSend,
  HourglassEmpty as IconHourglass,
  SentimentVeryDissatisfied as IconNonResponsive,
} from '@material-ui/icons'
import _ from 'lodash'
import useApi from '../../hooks/useApi'
import usePolling from '../../hooks/usePolling'
// import AddNewReferralBtn from '../Referrals/AddNewReferralButton'
import referralStatusConsts from '../ReferralForm/statuses'
import styled from 'styled-components'

const StyledStatusBar = styled.div``

interface ReferralsItem {
  Amount: number
  Status: string
  StatusID: number
}

interface Props {
  RenderComponent?(props: any): React.ReactElement
}

const statusInfo = {
  999: {
    icon: <IconSend fontSize="inherit" />,
  },
  [referralStatusConsts.REFERRAL_STATUS_NEW]: {
    icon: <IconCreate fontSize="inherit" />,
  },
  [referralStatusConsts.REFERRAL_STATUS_ACCEPTED]: {
    icon: <IconCallReceived fontSize="inherit" />,
  },
  [referralStatusConsts.REFERRAL_STATUS_AWAITING_ORDERS]: {
    icon: <IconHourglass fontSize="inherit" />,
  },
  [referralStatusConsts.REFERRAL_STATUS_AWAITING_SCHEDULING]: {
    icon: <IconCheckCircle fontSize="inherit" />,
  },
  [referralStatusConsts.REFERRAL_STATUS_PATIENT_NON_RESPONSIVE]: {
    icon: <IconNonResponsive fontSize="inherit" />,
  },
  // [referralStatusConsts.REFERRAL_STATUS_SCHEDULED]: {
  //   icon: <IconDone />,
  // },
}

const statusDisplayOrder = [
  999,
  referralStatusConsts.REFERRAL_STATUS_NEW,
  referralStatusConsts.REFERRAL_STATUS_ACCEPTED,
  referralStatusConsts.REFERRAL_STATUS_AWAITING_ORDERS,
  referralStatusConsts.REFERRAL_STATUS_AWAITING_SCHEDULING,
  referralStatusConsts.REFERRAL_STATUS_PATIENT_NON_RESPONSIVE,
  // referralStatusConsts.REFERRAL_STATUS_SCHEDULED,
]

export default function ReferralStatusBar({
  RenderComponent,
}: Props): React.ReactElement {
  const [statusData, setStatusData] = useState<
    Array<ReferralsItem & { icon: React.ReactElement }>
  >([])

  const { data: statusSummary, refetch: refetchStatusSummary } = useApi({
    route: '/referral_analytics/statuses',
  })

  const { data: referralStatuses = [] } = useApi({
    route: '/combined_statuses',
  })

  usePolling({
    duration: 15000,
    callback: React.useCallback(() => {
      return refetchStatusSummary()
    }, []),
  })

  useEffect(() => {
    mapStatuses(statusSummary)
  }, [statusSummary])

  function mapStatuses(statusSummary: ReferralsItem[] | null) {
    if (!statusSummary?.length) return
    const mapped = statusSummary.reduce((acc: any, item: ReferralsItem) => {
      acc[item.StatusID] = { ...item, icon: statusInfo[item.StatusID]?.icon }
      return acc
    }, {})
    const ordered = [...statusDisplayOrder]
      .map((key: number) => mapped[key])
      .filter((v) => !!v)
    setStatusData(ordered)
  }

  if (!referralStatuses || referralStatuses.length === 0 || !statusSummary)
    return (
      <StyledStatusBar>
        <LinearProgress />
      </StyledStatusBar>
    )

  if (RenderComponent) {
    return (
      <StyledStatusBar className="referral-status-bar">
        {statusData.map((x: any) => {
          // @todo this needs to be integrated into the useQueryParams better
          const qsParams = `filter=${encodeURIComponent(
            `StatusID[]=${x.StatusID}`
          )}`
          const linkToParams = {
            pathname: '/referrals',
            search: qsParams,
            state: { StatusID: x.StatusID },
          }
          return (
            <RenderComponent
              key={x.StatusID}
              {...x}
              linkToParams={linkToParams}
            />
          )
        })}
      </StyledStatusBar>
    )
  }

  return (
    <StyledStatusBar className="referral-status-bar">
      <Card style={{ width: '100%' }}>
        {/* <CardHeader title="Referrals Snapshot" action={<AddNewReferralBtn />} /> */}
        <CardContent>
          <Stepper activeStep={Object.keys(statusInfo).length} alternativeLabel>
            {statusData.map((x: any) => {
              // @todo this needs to be integrated into the useQueryParams better
              const qsParams = `filter=${encodeURIComponent(
                `StatusID[]=${x.StatusID}`
              )}`

              return (
                <Step key={x.StatusID}>
                  <StepLabel icon={x.icon}>
                    <Typography variant="h6">{x.Amount}</Typography>
                    <Link
                      to={{
                        pathname: '/referrals',
                        search: qsParams,
                        state: { StatusID: x.StatusID },
                      }}>
                      {x.Status}
                    </Link>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </CardContent>
      </Card>
    </StyledStatusBar>
  )
}
