import React, { useState, useLayoutEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryFallback } from '../ErrorBoundaryFallback'
import { SnackMessage } from '../SnackMessage'
import SideBar from './sidebar'
import TopBar from './topbar'
import { createGlobalStyle } from 'styled-components'
import { useScrollTrigger } from '@material-ui/core'

const StyledGlobal = createGlobalStyle`
:root {
  --sidebar-width: 210px;
  --header-height: 60px;
}

#app-frame-l1 {
  height: 100vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  margin: 0 auto;
  transition: all 0.15s ease-in-out;
}

// #app-frame-l2 {
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   margin: 0 auto;
//   // padding: calc(75px + 1rem) 1rem 1rem calc(var(--sidebar-width) + 1rem);
// }

#app-frame-container {
  height: fit-content;
  min-height: 100vh;
  align-self: start;
  // max-width: calc(100vw - var(--sidebar-width));
  width: calc(100% - var(--sidebar-width));
  overflow: clip;
  overflow-clip-margin: 1rem;
  margin-left: var(--sidebar-width);
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  transition: all 0.15s ease-in-out;
  z-index: 1;
}

#app-frame-content {
  padding: 1rem;
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - var(--header-height));
  width: 100%;
  // max-height: calc(100vh - var(--header-height) - 1rem);
  // overflow-y: visible;
  // min-height: 100vh;
  // max-height: calc(100vh - var(--header-height) - 1rem);
  box-sizing: border-box;
}

// global: @todo: put this elsewhere
hr {
  background: linear-gradient(
    -45deg,
    rgb(0, 89, 201) 0%,
    rgb(124, 23, 185) 29%,
    rgb(232, 0, 169) 69%,
    rgb(209, 0, 173) 96%
  );
  height: 2px;
  border: 0;
  opacity: 0.75;
  margin: 2rem 0;
}

@media (min-width: 2200px) {
  body:not(.full-width) {
    #app-frame-l1 {
      max-width: 2200px;
    }
  }
}

body.sidebarless {
  #app-frame-sidebar {
    opacity: 0;
  }
  
  #app-frame-container {
    max-width: 100%;
    margin-left: 0;
  }
}

body.is-scrolled #app-frame-header {
  background: rgba(0, 0, 0, 0.18);
}

body.frame-height-lock {
  #app-frame-container {
    height: 100vh;
  }
  #app-frame-content {
    height: calc(100vh - var(--header-height));
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  // necessary?
  // &.frame-content-hide-overflow {
  //   #app-frame-content {
  //     overflow: hidden;
  //   }
  // }
}
`

interface appFrameProps {
  children: any
  content: any
  contentKey: string
  title: string
}

type HeaderContent = React.ReactElement | null

interface shapeAppFrameContext {
  headerContentLeft?: HeaderContent
  setHeaderContentLeft(re: HeaderContent): void
  headerContentMid?: HeaderContent
  setHeaderContentMid(re: HeaderContent): void
  frameHeightLock?: boolean
  setFrameHeightLock(lock: boolean): void
}

export const appFrameContext = React.createContext<shapeAppFrameContext>(
  {} as shapeAppFrameContext
)

export default function AppFrame(props: appFrameProps) {
  const [frameState, setFrameState] = useState({
    sidebarless: false,
    'full-width': false,
    'is-scrolled': false,
  })
  const [headerContentLeft, setHeaderContentLeft] =
    useState<HeaderContent>(null)
  const [headerContentMid, setHeaderContentMid] = useState<HeaderContent>(null)
  const [frameHeightLock, setFrameHeightLock] = useState(false)
  const isScrolled = useScrollTrigger({
    target: window,
    threshold: 10,
    disableHysteresis: true,
  })

  useLayoutEffect(() => {
    for (const [k, v] of Object.entries(frameState)) {
      document.body.classList.toggle(k, v)
    }
  }, [frameState])

  useLayoutEffect(() => {
    setFrameState((curr) => ({ ...curr, 'is-scrolled': isScrolled }))
  }, [isScrolled])

  useLayoutEffect(() => {
    document.body.classList.toggle('frame-height-lock', frameHeightLock)
  }, [frameHeightLock])

  return (
    <appFrameContext.Provider
      value={{
        headerContentLeft,
        setHeaderContentLeft,
        headerContentMid,
        setHeaderContentMid,
        frameHeightLock,
        setFrameHeightLock,
      }}>
      <StyledGlobal />
      <main id="app-frame-l1">
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <SnackMessage />
          {/* <div id="app-frame-l2"> */}
          <SideBar />
          <div id="app-frame-container">
            <TopBar frameState={frameState} setFrameState={setFrameState} />
            <div id="app-frame-content">{props.children}</div>
          </div>
          {/* </div> */}
        </ErrorBoundary>
      </main>
      {/* <RenderAppFrame {...props} /> */}
    </appFrameContext.Provider>
  )
}

export function useSetFrameHeaderLeft(h: any, watches: Array<any> = []): void {
  const { setHeaderContentLeft } = React.useContext(appFrameContext)
  const comp: any = React.useMemo(h, watches)

  React.useLayoutEffect(() => {
    setHeaderContentLeft(comp)
    return () => setHeaderContentLeft(null)
  }, [comp])
}

export function useSetFrameHeaderMid(h: any, watches: Array<any> = []): void {
  const { setHeaderContentMid } = React.useContext(appFrameContext)
  const comp: any = React.useMemo(h, watches)

  React.useLayoutEffect(() => {
    setHeaderContentMid(comp)
    return () => setHeaderContentMid(null)
  }, [comp])
}

export function useFrameHeightLock(
  lock: () => boolean,
  watches: Array<any> = []
): void {
  const { setFrameHeightLock } = React.useContext(appFrameContext)

  React.useLayoutEffect(() => {
    setFrameHeightLock(lock())
    return () => setFrameHeightLock(false)
  }, watches)
}

// import styled from 'styled-components'
// import { COLORS } from '../../utils/colors'

// export const MainComponent = styled.main`
//   background-color: ${COLORS.BW.WHITE};
//   padding: 24px;
//   width: 100%;
//   padding-bottom: 500px;
// `
// export const Root = styled.div`
//   flexgrow: 1;
// `

// export const Content = styled.div`
//   padding-top: 60px;
// `

// export const AppFrameStyled = styled.div`
//   height: 100%;
//   width: 100%;
//   z-index: 1;
//   position: relative;
//   display: flex;
// `
