import React from 'react'
// import { Link as RouterLink } from 'react-router-dom'
import {
  // Paper as MUIPaper,
  // Grid as MUIGrid,
  // Typography as MUITypography,
  // Tabs as MUITabs,
  // Tab as MUITab,
  Tooltip as MUITooltip,
  IconButton as MUIIconButton,
  // TableContainer as MUITableContainer,
  // Table as MUITable,
  // CircularProgress as MUICircularProgress,
  // Link as MUILink,
  // Card as MUICard,
  // CardHeader as MUICardHeader,
  // CardContent as MUICardContent,
  // Button as MUIButton,
  // Drawer as MUIDrawer,
  // DrawerProps as MUIDrawerProps,
  Dialog as MUIDialog,
  DialogTitle as MUIDialogTitle,
  DialogContent as MUIDialogContent,
  DialogActions as MUIDialogActions,
  Typography as MUITypography,
  DialogProps as MUIDialogProps,
  CircularProgress as MUICircularProgress,
} from '@material-ui/core'
import {
  Edit as IconEdit,
  Visibility as IconVisibility,
  VisibilityOff as IconVisibilityOff,
  Close as IconClose,
  Add as IconAdd,
  Lock as IconLock,
  Info as IconInfo,
  Flip as IconReveal,
  OndemandVideo as IconVideo,
  Style as IconStyle,
  SwapHorizontalCircle as IconSwapCircle,
  Check as IconCheck,
  DoneAll as IconCheckAll,
  Delete as IconDelete,
  ArrowForward as IconArrowRight,
  ArrowBack as IconArrowLeft,
} from '@material-ui/icons'
import { Alert as MUIAlert } from '@material-ui/lab'
import styled from 'styled-components'

const StyledTooltipContent = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.25rem;
  line-height: 1.5;
  max-width: min(35vw, 650px);
`

export function Tooltip({
  children,
  title,
  ContainerStyle = {},
  ...spread
}: any) {
  return (
    <MUITooltip
      {...spread}
      arrow
      placement={spread?.placement || 'top'}
      PopperProps={{
        'data-zc-tooltip': 'true', // hack: see style.scss
      }}
      title={
        title ? (
          <StyledTooltipContent style={ContainerStyle}>
            {title}
          </StyledTooltipContent>
        ) : (
          ''
        )
      }>
      {children}
    </MUITooltip>
  )
}

export const Dialog = styled(
  React.forwardRef(function (
    {
      children,
      open,
      title,
      onClose,
      actionLeft,
      actionRight,
      fullWidth = true,
      maxWidth = 'md',
      disableCloseOnBackdropClick = false,
      disableCloseOnEsc = false,
      noContentPadding = false,
      fullHeight = false,
      ...spread
    }: {
      children: any
      open: boolean
      title?: string | React.ReactNode | undefined | null
      onClose: () => void
      actionLeft?: React.ReactNode
      actionRight?: React.ReactNode
      disableCloseOnBackdropClick?: boolean
      disableCloseOnEsc?: boolean
      noContentPadding?: boolean
      fullHeight?: boolean // fullHeight actually means: most of the vertical height of the window, except what default styles calc as the max height (which is exactly what we want)
    } & Omit<MUIDialogProps, 'title' | 'open' | 'onClose'>,
    ref: any
  ) {
    const refContent = React.useRef<any>(null)
    const rootPaperProps = React.useMemo(() => {
      if (!fullHeight) return {}
      return { style: { height: '100vh' } }
    }, [fullHeight])

    React.useImperativeHandle(
      ref,
      () => ({
        scrollToTop() {
          refContent.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        },
      }),
      [refContent]
    )

    function _onClose(ev: any, reason: any) {
      if (reason === 'backdropClick' && disableCloseOnBackdropClick) return
      if (reason === 'escapeKeyDown' && disableCloseOnEsc) return
      onClose?.()
    }

    return (
      <MUIDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={_onClose}
        PaperProps={rootPaperProps}
        {...spread}>
        {title && (
          <MUIDialogTitle
            disableTypography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: '0.5rem',
              background: '#f1f1f1',
              padding: '0.5rem 1rem',
            }}>
            {typeof title === 'string' ? (
              <MUITypography variant="h6">{title}</MUITypography>
            ) : (
              title
            )}
            <MUIIconButton onClick={onClose}>
              <IconClose fontSize="inherit" />
            </MUIIconButton>
          </MUIDialogTitle>
        )}
        <MUIDialogContent
          ref={refContent}
          style={{
            padding: noContentPadding ? 0 : '0.75rem 1rem',
          }}>
          {children}
        </MUIDialogContent>

        {(actionLeft || actionRight) && (
          <MUIDialogActions
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{actionLeft}</div>
            <div>{actionRight}</div>
          </MUIDialogActions>
        )}
      </MUIDialog>
    )
  })
)`
  .MuiDialog-paper {
    border-radius: 10px !important;
  }
`

export const LoadingSpinner = styled(({ ...spread }: any) => (
  <MUICircularProgress size={25} {...spread} />
))`
  margin: 0 auto;
`

export const AlertError = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="error">
    {children}
  </MUIAlert>
)

export const AlertWarning = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="warning">
    {children}
  </MUIAlert>
)

export const AlertSuccess = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="success">
    {children}
  </MUIAlert>
)

export const AlertInfo = ({ children, ...spread }: any) => (
  <MUIAlert {...spread} severity="info">
    {children}
  </MUIAlert>
)

export const CommonIcons = {
  IconEdit,
  IconVisibility,
  IconVisibilityOff,
  IconClose,
  IconAdd,
  IconLock,
  IconInfo,
  IconReveal,
  IconVideo,
  IconStyle,
  IconSwapCircle,
  IconCheck,
  IconCheckAll,
  IconDelete,
  IconArrowRight,
  IconArrowLeft,
}

export default {
  CommonIcons,
  Tooltip,
  Dialog,
  LoadingSpinner,
  AlertError,
  AlertWarning,
  AlertSuccess,
  AlertInfo,
  IconButton: MUIIconButton,
}
