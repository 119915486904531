import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { postReferralInvoice } from '../actions/ReferralInvoiceActions'
import dateTime from '../utils/dateTime'

const GenerateInvoiceButton: React.FC<{ referralId: string }> = ({
  referralId,
}) => {
  const history = useHistory()
  const generateInvoice = async () => {
    const res: any = await postReferralInvoice(referralId)
    if (res && res.Data && res.Data.ID) {
      history.push(`/referral_invoice/${res.Data.ID}`)
    }
  }

  return (
    <Button size="small" color="primary" onClick={generateInvoice}>
      <EditIcon width={20} />
      <span style={{ marginLeft: '7px' }}>Generate Invoice</span>
    </Button>
  )
}

export const ScheduledReferralModel = () => ({
  Descr: {
    name: 'Description',
  },
  CreatedByUser: {
    name: 'Name',
    details: {
      dataFormat: (_col: any, row: any) => {
        return row.EmployerMemberName
      },
    },
  },
  UpdatedAt: {
    name: 'Last Updated',
    details: {
      dataFormat(cell: any, row: any) {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {dateTime
              .parse(cell)
              .local()
              .format(dateTime.formats.PrettyVerbose)}
          </span>
        )
      },
    },
  },
  ServiceDate: {
    name: 'Service Date',
  },
  OrganizationName: {
    name: 'Organization',
    details: {
      sortName: 'OrganizationName',
    },
  },
  PracticeFacilityName: {
    name: 'Facility',
    details: {
      sortName: 'PracticeFacilityName',
    },
  },
  Notes: {
    name: 'Notes',
  },
  Generate: {
    name: 'Generate',
    details: {
      dataFormat: (_col: any, row: any) => {
        return <GenerateInvoiceButton referralId={row.ID} />
      },
    },
  },
})
