import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import useApi from '../../hooks/useApi'
import usePoller from '../../hooks/usePolling'
import ActivityList from '../Activity/ActivityList'
import { ClassNameMap } from '@material-ui/styles/withStyles'
import { Activity } from '../Activity/ActivityItem'
import useLocalStorageCache, {
  lastValueKeys,
} from '../../hooks/useLocalStorageCache'

const styles: any = () => {
  return {
    activityCard: {
      margin: 5,
    },
    activityContent: {
      paddingTop: 0,
    },
    noActivityContent: {
      textAlign: 'center',
    },
  }
}

interface NeedsReviewSummary {
  WeekOldCount: number
  MonthOldCount: number
}

export const NeedsReview = () => {
  const [oldestFirst, setOldestFirst] = useLocalStorageCache(
    lastValueKeys.NeedsReviewOldestFirst,
    false
  )
  const [activities, setActivities] = useState<{
    Activities: Activity[]
    Summary: NeedsReviewSummary | null
  }>({ Activities: [], Summary: null })

  const { data = [], refetch } = useApi(
    {
      route: '/activity/needs_review',
      sort: ['referral_activity.id', oldestFirst ? 'asc' : 'desc'],
    },
    {
      errorMessage:
        'There was a problem fetching data for the Needs Review list, please try refreshing or contact your administrator',
    }
  )

  const {
    startPolling,
    stopPolling,
    running: isPolling,
  } = usePoller({
    duration: 7500,
    callback: React.useCallback(() => {
      return refetch()
    }, [oldestFirst]),
  })

  useEffect(() => {
    refetch()
  }, [oldestFirst])

  useEffect(() => {
    if (data && data.Activities) {
      setActivities(data)
    } else {
      setActivities({ Activities: [], Summary: null })
    }
  }, [data])

  const removeActivity = (index: number) => {
    if (
      activities === null ||
      activities.Activities === undefined ||
      activities.Activities.length === 0
    )
      return null
    const updated = activities.Activities.filter((item: Activity) => {
      return item.ID !== index
    })
    setActivities({ Activities: updated, Summary: activities.Summary })
  }
  const handleAck = (id: number) => {
    removeActivity(id)
  }
  const monthOld =
    activities && activities.Summary
      ? activities.Summary.MonthOldCount
      : undefined
  const weekOld =
    activities && activities.Summary
      ? activities.Summary.WeekOldCount
      : undefined
  const dedupedWeekOld = weekOld && monthOld ? weekOld - monthOld : 0

  return (
    // <Card>
    <ActivityList
      title="Needs Review"
      showBanner
      allowAck
      NoActivityItem={NoActivityItem}
      refresh={refetch}
      activities={activities.Activities}
      onAck={handleAck}
      monthOld={monthOld}
      weekOld={dedupedWeekOld}
      oldestFirstValue={oldestFirst}
      oldestFirstSetter={setOldestFirst}
    />
    // </Card>
  )
}

const NoActivityItem = withStyles(styles)(({
  classes,
}: {
  classes: ClassNameMap<string>
}) => {
  return (
    <Card className={classes.activityCard}>
      <CardContent className={classes.noActivityContent}>
        <Done color="secondary" />
        <Typography variant="subtitle2">All caught up!</Typography>
        <Typography variant="caption">
          You have no activities to view.
        </Typography>
      </CardContent>
    </Card>
  )
})
