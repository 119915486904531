import { useHistory, useLocation } from 'react-router-dom'
import { parse, stringify } from 'query-string'
import { Cipher } from 'js-cipher'
import cloneDeep from 'lodash/cloneDeep'
import unset from 'lodash/unset'
import { useConfig } from '../providers/Config'

// add any params to parse as objects here
const objectParams = ['filter']

type Obj = { [key: string]: any }

const opts = {
  parseNumbers: true,
  parseBooleans: true,
  arrayFormat: 'bracket' as const,
}

export const parseParams = (params: string) => {
  const obj: Obj = parse(params, opts)
  for (const key in obj) {
    if (objectParams.includes(key)) {
      obj[key] = parse(obj[key], opts)
    }
  }
  return obj
}

export const stringifyParams = (params: Obj) => {
  const copy = cloneDeep(params)
  for (const key in copy) {
    if (typeof copy[key] === 'object' && !Array.isArray(copy[key])) {
      copy[key] = stringify(params[key], opts)
    }
  }
  return stringify(copy, opts)
}

export const convertSensitiveFilters = (
  params: Obj,
  sensitiveUrlParams: string[],
  fn: Function // encrypt or decrypt
) => {
  const copy = cloneDeep(params)
  const filter = copy.filter

  if (typeof filter === 'object') {
    for (const key in filter) {
      if (sensitiveUrlParams.includes(key)) {
        filter[key] = fn(filter[key])
      }
    }
  }

  return { ...copy, filter }
}

export const encryptParams =
  (encrKey: number) =>
  (params: string): string | undefined => {
    if (params) {
      params = `${params}`
      return new Cipher().encrypt(params, encrKey)
    }
  }

export const decryptParams =
  (encrKey: number) =>
  (params: string): string | undefined => {
    if (params) {
      params = `${params}`
      return new Cipher().decrypt(params, encrKey)
    }
  }

export default function (defaults?: object) {
  const hist = useHistory()
  const loc = useLocation()
  const { URL_PARAM_ENCR_KEY, sensitiveUrlParams }: any = useConfig()

  const stringifyEncrypt = (params: Obj) =>
    stringifyParams(
      convertSensitiveFilters(
        params,
        sensitiveUrlParams,
        encryptParams(URL_PARAM_ENCR_KEY)
      )
    )

  const params: any = convertSensitiveFilters(
    Object.assign({}, defaults, parseParams(loc.search)),
    sensitiveUrlParams,
    decryptParams(URL_PARAM_ENCR_KEY)
  )

  const setParams = (params: Obj | undefined) => {
    const str = params ? stringifyEncrypt(params) : undefined
    hist.push({ search: str })
  }

  return {
    params,
    setParams,
    setFilter,
    removeFilter,
  }
}

const setFilter = (params: any, filter: { [key: string]: any }) => {
  return { ...params, filter: { ...params.filter, ...filter } }
}

const removeFilter = (params: any, filterKey: string) => {
  const newParams = cloneDeep(params)
  unset(newParams, `filter.${filterKey}`) // safe delete, mutates the copy
  return newParams
}

export const utils = {
  setFilter,
  removeFilter,
}
