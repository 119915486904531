import React, { useLayoutEffect } from 'react'
import { useAuth } from '../../providers/Auth'
import { Link } from 'react-router-dom'
import { IconButton, Button, Avatar, Typography } from '@material-ui/core'
import { AssignmentInd as IconAssignment } from '@material-ui/icons'
import {
  ExitToApp as IconLogout,
  VerticalSplit as IconBorderLeft,
  AspectRatio as IconFullWidth,
} from '@material-ui/icons'
import { appFrameContext } from './index'
import DesignSuite24 from '../DesignSuite24'
import styled from 'styled-components'
import { COLORS } from '../../utils/colors'

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  min-height: var(--header-height);
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: background 0.15s ease-in-out;
  padding: 0 0 0 5.25rem;

  .user-email {
    display: none;
  }

  @media (min-width: 1500px) {
    .user-email {
      display: block;
    }
  }
`

const StyledToggleIconBtn = styled.span`
  display: inline-block;
  // opacity: 0.8;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  z-index: 101;
  transform: translateY(-50%);

  .MuiIconButton-root {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.4rem;
  }
`

const StyledAvatar = styled(Avatar)`
  color: ${COLORS.BW.GUNMETAL} !important;
  background-color: ${COLORS.BW.CLOUDS} !important;
  font-family: Open Sans;

  :hover {
    opacity: 0.5;
  }
`

export default function TopBar({
  frameState,
  setFrameState,
}: any): React.ReactElement | null {
  const { headerContentLeft, headerContentMid, frameHeightLock } =
    React.useContext(appFrameContext)
  const { access, zeroUser } = useAuth() as any

  const userFullName = zeroUser
    ? `${zeroUser.FirstName} ${zeroUser.LastName}`
    : ''

  function toggleSidebar() {
    setFrameState((curr: any) => ({
      ...curr,
      sidebarless: !curr['sidebarless'],
    }))
  }

  function toggleFullWidth() {
    setFrameState((curr: any) => ({
      ...curr,
      'full-width': !curr['full-width'],
    }))
  }

  return (
    <StyledHeader id="app-frame-header">
      <ToggleIcon
        IconComponent={IconBorderLeft}
        color={frameState['sidebarless'] ? 'secondary' : 'inherit'}
        onClick={toggleSidebar}
        style={{ left: '0.5rem' }}
        tooltip="Toggle Sidebar"
      />
      <ToggleIcon
        IconComponent={IconFullWidth}
        color={frameState['full-width'] ? 'secondary' : 'inherit'}
        onClick={toggleFullWidth}
        style={{ left: '2.75rem' }}
        tooltip="Toggle full width"
      />

      <div>{headerContentLeft}</div>
      <div>{headerContentMid}</div>

      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: '0.5rem',
          paddingRight: '0.5rem',
        }}>
        <Typography
          className="user-email"
          variant="subtitle2"
          color="textPrimary">
          {zeroUser?.Email || ''}
        </Typography>
        <Link
          to={{
            pathname: '/profile',
          }}
          style={{ textDecoration: 'none' }}>
          <StyledAvatar src={zeroUser?.Avatar}>
            {userFullName?.[0]}
          </StyledAvatar>
        </Link>
      </div>
    </StyledHeader>
  )
}

function ToggleIcon({ IconComponent, color, style, tooltip, onClick }: any) {
  return (
    <DesignSuite24.Tooltip title={tooltip}>
      <StyledToggleIconBtn style={style}>
        <IconButton size="small" color="inherit" onClick={onClick}>
          <IconComponent fontSize="inherit" color={color} />
        </IconButton>
      </StyledToggleIconBtn>
    </DesignSuite24.Tooltip>
  )
}
