import React, { useEffect, useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import Autocomplete from '../AutocompleteField'
import useApi from '../../hooks/useApi'
import { ReferralForm } from './types'
import TextField from '../TextField'
import { ReferralRequestForm } from '../ReferralRequestForm/types'
import { useAuth } from '../../providers/Auth'

// remove Quest from the organizations options
// @todo: SD-1539: is this still relevant?
const QUEST_ID = 1

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
  readOnly?: boolean
  useV2Style?: boolean
  withSelectedOrgNotes?(notes: string | null): void
}

const SelectOrganization: React.FC<Props> = ({
  form,
  showErrors,
  readOnly,
  useV2Style = false,
  withSelectedOrgNotes,
}) => {
  const [selectedOrg, setSelectedOrg] = useState<any | null>(null)
  const { access } = useAuth()
  // need to know if viewing vs creating, and use the correct route;
  // we need this to restrict which orgs the user can select when creating.
  // further, this organization selector should only ever be used in w/
  // a referral/ref request, such that a member is always in context (thus,
  // having an employerID via the member)
  const { data, loading } = useApi(
    readOnly
      ? { route: '/referral_organization' }
      : {
          route: '/referral_organization_editor',
          filter: {
            EmployerID: form.data?.EmployerMember?.EmployerID,
          },
        }
  )

  useEffect(() => {
    if (!data || !form) return
    if (!form.data.OrganizationID) setSelectedOrg(null)
    data.forEach((org: any) => {
      if (org.OrganizationID === form.data.OrganizationID) setSelectedOrg(org)
    })
  }, [data, form])

  useEffect(() => {
    if (!selectedOrg) return
    displayHelperText()
  }, [selectedOrg])

  if (loading || !data) {
    return <Skeleton />
  }

  const options = data
    .filter((org: any) => org.OrganizationID !== QUEST_ID)
    .map((org: any) => ({
      name: org.OrganizationName,
      value: org.OrganizationID,
      inNetwork: org.InNetwork,
    }))
    .sort((a: any, b: any) => {
      if (!!a.inNetwork && !b.inNetwork) return -1
      if (!a.inNetwork && !!b.inNetwork) return 1
      return a.name.localeCompare(b.name)
    })

  const displayHelperText = () => {
    if (!access.IsZeroUser) {
      return null
    }

    const { LoaOnly, PHANotes } = selectedOrg
    if (!LoaOnly && !PHANotes) return null

    let text = ''
    if (LoaOnly && !PHANotes) text = 'LOA Only'
    if (!LoaOnly && PHANotes) text = PHANotes
    if (LoaOnly && PHANotes) text = `LOA Only - ${PHANotes}`

    withSelectedOrgNotes?.(text)
  }

  if (readOnly) {
    return (
      <TextField
        disabled
        label="Organization"
        value={selectedOrg ? selectedOrg.OrganizationName : ''}
        onChange={() => {}}
      />
    )
  }

  const PassthroughProps = {} as any
  if (useV2Style) {
    PassthroughProps.renderInput = (params: any, label: any, error: any) => {
      return (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          margin="none"
          label="Organization"
          error={!!error}
          helperText={error}
          InputLabelProps={{ shrink: true }}
        />
      )
    }
  }

  return (
    <>
      <Autocomplete
        label="Organization"
        value={form.data.OrganizationID}
        onChange={form.setters.OrganizationID}
        options={options}
        error={showErrors ? form.errors.OrganizationID : null}
        PassthroughProps={{
          ...PassthroughProps,
          groupBy: (opt: any) => {
            return opt.inNetwork ? 'In Network' : 'Out Of Network'
          },
          getOptionDisabled: (opt: any) => {
            return !opt.inNetwork
          },
        }}
      />
    </>
  )
}

export default SelectOrganization
