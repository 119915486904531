import React, { useEffect, useId, useState } from 'react'
import { Button, Typography, Chip } from '@material-ui/core'
import DesignSuite24 from '../DesignSuite24'
import PriceList, { ShapePriceListItem, RenderPriceList } from '../PriceList'
import dateTime from '../../utils/dateTime'
import TextField from '../../components/TextField'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import styled from 'styled-components'

const StyledAttachBundles = styled.div`
  .existing-bundles {
    max-width: 380;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .not-ready-msg {
    color: #999;
    padding-top: 0.5rem;
  }
`

const StyledDialog1Content = styled.div`
  .selected-bundles {
    // background: #f1f1f1;
    // position: sticky;
    // top: 0;
    // z-index: 2;
    // padding: 1rem;
    // max-height: 400px;
    // overflow-y: scroll;
  }
`

const StyledDialog2Content = styled.div`
  .available-bundles {
    padding: 0.5rem 1rem 1rem;
    position: sticky;
    top: 0;
    z-index: 2;
    background: rgba(233, 233, 233, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 8px;
  }
`

interface props {
  entityName: string
  isReady?: boolean
  readOnly?: boolean
  title?: string | null
  apiGetPrices(): Promise<{
    Data: ShapePriceListItem[] | null
    error?: boolean
  }>
  apiSavePrices?(payload: { priceIDs: number[] }): Promise<{ error?: boolean }>
  priceListFilters?: any
}

export default function AttachedBundles({
  entityName,
  isReady = false,
  readOnly = false,
  title = 'Manage Attached Bundles',
  apiGetPrices,
  apiSavePrices,
  priceListFilters: passedPriceListFilters,
}: props): React.ReactElement | null {
  const [isOpenDialog1, setIsOpenDialog1] = useState(false)
  const [isOpenDialog2, setIsOpenDialog2] = useState(false)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [priceListProps, setPriceListProps] = useState<any>(null)
  const [existing, setExisting] = useState<Array<ShapePriceListItem>>([])
  const [selected, setSelected] = useState<Array<ShapePriceListItem>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbarDuration } = useSnackbar()

  useEffect(() => {
    if (!isReady) return
    loadExistingPrices()
  }, [isReady])

  useEffect(() => {
    if (!isOpenDialog1) {
      setSearchFilter('')
      setPriceListProps(null)
      setSelected([])
      return
    }
    setSelected(existing || [])
  }, [isOpenDialog1, existing])

  useEffect(() => {
    if (!isReady || !isOpenDialog1) return
    let serviceDate = dateTime.parse(passedPriceListFilters?.serviceDate)
    if (!serviceDate.isValid()) {
      serviceDate = dateTime.now()
    }
    setPriceListProps({
      ...(passedPriceListFilters || {}),
      serviceDate: serviceDate.format(dateTime.formats.ISODate),
    })
  }, [isReady, isOpenDialog1, passedPriceListFilters])

  function loadExistingPrices() {
    return apiGetPrices()
      .then((res) => {
        if (res?.error) throw res
        setExisting(res.Data || [])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading existing attached bundles',
        })
      )
  }

  function doSave() {
    if (!apiSavePrices) {
      showSnackbarDuration(
        'Saving attached bundles is not supported here',
        SnackbarTypeError,
        6000
      )
      return
    }

    apiSavePrices({
      priceIDs: selected.map((item) => item.FeeSchedulePriceID),
    })
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbarDuration(
          'Attached bundles saved OK',
          SnackbarTypeSuccess,
          6000
        )
      })
      .then(() => {
        return loadExistingPrices()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to save attached bundles',
        })
      )
      .finally(() => {
        setIsOpenDialog2(false)
        setIsOpenDialog1(false)
      })
  }

  return (
    <StyledAttachBundles>
      {!!title && (
        <Typography variant="h5" style={{ marginBottom: '0.5rem' }}>
          <span className="header-snazzy">{title}</span>
        </Typography>
      )}
      {existing.length > 0 && (
        <div className="existing-bundles">
          {existing.map((item: ShapePriceListItem) => (
            <Chip
              key={item.FeeSchedulePriceID}
              label={item.CostKeyCode}
              color="primary"
              variant="outlined"
            />
          ))}
        </div>
      )}
      {!readOnly && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpenDialog1(true)}
          disabled={!isReady}>
          Manage Attached Bundles
        </Button>
      )}
      {!isReady && !readOnly && (
        <Typography className="not-ready-msg" variant="body2">
          The {entityName} must be saved with a selected practice facility prior
          to attaching bundles.
        </Typography>
      )}

      <DesignSuite24.Dialog
        title="Manage Attached Bundles"
        open={isOpenDialog1}
        onClose={() => setIsOpenDialog1(false)}
        actionLeft={
          <Button
            size="small"
            variant="outlined"
            onClick={() => setIsOpenDialog1(false)}>
            Close
          </Button>
        }
        actionRight={
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={doSave}>
            Save
          </Button>
        }>
        {isOpenDialog1 && (
          <StyledDialog1Content>
            {selected.length > 0 ? (
              <div className="selected-bundles">
                <RenderPriceList
                  items={selected}
                  onSelectButtonLabel={<DesignSuite24.CommonIcons.IconDelete />}
                  onSelect={(sel: ShapePriceListItem) => {
                    setSelected((curr: Array<ShapePriceListItem>) => {
                      return curr.filter(
                        (item) =>
                          item.FeeSchedulePriceID !== sel.FeeSchedulePriceID
                      )
                    })
                  }}
                />
              </div>
            ) : (
              <div className="selected-bundles" style={{ textAlign: 'center' }}>
                <p>No bundles currently selected</p>
              </div>
            )}

            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => setIsOpenDialog2(true)}
                size="large"
                variant="contained"
                color="primary">
                Add Bundles
              </Button>
            </div>
          </StyledDialog1Content>
        )}
      </DesignSuite24.Dialog>

      <DesignSuite24.Dialog
        title="Search Available Bundles"
        open={isOpenDialog2}
        onClose={() => setIsOpenDialog2(false)}>
        {isOpenDialog2 && (
          <StyledDialog2Content>
            <div className="available-bundles">
              <Typography variant="h6">
                <span className="header-snazzy">Available Bundles</span>
              </Typography>
              <TextField
                value={searchFilter}
                onChange={(v: string) => setSearchFilter(v)}
                placeholder="Type to search by CPT code..."
              />
            </div>
            <PriceList
              {...priceListProps}
              filterCPT={searchFilter}
              onSelect={(sel: ShapePriceListItem) => {
                setSelected((curr: Array<ShapePriceListItem>) => {
                  return [...curr, sel]
                })
                setIsOpenDialog2(false)
              }}
            />
          </StyledDialog2Content>
        )}
      </DesignSuite24.Dialog>
    </StyledAttachBundles>
  )
}
