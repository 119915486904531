import React, { useEffect, useState } from 'react'
import AutocompleteField from '../AutocompleteField'
import { Chip } from '@material-ui/core'
import ReportDisplay from './reportDisplay'
import * as reportActions from '../../actions/ReportActions'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { useSetFrameHeaderMid } from '../AppFrame'

export default function OrganizationReports() {
  const [autoCompleteOrgList, setAutoCompleteOrgList] = useState([])
  const [selectedOrgID, setSelectedOrgID] = useState<number | null>(null)
  const [selectedReportHandle, setSelectedReportHandle] = useState<
    string | null
  >(null)
  const [reportList, setReportList] = useState([])
  const [reportURL, setReportURL] = useState<string | null>(null)
  const { show: showSnackbar } = useSnackbar()

  useSetFrameHeaderMid(() => {
    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: '0.5rem',
        }}>
        <AutocompleteField
          label="Organization"
          value={selectedOrgID}
          onChange={(orgID: any) => {
            setSelectedReportHandle(null)
            setSelectedOrgID(orgID || null)
          }}
          options={autoCompleteOrgList}
          PassthroughProps={{
            defaultValue: null,
            getOptionSelected: (v: any): boolean => +v.value === selectedOrgID,
          }}
          style={{ width: 320 }}
        />
        {reportList.map((r: any) => (
          <div key={r.Handle}>
            <Chip
              label={r.Name}
              color={r.Handle === selectedReportHandle ? 'primary' : 'default'}
              onClick={() => setSelectedReportHandle(r.Handle)}
            />
          </div>
        ))}
      </div>
    )
  }, [selectedOrgID, autoCompleteOrgList, reportList, selectedReportHandle])

  useEffect(() => {
    setSelectedReportHandle(null)
    setReportList([])
    setReportURL(null)
    loadOrgList()
  }, [])

  useEffect(() => {
    if (!selectedOrgID) return
    setSelectedReportHandle(null)
    setReportList([])
    setReportURL(null)
    loadReportsByOrgID(selectedOrgID)
  }, [selectedOrgID])

  useEffect(() => {
    if (!selectedOrgID || !selectedReportHandle) {
      setReportURL(null)
      return
    }
    loadReportData(selectedOrgID, selectedReportHandle)
  }, [selectedOrgID, selectedReportHandle])

  function loadOrgList() {
    return reportActions
      .getReportableOrganizations()
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setAutoCompleteOrgList(
          res.Data.map((org: any) => ({
            name: org.OrganizationName,
            value: org.OrganizationID,
          }))
        )
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar('Failed loading list of organizations', SnackbarTypeError)
      })
  }

  function loadReportsByOrgID(orgID: number) {
    return reportActions
      .getReportsByOrgID(orgID)
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setReportList(res.Data)
        setSelectedReportHandle(res.Data[0].Handle)
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar(
          'Failed loading reports for selected organization',
          SnackbarTypeError
        )
      })
  }

  function loadReportData(orgID: number, reportHandle: string) {
    return reportActions
      .getOrganizationReport(orgID, reportHandle)
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setReportURL(res.Data.URL)
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar('Failed loading report', SnackbarTypeError)
      })
  }

  return <>{reportURL && <ReportDisplay reportURL={reportURL} />}</>
}
