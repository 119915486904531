import React from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import querystring from 'query-string'
import { useConfig } from '../providers/Config'
import { useAuth } from '../providers/Auth'
import useSnackbar from './useSnackbar'
import usePrevious from './usePrevious'
import { SortDirection } from '@material-ui/core'

export interface Request extends AxiosRequestConfig {
  route: string
  filter?: { [key: string]: any }
  sort?: [string, 'ASC' | 'DESC' | SortDirection | false]
  range?: [number, number]
}

interface RequestOptions {
  errorMessage: string
}

interface Response {
  Data: any
  Meta: {
    RequestID?: string
    Total?: number
    Range?: [number, number]
  }
}

interface ResponseError {
  message: string
}

export type ApiRes = ReturnType<typeof useApi>

const buildRequest = (
  req: Request,
  baseUrl: string,
  token: string
): AxiosRequestConfig => {
  const { route, headers, filter, sort, range, ...axiosReq } = req

  let request = {
    ...axiosReq,
    url: `${baseUrl}${route}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...req.headers,
    },
    params: {
      filter,
      sort,
      range,
    },
    paramsSerializer: (params: any) => {
      const stringValueParams: any = {}
      for (const key in params) {
        stringValueParams[key] = JSON.stringify(params[key])
      }
      return querystring.stringify(stringValueParams)
    },
  }

  return request
}

/*
 * useApi hook uses the same interface as axios and returns { loading, error, data }
 * - auth token is configured for you
 * - pass search params with a { params } prop on the request object
 * */
const useApi = (request?: Request, opts?: RequestOptions) => {
  const { show: showSnackbar } = useSnackbar()
  const prevReq = usePrevious(request)

  const { baseUrl }: any = useConfig()
  const { idToken } = useAuth()

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<ResponseError | null>(null)
  const [data, setData] = React.useState<Response['Data'] | null>(null)
  const [meta, setMeta] = React.useState<Response['Meta'] | null>(null)

  const makeRequest = async (req?: Request, append?: boolean) => {
    if (req) {
      setLoading(true)
      setError(null)
      axios(buildRequest(req, baseUrl, idToken))
        .then((res: AxiosResponse<Response>) => {
          if (res.data.Data) {
            if (append && Array.isArray(data)) {
              setData([...data, ...res.data.Data])
            } else {
              setData(res.data.Data)
            }
          }
          if (res.data.Meta) setMeta(res.data.Meta)
          setLoading(false)
        })
        .catch((err: any) => {
          setError(err)
          const errMsg =
            opts && opts.errorMessage ? opts.errorMessage : err.message
          showSnackbar(errMsg, 'error')
        })
    }
  }

  React.useEffect(() => {
    if (!prevReq && !!request) {
      makeRequest(request)
    }
  }, [request])

  // can use options.range for server side pagination
  const refetch = async (
    opts: Partial<Request> & { append?: boolean } = {}
  ) => {
    const { append, ...options } = opts
    if (request && options) {
      await makeRequest({ ...request, ...options }, append)
    } else {
      await makeRequest(request)
    }
  }

  // for client side pagination. fetches next range and appends to data
  const fetchNextRange = async (range: [number, number]) => {
    if (data.length < range[1] && request) {
      await makeRequest({ ...request, range }, true)
    }
  }

  return {
    loading,
    error,
    data,
    meta,
    refetch,
    fetchNextRange,
  }
}

export default useApi
