import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import {
  getReferralExistingInvoices,
  postReferralInvoice,
} from '../../actions/ReferralInvoiceActions'
import { Description } from '@material-ui/icons'
import dateTime from '../../utils/dateTime'

const styles = (theme: any) => {
  return {
    flexy: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      background: '#f1f1f1',
      borderRadius: '8px',
      marginBottom: '0.5rem',
    },
    padRight: {
      paddingRight: '0.5rem',
      lineHeight: '1.5',
    },
    textContent: {
      display: 'block',
      fontSize: '90%',
    },
  }
}

interface Props {
  record: any
  history: any
  classes: any
}

export default withStyles(styles)(function ({
  record,
  history,
  classes,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [existing, setExisting] = useState([])
  const referralID = record.ID

  const onClose = () => {
    setIsOpen(false)
  }

  const generateInvoice = (ev: any) => {
    ev.preventDefault()
    getReferralExistingInvoices(referralID).then((res: any) => {
      if (Array.isArray(res.Data) && res.Data.length > 0) {
        setExisting(res.Data)
        setIsOpen(true)
        return
      }
      handleDoGenerateInvoice()
    })
  }

  const handleDoGenerateInvoice = () => {
    setIsOpen(false)
    postReferralInvoice(referralID).then((res: any) => {
      if (res && res.Data && res.Data.ID) {
        history.push(`/referral_invoice/${res.Data.ID}`)
        return
      }
    })
  }

  const onViewExisting = (invID: number) => {
    return () => {
      history.push(`/referral_invoice/${invID}`)
    }
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        onClick={generateInvoice}
        variant="contained"
        startIcon={<Description />}>
        Generate Invoice
      </Button>
      <Dialog open={isOpen}>
        <DialogTitle>Generate New Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This referral already has {existing.length} existing invoices. If
            you intend to continue working on an invoice, use the 'View Invoice'
            button for the relevant invoice below. Otherwise, if you do intend
            to create a new invoice, use the 'Proceed With New Invoice' button
            at the bottom.
          </DialogContentText>
          {existing.map((ri: any) => {
            return (
              <div className={classes.flexy} key={ri.ID}>
                <div className={classes.padRight}>
                  <span className={classes.textContent}>
                    <strong>Last Updated:</strong>{' '}
                    {dateTime
                      .parse(ri.LastUpdatedAt)
                      .local()
                      .format(dateTime.formats.Calendar)}
                  </span>
                  <span className={classes.textContent}>
                    <strong>Invoice Date Of Service:</strong>{' '}
                    {dateTime.parse(ri.DateOfService).format()}
                  </span>
                  <span className={classes.textContent}>
                    <strong>Notes:</strong> {ri.Notes || ''}
                  </span>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={onViewExisting(ri.ID)}
                    style={{ whiteSpace: 'nowrap' }}>
                    View Invoice
                  </Button>
                </div>
              </div>
            )
          })}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDoGenerateInvoice} color="primary" autoFocus>
            Proceed With New Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})
