import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Refresh as IconRefresh } from '@material-ui/icons'
import { AgingActivitiesWarningBanner } from './WarningBanner'
import ActivityItem, { Activity } from './ActivityItem'
import SwitchField from '../SwitchField'

interface Props {
  activities: Activity[] | null
  title: string
  NoActivityItem: any
  onAck: Function
  refresh: () => void
  showBanner?: boolean
  allowAck?: boolean
  weekOld?: number
  monthOld?: number
  oldestFirstValue?: boolean
  oldestFirstSetter?: (value: boolean) => void
  briefSubheader?: boolean
  frameless?: boolean
}

const Refresher: React.FC<{ refresh: () => void }> = (props) => {
  return (
    <IconButton onClick={() => props.refresh()}>
      <IconRefresh />
    </IconButton>
  )
}

export default function ActivityList({
  allowAck,
  activities,
  weekOld = 0,
  monthOld = 0,
  onAck,
  refresh,
  title,
  NoActivityItem,
  showBanner,
  oldestFirstValue,
  oldestFirstSetter,
  briefSubheader,
  frameless = false,
}: Props) {
  const hasActivities =
    activities && activities.length !== undefined && activities.length > 0

  if (frameless) {
    return (
      <>
        {hasActivities ? (
          activities.map((activity, i) => {
            return (
              <ActivityItem
                key={activity.ID}
                activity={activity}
                allowAck={!!allowAck}
                onAck={onAck}
                briefSubheader={briefSubheader}
              />
            )
          })
        ) : (
          <NoActivityItem />
        )}
      </>
    )
  }

  return (
    <Card className="activity-list-card-root">
      <CardHeader
        className="activity-list-card-header"
        title={
          <div>
            <span className="header-snazzy">{title}</span>
            {oldestFirstSetter && (
              <span style={{ float: 'right' }}>
                <SwitchField
                  label="Oldest First"
                  value={!!oldestFirstValue}
                  onChange={oldestFirstSetter}
                />
              </span>
            )}
          </div>
        }
        action={<Refresher refresh={refresh} />}
      />
      <CardContent className="activity-list-card-content">
        {hasActivities && showBanner && (
          <AgingActivitiesWarningBanner weekOld={weekOld} monthOld={monthOld} />
        )}
        {hasActivities ? (
          activities.map((activity, i) => {
            return (
              <ActivityItem
                key={activity.ID}
                activity={activity}
                allowAck={!!allowAck}
                onAck={onAck}
                briefSubheader={briefSubheader}
              />
            )
          })
        ) : (
          <NoActivityItem />
        )}
      </CardContent>
    </Card>
  )
}
