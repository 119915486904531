import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import DesignSuite24 from './DesignSuite24'

interface props {
  value: string
  canToggleToView?: boolean
}

export default function Masker({
  value,
  canToggleToView = false,
}: props): React.ReactElement | null {
  const [isRevealed, setIsRevealed] = useState(false)
  const masked = '-'.repeat(`${value}`.length)
  const Icon = isRevealed
    ? DesignSuite24.CommonIcons.IconVisibilityOff
    : DesignSuite24.CommonIcons.IconVisibility

  return (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        columnGap: '0.35rem',
      }}>
      <span style={{ display: 'inline-block' }}>
        {isRevealed ? value : masked}
      </span>
      {canToggleToView && (
        <IconButton size="small" onClick={() => setIsRevealed((v) => !v)}>
          <Icon fontSize="inherit" />
        </IconButton>
      )}
    </span>
  )
}
