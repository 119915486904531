import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import { deepOrange, deepPurple, green, pink } from '@material-ui/core/colors'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles = () => {
  return {
    orangeAvatar: {
      color: '#fff',
      backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
      color: '#fff',
      backgroundColor: deepPurple[500],
    },
    pinkAvatar: {
      // margin: 10,
      color: '#fff',
      backgroundColor: pink[500],
    },
    greenAvatar: {
      // margin: 10,
      color: '#fff',
      backgroundColor: green[500],
    },
  }
}

const NUM_COLORS = 4

interface UserAvatarProps {
  classes: ClassNameMap<string>
  firstName: string
  lastName: string
  imgUrl?: string
}

export const UserAvatar = withStyles(styles)(({
  classes,
  firstName,
  lastName,
  imgUrl,
}: UserAvatarProps) => {
  const avatarClass = getUserAvatarColor(firstName, lastName, classes)

  if (!imgUrl && firstName && lastName) {
    return (
      <Avatar aria-label="Avatar" className={avatarClass}>
        {`${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`}
      </Avatar>
    )
  }
  return <Avatar aria-label="Avatar" src={imgUrl} />
})

function getUserAvatarColor(
  firstName: string,
  lastName: string,
  classes: ClassNameMap<string>
) {
  if (firstName && lastName) {
    const val = firstName.charCodeAt(0) + lastName.charCodeAt(0)

    if (val % NUM_COLORS === 0) {
      return classes.purpleAvatar
    }
    if (val % NUM_COLORS === 1) {
      return classes.orangeAvatar
    }
    if (val % NUM_COLORS === 2) {
      return classes.pinkAvatar
    }
    if (val % NUM_COLORS === 3) {
      return classes.greenAvatar
    }
  }
}
