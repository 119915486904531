/*
@todo: SD-2591|SD-2408: factor every single one of these components out of existence
*/
import React, { LegacyRef } from 'react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import moment from 'moment'
import { createNumberMask, emailMask } from 'text-mask-addons'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'

interface CustomMaskProps extends MaskedInputProps {
  inputRef: (x: HTMLElement | null) => LegacyRef<MaskedInput> | undefined

  [x: string]: any
}

const SSNMask = (props: CustomMaskProps) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="XXX-XX-XXXX"
    />
  )
}

const DateMask = ({ inputRef, ...other }: CustomMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[0-1]/, /\d/, '/', /\d/, /\d/, '/', /[0-2]/, /\d/, /\d/, /\d/]}
      placeholder="MM/DD/YYYY"
    />
  )
}

const PhoneNumberMask = ({ inputRef, ...other }: CustomMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder="(___)___-____"
    />
  )
}

const CurrencyMask = ({ inputRef, ...other }: CustomMaskProps) => {
  const numberMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    requireDecimal: true,
  })

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={numberMask}
    />
  )
}

const NPIMask = ({ inputRef, ...other }: CustomMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar=" "
    />
  )
}

const DiagnosisPointerMask = ({ inputRef, ...other }: CustomMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/A[A-L]*/]}
      placeholderChar=" "
    />
  )
}

const CptCodeMask = ({ inputRef, ...other }: CustomMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\w/, /\w/, /\w/, /\w/, /\w/]}
      placeholderChar=" "
      pipe={(conformedValue) => {
        return conformedValue.trim()
      }}
    />
  )
}

const EmailMask = ({ inputRef, ...other }: CustomMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={emailMask}
      placeholder="you@somedomain.com"
    />
  )
}

interface CustomMaskInputProps {
  name?: string
  label: string
  value: string
  onChange: any
  maskInput: any
  error?: boolean
  helperText?: string
  showHelper?: boolean
  className?: string
  disabled?: boolean
  useV2Style?: boolean

  [x: string]: any
}

const MaskInput = ({
  name,
  label,
  value,
  onChange,
  maskInput,
  error,
  helperText,
  showHelper,
  className,
  disabled,
  useV2Style = false,
  ...other
}: CustomMaskInputProps) => {
  const id = React.useId()

  if (useV2Style) {
    return (
      <FormControl
        className={className}
        error={error}
        variant="outlined"
        size="small"
        fullWidth>
        <InputLabel disabled={disabled} htmlFor={`component-${id}`}>
          {label}
        </InputLabel>
        <OutlinedInput
          {...other}
          id={`component-${id}`}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          inputComponent={maskInput}
        />
        {(error || showHelper) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

  return (
    <FormControl className={className} error={error}>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      <Input
        {...other}
        name={name}
        value={value}
        onChange={onChange}
        inputComponent={maskInput}
        disabled={disabled}
      />
      {(error || showHelper) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

interface CustomInputProps {
  name?: string
  label: string
  value: string
  onChange: any
  error?: boolean
  helperText?: string
  showHelper?: boolean
  className?: string
  disabled?: boolean

  [x: string]: any
}

export const PhoneInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={PhoneNumberMask} {...props} />
}

export const SSNInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={SSNMask} {...props} />
}

/**
 * Render date input in the format MM/DD/YYYY
 */
export const DateInput = (props: CustomInputProps) => {
  let dateToRender = props.value

  // If the value is a valid date then format it correctly
  if (moment(dateToRender).isValid()) {
    dateToRender = moment(props.value).format('MM/DD/YYYY')
  }

  return <MaskInput maskInput={DateMask} {...props} value={dateToRender} />
}

export const EmailInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={EmailMask} {...props} />
}

export const CptCodeInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={CptCodeMask} {...props} />
}

export const NPIInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={NPIMask} {...props} />
}

export const DiagnosisPointerInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={DiagnosisPointerMask} {...props} />
}

export const CurrencyInput = (props: CustomInputProps) => {
  return <MaskInput maskInput={CurrencyMask} {...props} />
}
