import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Typography } from '@material-ui/core'
import { WarningOutlined } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { ReferralForm } from './types'
import Autocomplete from '../AutocompleteField'
import TextField from '../TextField'
import { ReferralRequestForm } from '../ReferralRequestForm/types'
import {
  getOrgFacilities,
  GetOrgFacilitiesParams,
} from '../../actions/OrganizationActions'

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
  readOnly: boolean
  useV2Style?: boolean
}

const SelectPracticeFacility: React.FC<Props> = ({
  form,
  showErrors,
  readOnly,
  useV2Style = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [facilityList, setFacilityList] = useState<any[]>([])
  const [selectedFacilityInfo, setSelectedFacilityInfo] = useState<any>(null)

  useEffect(() => {
    const orgID = form?.data?.OrganizationID
    if (!orgID) return
    let params: GetOrgFacilitiesParams = {}
    if (form?.data?.EmployerMemberID) {
      params.CalcDistanceForEmployerMemberID = form.data.EmployerMemberID
    }
    setIsLoading(true)
    getOrgFacilities(orgID, params)
      .then((res: any) => {
        if (!res.Data) {
          setFacilityList([])
          return
        }
        // we filter out any facilities that are not active, unless it is the currently selected one,
        // in that case we show it in the options and disable it
        // @todo: SD-1336: I couldn't untangle when this component would ever receive the 'readOnly' prop;
        // unsure of the repurcussions but probably need to test this fairly well
        setFacilityList(
          res.Data.filter(
            (pf: any) =>
              !!pf.IsActive || pf.ID == form?.data?.PracticeFacilityID
          ).map((pf: any) => ({
            ...pf,
            value: pf.ID,
            name: pf.IsActive ? pf.Name : `${pf.Name} (INACTIVE)`,
            _disabled: !pf.IsActive,
          }))
        )
      })
      .catch((e: any) => {
        console.log('caught ', e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [form?.data?.OrganizationID])

  useEffect(() => {
    if (!facilityList || !form?.data?.PracticeFacilityID) return
    setSelectedFacilityInfo(
      facilityList.find(
        (item: any) => item.ID === form?.data?.PracticeFacilityID
      )
    )
  }, [facilityList, form?.data?.PracticeFacilityID])

  if (isLoading) {
    return <Skeleton />
  }

  if (!form?.data?.OrganizationID) {
    return (
      <TextField
        disabled
        label="Practice Facility"
        value="Please choose an organization"
        onChange={() => {}}
      />
    )
  }

  const PassthroughProps = {} as any
  if (useV2Style) {
    PassthroughProps.renderInput = (params: any, label: any, error: any) => {
      return (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          margin="none"
          label="Practice Facility"
          error={!!error}
          helperText={error}
          InputLabelProps={{ shrink: true }}
        />
      )
    }
  }

  return (
    <>
      <Autocomplete
        label="Practice Facility"
        value={form?.data?.PracticeFacilityID}
        onChange={(id: any) => {
          if (!id) {
            // @ts-ignore
            form.setters.PracticeFacilityID(null)
            setSelectedFacilityInfo(null)
            return
          }
          form.setters.PracticeFacilityID(id)
        }}
        options={facilityList}
        error={showErrors ? form.errors.PracticeFacilityID : null}
        readOnly={readOnly}
        PassthroughProps={PassthroughProps}
      />
      {!!selectedFacilityInfo && (
        <>
          <Typography
            component="div"
            style={{ marginTop: 10, fontSize: '90%' }}>
            {_.get(selectedFacilityInfo, 'Address.DisplayAddress', '')}{' '}
            {selectedFacilityInfo.Distance > 0 && (
              <Typography style={{ fontWeight: 'bold', fontSize: '90%' }}>
                ({parseInt(selectedFacilityInfo.Distance)} mi)
              </Typography>
            )}
          </Typography>
          {!selectedFacilityInfo.IsActive && (
            <Typography style={{ fontSize: '90%' }}>
              <WarningOutlined fontSize="small" /> Facility no longer available!
              Please choose another.
            </Typography>
          )}
        </>
      )}
    </>
  )
}

export default SelectPracticeFacility
