import React from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'
import useApi from '../../hooks/useApi'
import useQueryParams from '../../hooks/useQueryParams'

const SEARCH_FILTER = 'q'
const DOB_FILTER = 'DOB'
const ORG_FILTER = 'OrganizationIDs'
const STATUS_FILTER = 'ReferralInvoiceStatusIDs'
const EMPLOYER_FILTER = 'EmployerIDs'
const DEBOUNCE_MS = 300

export const useReferralInvoiceList = () => {
  const { params }: any = useQueryParams()

  return useApi({
    route: '/referral_invoice',
    filter: params.filter,
    sort: params.sort || ['UpdatedAt', 'DESC'],
    range: params.range || [1, 25],
  })
}

export const useReferralInvoiceFilters = (refetchReferralInvoices: any) => {
  const { params, setParams } = useQueryParams()
  const initialFilters = params.filter || {}

  const [searchText, setSearchTextState] = React.useState<string>(
    initialFilters[SEARCH_FILTER] || ''
  )
  const [searchDob, setSearchDobState] = React.useState<string>(
    initialFilters[DOB_FILTER] || ''
  )
  const [selectedOrg, setSelectedOrgState] = React.useState<number | undefined>(
    initialFilters[ORG_FILTER] || undefined
  )
  const [selectedStatus, setSelectedStatusState] = React.useState<number[]>(
    initialFilters[STATUS_FILTER] || []
  )
  const [selectedEmployer, setSelectedEmployerState] = React.useState<number[]>(
    initialFilters[EMPLOYER_FILTER] || []
  )

  const debouncedRefetch = React.useCallback(
    debounce(refetchReferralInvoices, DEBOUNCE_MS),
    []
  )

  const formattedFilter = { ...initialFilters }
  if (
    initialFilters[ORG_FILTER] &&
    !Array.isArray(initialFilters[ORG_FILTER])
  ) {
    formattedFilter[ORG_FILTER] = [initialFilters[ORG_FILTER]]
  }

  const setSearchText = (val: string) => {
    setSearchTextState(val)
    const isSearchTextValid = val && val.length >= 3
    let filter = {} as any
    if (isSearchTextValid) {
      filter = { ...formattedFilter, [SEARCH_FILTER]: val }
    } else {
      filter = { ...formattedFilter }
      delete filter[SEARCH_FILTER]
    }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSearchDob = (val: string) => {
    setSearchDobState(val)
    const isSearchDobValid = val && moment(val).isValid()
    let filter = {} as any
    if (isSearchDobValid) {
      filter = { ...formattedFilter, [DOB_FILTER]: val }
    } else {
      filter = { ...formattedFilter }
      delete filter[DOB_FILTER]
    }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedOrg = (orgId: number) => {
    let filter = {} as any
    if (orgId) {
      setSelectedOrgState(orgId)
      filter = { ...formattedFilter, [ORG_FILTER]: [orgId] }
    } else {
      setSelectedOrgState(undefined)
      filter = { ...formattedFilter }
      delete filter[ORG_FILTER]
    }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedStatus = (statuses: number[]) => {
    setSelectedStatusState(statuses)
    const filter = { ...formattedFilter, [STATUS_FILTER]: statuses }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedEmployer = (employer: number[]) => {
    setSelectedEmployerState(employer)
    const filter = { ...formattedFilter, [EMPLOYER_FILTER]: employer }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  return {
    searchText,
    setSearchText,
    searchDob,
    setSearchDob,
    selectedOrg,
    setSelectedOrg,
    selectedStatus,
    setSelectedStatus,
    selectedEmployer,
    setSelectedEmployer,
  }
}
