import React, { useLayoutEffect, useState } from 'react'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core'
import dateTime from '../../utils/dateTime'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import styled from 'styled-components'
import { Cancel } from '@material-ui/icons'

export interface SetterArgs {
  name: string
  value: any
}

export type Setter = ({ name, value }: SetterArgs) => void

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root:not(.MuiFormControl-fullWidth) {
    width: 185px;
  }

  &.MuiFormControl-marginDense {
    .MuiInputBase-input {
      padding-top: 11.5px;
      padding-bottom: 9.5px;
    }
  }

  // screw lastpass' little auto-complete icon
  [data-lastpass-icon-root] {
    display: none !important;
  }

  .MuiInputBase-root {
    padding-left: 0 !important;

    &.MuiInputBase-adornedEnd {
      padding-right: 0.25rem;
    }
  }

  .MuiInputBase-input {
    padding-left: 0;

    ::placeholder {
      font-family: monospace;
    }

    &[type='text'] {
      padding-left: 1rem;
      padding-right: 0;
    }

    &[type='date'] {
      padding-left: 0.75rem;
    }

    &.MuiInputBase-inputAdornedStart {
      padding-left: 2rem;
    }
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
`

export type props = {
  setter: Setter
  UseComponent?:
    | React.ComponentProps<typeof TextField>
    | React.Component<TextFieldProps>
    | any
} & Partial<any>

export default function ({
  value,
  setter,
  UseComponent = StyledTextField,
  ...spread
}: props): React.ReactElement {
  const [internal, setInternal] = useState(value)
  const [isInvalid, setIsInvalid] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const { showForDuration: showSnackbarDuration } = useSnackbar()
  const inputRef = React.useRef() as any

  spread.className = [spread?.className, 'managed-date-input'].join(' ')
  spread.margin = spread.margin || 'none'
  spread.size = spread.size || 'small'
  spread.variant = spread.variant = 'outlined'
  spread.fullWidth =
    typeof spread.fullWidth === 'boolean' ? spread.fullWidth : true
  spread.InputLabelProps = { ...spread.InputLabelProps, shrink: true }
  spread.InputProps = {
    ...spread.InputProps,
    endAdornment: spread.disabled ? null : (
      <>
        <InputAdornment position="end">
          <IconButton aria-label="Clear" size="small" onClick={doClear}>
            <Cancel fontSize="inherit" />
          </IconButton>
        </InputAdornment>
      </>
    ),
  }

  // This is ultra finnicky: type="date" inputs don't show a placeholder, but
  // always show (in dark text) 'mm/dd/YYYY'. When you click back, if it swaps
  // back to a text input, it clears the input entirely, whereas it should just
  // auto-move the selected text to the given <day>|<month>|<year> section. This
  // fixes that and lets us alternate in a placeholder by changing the input
  // type to 'text' instead of date if it's empty. BUT - this CANNOT be done with
  // putting the type string in a useState value - it has to be done inline here.
  // Bottom line: if you're going to touch this, it needs very thorough manual testing.
  useLayoutEffect(() => {
    if (!inputRef?.current) return
    if (!internal && !value) {
      if (document.activeElement === inputRef.current) {
        return
      }
      inputRef.current.type = 'text'
    } else {
      inputRef.current.type = 'date'
    }

    // With the state value for the input hijacked and managed internally, it makes
    // it difficult to manage *outside state changes* (if a parent component wants to
    // reset the value to null, for example). This code allows for that.
    if (document.activeElement !== inputRef.current && !value && !!internal) {
      setIsInvalid(false)
      setInternal(null)
    }
  }, [inputRef?.current, internal, value])

  useLayoutEffect(() => {
    if (isDirty) return
    setInternal(dateTime.parse(value).format(dateTime.formats.ISODate))
  }, [value, isDirty])

  useLayoutEffect(() => {
    if (!isDirty) return
    if (value === internal) return
    if (isInvalid) {
      setter && setter({ name: spread.name, value: '_invalid_' })
      return
    }
    setter && setter({ name: spread.name, value: internal })
  }, [isDirty, isInvalid, value, internal])

  function handleInput(input: string) {
    setIsDirty(true)
    if ((input || '').length >= 1) {
      const dt = dateTime.parse(input)
      const y = dt.year()
      const v = dt.isValid() && y > 1890 && y < 2050
      setIsInvalid(!v)
      setInternal(input)
      return
    }
    setIsInvalid(false)
    setInternal(null)
  }

  function doClear() {
    handleInput('')
  }

  function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    handleInput(ev?.target.value || '')
  }

  function onPaste(ev: any) {
    if (!ev?.clipboardData) {
      return console.warn(
        'paste detected into ManagedDateInput but cannot handle event'
      )
    }
    const text = ev?.clipboardData?.getData('text')
    const dt = dateTime.parse(text)
    if (!dt.isValid()) {
      showSnackbarDuration(
        `Invalid value pasted to date field ${spread?.label} (received:'${text}'). Must be format: YYYY-MM-DD, MM/DD/YYYY, or M/D/YYYY`,
        SnackbarTypeError,
        5500
      )
      return
    }
    handleInput(dt.format(dateTime.formats.ISODate, ''))
  }

  return (
    <UseComponent
      {...spread}
      inputRef={inputRef}
      type="date"
      value={internal || ''}
      onChange={onChange}
      onFocus={(e: any) => (e.target.type = 'date')}
      onBlur={(e: any) => {
        if (!value && !internal) {
          e.target.type = 'text'
        }
      }}
      onPaste={onPaste}
      error={isInvalid || spread.error}
      placeholder="--/--/----"
      InputLabelProps={{ ...spread?.InputLabelProps, shrink: true }}
    />
  )
}
