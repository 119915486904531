import * as api from '../services/ApiService'
import { ShapePlanExclusion } from '../components/Exclusions/types'

interface GetEmployerExclusionsResponse {
  error?: boolean
  Data: {
    DeductibleStartDay: number
    DeductibleStartMonth: number
    PlanExclusions: ShapePlanExclusion[]
  }
}

export function getEmployerExclusions({
  employerID,
}: {
  employerID: number
}): Promise<GetEmployerExclusionsResponse> {
  return api.get(`/employer/${employerID}/plan_exclusions`)
}
