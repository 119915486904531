import React, { KeyboardEvent, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
// @ts-ignore
import _ from 'lodash'

interface ICDCode {
  Code: string
  Descr: string
}

const REASON_REMOVED = 'remove-option'
const REASON_SELECTED = 'select-option'

export const ICD10CodeAutoComplete = (props: any) => {
  const [input, setInput] = useState('')
  const [curValues, setCurValues] = useState(props.initialValues)
  const [lastRequestID, setLastRequestID] = useState(0)
  const [loading, setLoading] = useState(false)
  const [fetchErr, setFetchErr] = useState(null)
  const [suggestions, setSuggestions] = useState<any>([])

  const handleChange = (e: any, newVals: ICDCode[], reason: string) => {
    const dif = _.xor(curValues, newVals)
    setSuggestions([])

    if (
      reason === REASON_SELECTED &&
      curValues.some((icd: ICDCode) => icd.Code === dif[0].Code)
    ) {
      return
    }

    switch (reason) {
      case REASON_SELECTED:
        props.onSelect(dif[0])
        break
      case REASON_REMOVED:
        props.onRemove(dif[0])
        break
      default:
        break
    }

    setCurValues(newVals)
  }

  const handleInputChange = (e: any, value: string) => {
    const inputValue = value.trim().toLowerCase()
    setInput(inputValue)
    const { threshold } = props

    if (lastRequestID !== 0) {
      clearTimeout(lastRequestID)
    }

    if (threshold && threshold > inputValue.length) {
      return
    }
    setLoading(true)
    const newRequestID = props
      .fetch(inputValue)
      .then((res: any) => {
        const { Data } = res
        if (Data && Data.length > 0) {
          setFetchErr(null)
          setSuggestions(Data)
        }
      })
      .catch((err: any) => {
        console.error('Unable to fetch suggestions: ', err)
        setFetchErr(err)
      })
      .finally(() => {
        setLoading(false)
      })

    setLastRequestID(newRequestID)
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.code === 'Tab') {
      if (suggestions.length > 0) {
        setInput('')
        handleChange(null, [...curValues, suggestions[0]], REASON_SELECTED)
      }
      e.preventDefault()
    }
  }

  return (
    <Autocomplete
      value={curValues || []}
      disableClearable={true}
      multiple
      options={suggestions}
      getOptionLabel={props.labelGetter}
      onChange={handleChange}
      inputValue={input || ''}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      defaultValue={props.initialValues}
      filterSelectedOptions
      loading={loading}
      disabled={props.readOnly}
      style={{ minWidth: 'max(360px, 50%)' }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant={props.variant || 'outlined'}
          label={props.label}
          placeholder={props.placeholder}
          size="small"
        />
      )}
    />
  )
}
