import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Typography, Button } from '@material-ui/core'
import ReferralDetails from './ReferralDetails'
import InvoiceDetails from './InvoiceDetails'
import InvoiceLineTable2 from './InvoiceLineTable2'
import { WorkflowActions } from './WorkflowActions'
import { getReferralInvoice } from '../../actions/ReferralInvoiceActions'
import { isEditableStatus, validateDateOfService } from './utils'
import useForm from '../../hooks/useForm'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import {
  acceptReferralInvoice,
  submitReferralInvoice,
  updateReferralInvoice,
} from '../../services/ReferralInvoiceService'
import { useSetFrameHeaderLeft, useSetFrameHeaderMid } from '../AppFrame'
import InvoiceFileUpload from './ReferralInvoiceFileUpload'
import TextField from '../TextField'
import {
  STATUS_ACCEPTED,
  STATUS_DRAFT,
  STATUS_IGNORED,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from './statuses'
import styled from 'styled-components'
import InvoiceICDCodeSelector from './ReferralInvoiceICDCodeSelector'
import AddEditLine from './InvoiceLineTable2/addEditLine'
import { Link } from 'react-router-dom'
import DesignSuite24 from '../DesignSuite24'

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 16px 24px;
`

const typographyPropsSubHeader = {
  variant: 'body1',
  color: 'textSecondary',
  style: { paddingBottom: '10px' },
}

export default function ReferralInvoiceForm() {
  const params: any = useParams()
  const hist = useHistory()
  const loc: any = useLocation()
  const { show: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [referralInvoice, setReferralInvoice] = useState<any>(null)
  const [attemptedSave, setAttemptedSave] = useState(false)
  const refLinesTable = useRef<any>(null)

  const form = useForm(
    {
      ID: +params.id,
      DateOfService: null,
      BillingEntity2ID: null,
      PatientAccountNumber: '',
      Notes: '',
      OrganizationNPI: '',
      PracticeFacilityNPI: '',
    },
    {
      DateOfService: {
        msg: "Date of Service can't be in the future or left blank. Must be current date or past date.",
        isValid: validateDateOfService,
      },
      // BillingEntity2ID: {
      //   msg: 'test Billing Entity is required.',
      //   isValid: (val: any, x: any) => {
      //     // @ts-ignore
      //     console.log('received', val, data, x)
      //     return false
      //   },
      // },
    }
  )

  useSetFrameHeaderLeft(() => (
    <Typography variant="h5">Referral Invoice</Typography>
  ))

  useSetFrameHeaderMid(() => {
    if (!referralInvoice) return null
    return (
      <Typography
        variant="h6"
        style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span style={{ fontSize: '84%', color: '#333' }}>Invoice Number:</span>
        &nbsp;
        <span style={{ fontFamily: 'monospace' }}>{referralInvoice.ID}</span>
      </Typography>
    )
  }, [referralInvoice])

  useEffect(() => {
    loadInvoice()
  }, [])

  function loadInvoice() {
    getReferralInvoice({ id: params.id })
      .then((res: any) => {
        if (res.error) throw res
        setReferralInvoice(res.Data)
        form.setData({
          ...form.data,
          DateOfService: res.Data?.DateOfService,
          BillingEntity2ID: res.Data?.BillingEntity2ID,
          Notes: res.Data?.Notes,
          PatientAccountNumber: res.Data?.PatientAccountNumber,
          OrganizationNPI: res.Data?.OrganizationNPI,
          PracticeFacilityNPI: res.Data?.PracticeFacilityNPI,
        })
      })
      .catch(
        catchAPIError({ defaultMessage: 'Error loading referral invoice' })
      )
  }

  const onSave = async (statusId: number, successMessage: string) => {
    setAttemptedSave(statusId !== STATUS_DRAFT)
    try {
      if (statusId === STATUS_DRAFT || form.isValid()) {
        let refInvoice = {
          ...form.data,
          ReferralInvoiceStatusID: statusId,
        }

        switch (statusId) {
          case STATUS_SUBMITTED:
            await submitReferralInvoice(params.id, refInvoice)
            break
          case STATUS_ACCEPTED:
            await acceptReferralInvoice(params.id, refInvoice)
            break
          default:
            await updateReferralInvoice(params.id, refInvoice)
        }

        showSnackbar(successMessage, SnackbarTypeSuccess)

        hist.push(
          `/referral_invoices${loc.state ? loc.state.prevQueryString : ''}`
        )
      } else {
        showSnackbar(`Please correct form errors.`, SnackbarTypeError)
      }
    } catch (e: any) {
      console.warn('Error saving invoice', e)
      if (Array.isArray(e.Error) && e.Error.length > 0) {
        showSnackbar(
          `Error saving invoice; ${e.Error.join(', ')}`,
          SnackbarTypeError
        )
        return
      }
      showSnackbar(`Error saving invoice.`, SnackbarTypeError)
    }
  }

  if (!referralInvoice) return null

  // Disable editing if there are no actions to take on this invoice
  const disableEditing =
    referralInvoice.NextStatuses && referralInvoice.NextStatuses.length === 0
  const editableStatus = isEditableStatus(
    referralInvoice.ReferralInvoiceStatusID
  )

  const referral = {
    ID: referralInvoice.ReferralID,
    ...referralInvoice.Referral,
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ maxWidth: 410 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0.5rem',
          }}>
          <Typography variant="h5" style={{ marginRight: '1rem' }}>
            <span className="header-snazzy">Referral Details</span>
          </Typography>
          <div>
            <DesignSuite24.Tooltip title="Open the original referral in a new tab.">
              <Button
                to={`/referral/${referralInvoice.ReferralID}`}
                target="_blank"
                component={Link}
                size="small"
                variant="outlined"
                startIcon={<DesignSuite24.CommonIcons.IconVisibility />}>
                View Referral
              </Button>
            </DesignSuite24.Tooltip>
          </div>
        </div>
        <ReferralDetails referral={referral} />
      </div>
      <div
        style={{
          flex: 1,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          marginLeft: '1.5rem',
          borderLeft: '2px solid #ddd',
        }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              paddingRight: '1.5rem',
              borderRight: '2px solid #e1e1e1',
            }}>
            <Typography variant="h5">
              <span className="header-snazzy">Invoice Details</span>
              &nbsp;
              <small>
                (#
                {referralInvoice.ID})
              </small>
            </Typography>
            <Typography {...(typographyPropsSubHeader as any)}>
              {referralInvoice.ReferralInvoiceStatusID === STATUS_DRAFT ? (
                <>
                  <em>This invoice is a draft.</em>
                  &nbsp;
                  <span>
                    Fill out the details below then press submit to send it to
                    ZERO for review.
                  </span>
                </>
              ) : null}
              {referralInvoice.ReferralInvoiceStatusID === STATUS_SUBMITTED ? (
                <em>This invoice has been submitted and cannot be edited.</em>
              ) : null}
              {referralInvoice.ReferralInvoiceStatusID === STATUS_IGNORED ? (
                <>
                  <em>This invoice has been ignored.</em>
                  &nbsp;
                  <span>
                    If you need to edit it, click Save In Draft Mode below.
                  </span>
                </>
              ) : null}
              {referralInvoice.ReferralInvoiceStatusID === STATUS_REJECTED ? (
                <>
                  <em>This invoice has been rejected by ZERO.</em>
                  &nbsp;
                  <span>Please review notes, make edits and re-submit.</span>
                </>
              ) : null}
              {referralInvoice.ReferralInvoiceStatusID === STATUS_ACCEPTED ? (
                <strong>
                  This invoice has been accepted by ZERO and can no longer be
                  edited.
                </strong>
              ) : null}
            </Typography>

            <hr style={{ margin: '0 0 1.5rem', background: '#ddd' }} />

            <InvoiceDetails
              referralInvoice={referralInvoice}
              form={form}
              editable={editableStatus}
              showErrors={attemptedSave}
            />
          </div>
          <div style={{ width: '45%', paddingLeft: '1.5rem' }}>
            {/* <NotesSection form={form} disabled={disableEditing} /> */}
            <Typography variant="h5">
              <span className="header-snazzy">Invoice Notes</span>
            </Typography>
            <Typography {...(typographyPropsSubHeader as any)}>
              For details and communication about the invoice
            </Typography>
            <TextField
              label="Notes"
              value={form.data.Notes}
              onChange={form.setters.Notes}
              disabled={disableEditing}
              multiline
              minRows={4}
            />

            <hr />
            <InvoiceFileUpload
              id={referralInvoice.ID}
              files={referralInvoice.Files}
            />
          </div>
        </div>

        <hr />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              columnGap: '0.5rem',
            }}>
            <Typography variant="h5">
              <span className="header-snazzy">Invoice Lines</span>
            </Typography>
            <AddEditLine
              referralInvoice={referralInvoice}
              isEditable={editableStatus}
              defaultDateOfService={form.data.DateOfService}
              onSave={() => {
                refLinesTable?.current?.refresh?.()
              }}
            />
          </div>

          <InvoiceICDCodeSelector
            id={referralInvoice.ID}
            codes={referralInvoice.ICD10Codes}
            readOnly={false}
            // readOnly={!editable}
          />
        </div>

        <InvoiceLineTable2
          ref={refLinesTable}
          referralInvoiceID={referralInvoice.ID}
          isEditable={editableStatus}
        />

        <hr />

        <Toolbar>
          <WorkflowActions referralInvoice={referralInvoice} onSave={onSave} />
        </Toolbar>
      </div>
    </div>
  )
}
