import React from 'react'
import MaskedInput from 'react-text-mask'
import { TextField, StandardTextFieldProps } from '@material-ui/core'

interface Props extends Omit<StandardTextFieldProps, 'onChange' | 'error'> {
  onChange(val: string): void
  error?: string | null | undefined
}

const mask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export default function PhoneField(props: Props) {
  const { InputProps = {}, ...rest } = props
  return (
    <TextField
      {...rest}
      variant="outlined"
      size="small"
      fullWidth
      onChange={(evt: any) => props.onChange(evt.target.value)}
      error={!!props.error}
      helperText={props.error || undefined}
      InputProps={{
        ...InputProps,
        inputComponent: PhoneNumberMask as any,
      }}
    />
  )
}

function PhoneNumberMask(props: any) {
  return <MaskedInput {...props} mask={mask} placeholder="(___) ___-____" />
}

export function FormatPhoneNumber(phone: string | null) {
  if (!phone) return ''
  const cleaned = ('' + phone).replace(/\D/g, '')
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`
}

// import styled from 'styled-components'
// import MaskedInput from 'react-text-mask'
// import {
//   FormControl,
//   FormHelperText,
//   InputLabel,
//   Input,
//   OutlinedInput,
//   InputBaseComponentProps,
// } from '@material-ui/core'
// // import ReferenceTextField from './ReferenceTextField'

// interface Props {
//   label: string | null
//   value: string
//   onChange: (val: string) => void
//   error?: string | null
//   // readOnly?: boolean
//   disabled?: boolean
//   useV2Style?: boolean
//   inputProps?: InputBaseComponentProps
// }

// interface MaskProps {
//   value: string
//   onChange: (e: any) => void
//   inputRef: (ref: any) => void
// }

// const PhoneFieldContainer = styled.div`
//   .MuiInputBase-input.Mui-disabled {
//     color: initial;
//   }

//   .MuiInput-underline.Mui-disabled:before {
//     border: none;
//   }
// `

// const PhoneNumberMask: React.FC<MaskProps> = (props: MaskProps) => {
//   const { inputRef, ...other } = props

//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref: any) => {
//         inputRef(ref ? ref.inputElement : null)
//       }}
//       mask={[
//         '(',
//         /[1-9]/,
//         /\d/,
//         /\d/,
//         ')',
//         ' ',
//         /\d/,
//         /\d/,
//         /\d/,
//         '-',
//         /\d/,
//         /\d/,
//         /\d/,
//         /\d/,
//       ]}
//       placeholder="(___) ___-____"
//     />
//   )
// }

// const PhoneField: React.FC<Props> = (props: Props) => {
//   const id = React.useId()
//   const { label, value, onChange, error, readOnly } = props

//   // if (readOnly) {
//   //   return <ReferenceTextField label={label || ''} value={value} />
//   // }

//   if (props.useV2Style) {
//     return (
//       <PhoneFieldContainer>
//         <FormControl fullWidth error={!!error} variant="outlined" size="small">
//           {!!label && (
//             <InputLabel htmlFor={`component-${id}`}>{label}</InputLabel>
//           )}
//           <OutlinedInput
//             id={`component-${id}`}
//             value={value}
//             label={label || undefined}
//             onChange={(e: any) => onChange(e.target.value)}
//             inputComponent={PhoneNumberMask as any}
//             disabled={readOnly}
//             inputProps={props.inputProps}
//           />
//           {error && <FormHelperText>{error}</FormHelperText>}
//         </FormControl>
//       </PhoneFieldContainer>
//     )
//   }

//   return (
//     <PhoneFieldContainer>
//       <FormControl fullWidth error={!!error}>
//         <InputLabel>{label}</InputLabel>
//         <Input
//           value={value}
//           onChange={(e: any) => onChange(e.target.value)}
//           inputComponent={PhoneNumberMask as any}
//           disabled={readOnly}
//         />
//         {error && <FormHelperText>{error}</FormHelperText>}
//       </FormControl>
//     </PhoneFieldContainer>
//   )
// }

// export default PhoneField
