import React from 'react'
import styled from 'styled-components'
import { TextField, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'

interface Props {
  value?: string | null
  onChange: (val: string) => void
  label: string
  error?: string | null
  style?: any
  useV2Style?: boolean
  // tooltip?
}

const StyledTextField: typeof TextField = styled(TextField)`
  flex: 1;
`

const SearchField: React.FC<Props> = ({
  value,
  onChange,
  label,
  error,
  style,
  useV2Style = false,
}) => {
  const spreadProps = {} as any
  if (useV2Style) {
    spreadProps.variant = 'outlined'
    spreadProps.size = 'small'
    spreadProps.fullWidth = true
    spreadProps.margin = 'none'
  }

  return (
    <StyledTextField
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
      label={label}
      placeholder="Search..."
      error={!!error}
      helperText={error}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      style={style}
      {...spreadProps}
    />
  )
}

export default SearchField
