import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { JssProvider } from 'react-jss'
import { AuthProvider } from './providers/Auth'
import { ConfigProvider } from './providers/Config'
import ZeroThemeProvider from './providers/Theme'
import AppFrame from './components/AppFrame'
import { NetworkDownContainer } from './components/NetworkDown'
import RenderRoutes from './Routes'
import requestMocksServiceWorker from './utils/networkRequestMocks'
import checkRelease from './CheckRelease'
import ReleaseAnnouncement from './components/ReleaseAnnouncements'
import './types'
import './styles/index.scss'

const browserHistory = createBrowserHistory()
const onRedirectCallback = (appState) => {
  browserHistory.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const redirect = `${window.location.origin}/login_callback`

if (process.env.NODE_ENV === 'development') {
  requestMocksServiceWorker.start()
}

try {
  createRoot(document.getElementById('root')).render(
    <ZeroThemeProvider>
      <ConfigProvider>
        <AuthProvider
          redirect_uri={redirect}
          onRedirectCallback={onRedirectCallback}>
          <JssProvider>
            <Router history={browserHistory}>
              <AppFrame>
                <RenderRoutes />
              </AppFrame>
              <NetworkDownContainer />
            </Router>
            <ReleaseAnnouncement />
          </JssProvider>
        </AuthProvider>
      </ConfigProvider>
    </ZeroThemeProvider>
  )
} catch (e) {
  console.warn('Failed rendering application; Error=', e)
  const r2 = document.createElement('div')
  document.body.append(r2)
  createRoot(r2).render(<RenderOutOfService />)
}

checkRelease()

function RenderOutOfService() {
  const srcLogo = require('./assets/tzc-logo.png')

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div style={{ display: 'inline-block', textAlign: 'center' }}>
        <img
          alt="logo"
          src={srcLogo}
          style={{
            display: 'block',
            height: 'auto',
            maxWidth: 200,
            margin: '0 auto 1.5rem',
          }}
        />
        <span style={{ lineHeight: 1.75 }}>
          Our apologies, GoZERO may be experiencing issues.
          <br /> If this persists, please{' '}
          <a href="https://zero.health/contact-us">Contact Us</a>.
          <br />
          <br />
          Please use Chrome or Firefox to view GoZERO.
        </span>
      </div>
    </div>
  )
}
