/**
 * Return true if we are on production
 * This is used for themeing purposes in most cases
 */
export const checkIfProduction = () => {
  const reactAppEnv = process.env.REACT_APP_ENV
  return reactAppEnv === 'prd'
}

export const parseS3Key = (s3Key) => {
  if (!s3Key) return ''
  const parts = s3Key.split('/')
  if (parts && parts.length > 0) return parts[parts.length - 1]
}

export const isValidCpt = (cpt) => {
  return cpt && typeof cpt === 'string' && cpt.length === 5
}

export const errors = {
  IsForbidden: (err) => {
    return err && err.Error && err.Error.StatusCode === 403
  },
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function bytesToFriendly(bytes, decimals = 2) {
  if (!bytes) return '0 Bytes (or unknown)'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function makeRandomString(len = 8) {
  // Declare all characters
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  // Pick characers randomly
  let str = ''
  for (let i = 0; i < len; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return str
}

// Used by src/components/DataTable. This is dumb to have it live here, but
// its less hacky than it was before. @todo: ensure only tables that need to use
// this are doing so, and make the default argument to the DataTable a no-op.
export function defaultTableRowColorer(row) {
  if (row.IsUrgent) return '#ff9e7b'
  if (row.ArchivedAt) return '#ededed'
  return null
}
