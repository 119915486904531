import React, { useCallback, useEffect, useState } from 'react'
import TextField from '../TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DRGCodeActions } from '../../actions'
import debounce from 'lodash/debounce'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'

const { findDRGCode } = DRGCodeActions

interface DataItem {
  Code: string
  Descr: string
}

interface Props {
  selectedDRGCode: string | null
  onChange(drgCode: string | null, selectedObj: any): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
}

const DRGCodeSearcher = ({
  selectedDRGCode,
  onChange,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disabled = false,
  ...props
}: Props) => {
  const { show: showSnackbar } = useSnackbar()
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)

  const showGenericErr = useCallback(() => {
    showSnackbar(
      'Uh oh, something went awry searching for DRG Codes. Try again later.',
      SnackbarTypeError
    )
  }, [])

  useEffect(() => {
    if (!selectedDRGCode) {
      if (!!active) {
        setActive(null)
      }
      return
    }
    if (active && active.Code === selectedDRGCode) {
      return
    }
    findDRGCode({ filter: { code: selectedDRGCode } })
      .then((r: any) => {
        if (r.error) {
          return showGenericErr()
        }
        setActive({ Code: r.Data.Code, Descr: r.Data.Descr })
      })
      .catch(showGenericErr)
  }, [selectedDRGCode, active, showGenericErr])

  useEffect(() => {
    if (!query.length) {
      return
    }
    findDRGCode({ filter: { q: query } })
      .then((r: any) => {
        if (r.error) {
          return showGenericErr()
        }
        setData(r.Data)
      })
      .catch(showGenericErr)
  }, [query, showGenericErr])

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    setQuery(q)
  }, 250)

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.Code : null, b)
  }

  return (
    <Autocomplete
      options={data}
      getOptionLabel={(opt: DataItem) => `${opt.Code}`}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.Code === active.Code : false
      }}
      defaultValue={null}
      value={active}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      disabled={disabled}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="DRG Code"
          placeholder="Start typing to search..."
          variant="outlined"
          size="small"
          margin="none"
          helperText={active ? active.Descr : ''}
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}

export default DRGCodeSearcher
