import React from 'react'
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

type Option = { name?: string | any; value: any; disabled?: boolean }

interface Props {
  value?: any
  onChange: (val: any) => void
  error?: string | null
  label?: string
  options: Option[]
  multiple?: boolean
  disabled?: boolean
  // useV2Style?: boolean
}

const SelectField: React.FC<Props> = ({
  label,
  options,
  value,
  onChange,
  error,
  multiple,
  disabled,
  // useV2Style = false,
}) => {
  const id = React.useId()
  const renderMultiple = (selected: any) => {
    if (Array.isArray(selected)) {
      const optionMap = options.reduce((obj: any, option: Option) => {
        obj[option.value] = option.name
        return obj
      }, {})

      const names = selected.map((s: number) => optionMap[s])

      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {names.map((name: string) => (
            <Chip key={name} label={name} />
          ))}
        </div>
      )
    }

    return null
  }

  const formControlProps = {} as any
  const inputLabelProps = {} as any
  const selectProps = {} as any
  // if (useV2Style) {
  formControlProps.variant = 'outlined'
  formControlProps.size = 'small'
  formControlProps.margin = 'none'
  inputLabelProps.shrink = true
  selectProps.label = label
  selectProps.notched = true
  // }

  return (
    <FormControl style={{ flex: 1, width: '100%' }} {...formControlProps}>
      {label && (
        <InputLabel
          htmlFor={`component-${id}`}
          shrink={!!value}
          {...inputLabelProps}>
          {label}
        </InputLabel>
      )}
      <Select
        id={`component-${id}`}
        value={value}
        onChange={(evt: any) => {
          onChange(evt.target.value)
        }}
        renderValue={multiple ? renderMultiple : undefined}
        multiple={multiple}
        disabled={disabled}
        {...selectProps}>
        {options.map(({ name, value, disabled }: Option, idx: number) => {
          return (
            <MenuItem
              key={`${name}.${value || '_empty_'}.${idx}`}
              value={value}
              disabled={!!disabled}>
              {name}
            </MenuItem>
          )
        })}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
