import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
// import { FormGrid, MiddleGridCol, SectionSubheader } from './components'
import InvoiceICDCodeSelector from './ReferralInvoiceICDCodeSelector'
import InvoiceFileUpload from './ReferralInvoiceFileUpload'
import Select from '../SelectField'
import TextField from '../TextField'
import { NPIInput } from '../../components/Inputs'
import { ReferralInvoice } from './types'
import BillingEntityPicker from './BillingEntityPicker'
import ManagedDateInput from '../Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'

interface InvoiceDetailsProps {
  referralInvoice: ReferralInvoice
  form: any
  editable: boolean
  showErrors: boolean
}

export default function InvoiceDetails({
  referralInvoice,
  form,
  editable,
  showErrors,
}: InvoiceDetailsProps) {
  return (
    <>
      <div
        style={{ display: 'flex', columnGap: '1rem', marginBottom: '1.5rem' }}>
        <div style={{ width: 200 }}>
          <ManagedDateInput
            label="Date of Service"
            disabled={!editable}
            value={form.data.DateOfService}
            setter={({ name, value }) => form.setters.DateOfService(value)}
            error={showErrors && form.errors.DateOfService}
            fullWidth
          />
        </div>
        <div style={{ width: 250 }}>
          <TextField
            label="Patient Account Number"
            value={form.data.PatientAccountNumber}
            onChange={form.setters.PatientAccountNumber}
          />
        </div>
        <div style={{ width: 160 }}>
          <NPIInput
            useV2Style
            value={form.data.OrganizationNPI || ''}
            disabled={!editable}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              form.setters.OrganizationNPI(e.target.value)
            }
            label="Organization NPI"
          />
        </div>
        <div style={{ width: 150 }}>
          <Select
            // useV2Style
            label="Facility NPI"
            value={form.data.PracticeFacilityNPI}
            onChange={(npi: string) => form.setters.PracticeFacilityNPI(npi)}
            disabled={!editable}
            options={referralInvoice.PracticeFacilityNPIs.map(
              (npi: string) => ({
                name: npi,
                value: npi,
              })
            )}
          />
        </div>
      </div>

      <div>
        <BillingEntityPicker
          organizationID={referralInvoice.Referral.OrganizationID}
          selectedBillingEntityID={form.data.BillingEntity2ID}
          disabled={!editable}
          onChange={(be: any) => {
            if (!be) {
              form.setters.OrganizationNPI('')
              form.setters.BillingEntity2ID(null)
              return
            }
            // if NPI we have on file is different than whatever the current
            // value is for NPI, use the one we have on file
            if (form.data.OrganizationNPI != be.NPI) {
              form.setters.OrganizationNPI(be.NPI)
              // woof, timing hack here. Form setters are not syncronous and we can end up wiping out changes
              // if called in quick succession
              form.data.OrganizationNPI = be.NPI
            }
            form.setters.BillingEntity2ID(be.ID)
          }}
        />

        {!referralInvoice.LinkedBillingEntity2?.IsLatest && (
          <Typography
            variant="caption"
            color="textSecondary"
            style={{
              background: '#f0ca27',
              display: 'block',
              padding: '0.35rem',
              fontWeight: 600,
              borderRadius: '5px',
              marginTop: '0.5rem',
              maxWidth: 410,
            }}>
            {!editable ? (
              <span>
                ZERO's records have been updated since the Billing Entity was
                assigned on this invoice.
              </span>
            ) : (
              <span>
                ZERO has new information on file for the Billing Entity
                previously configured on this invoice (TIN, new remittance
                address, Organization NPI, or W9 on file), which requires manual
                review. Please re-select the appropriate Billing Entity from the
                list above.
              </span>
            )}
          </Typography>
        )}
      </div>

      {/* <InvoiceICDCodeSelector
        id={referralInvoice.ID}
        codes={referralInvoice.ICD10Codes}
        readOnly={!editable}
      /> */}
    </>
  )
}
