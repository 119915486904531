// @todo: SD-2591: remove
import moment from 'moment'

// @todo: SD-2591: remove
export const date = {
  format: (date: string) => {
    const m = moment(date)
    if (m.isValid()) {
      return m.format('MM/DD/YYYY')
    }
    return ''
  },
  deformat: (date: string) => {
    const m = moment(date)
    if (m.isValid()) {
      return m.format('YYYY-MM-DD')
    }
    return null
  },
}

export function formatCurrency(v: string | number | null): string {
  if (!v) return ''
  return Number(v).toLocaleString(`en-US`, {
    style: 'currency',
    currency: 'USD',
  })
}

export default {
  formatCurrency,
}
