import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import {
  OpenInNew as IconOpenNew,
  Visibility,
  Warning as IconWarning,
} from '@material-ui/icons'
import Tooltip from '../components/Tooltip'
import CloneReferralButton from '../components/Referrals/CloneReferralButton'
import styled from 'styled-components'
import ArchiveButton from '../components/Referrals/ButtonArchiveReferral'
import dateTime from '../utils/dateTime'

const InvoiceText = styled.a`
  font-size: 11px;
  color: red;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;

  .launch-icon {
    font-size: inherit;
    margin-bottom: -2px;
  }
`

interface ButtonLinkProps {
  row: any
}

const ViewLink: React.FC<ButtonLinkProps> = ({ row }) => {
  const loc = useLocation()
  const pathname =
    row.TypeIs === 'referral_request'
      ? `/referral_request/${row.ID}`
      : `/referral/${row.ID}`

  return (
    <Link
      to={{
        state: { prevQueryString: loc.search || '' },
        pathname,
      }}
      style={{ textDecoration: 'none' }}>
      <Button size="small" color="primary">
        <Visibility width={20} />
        <span style={{ marginLeft: '7px' }}>View</span>
      </Button>
    </Link>
  )
}

const CloneLink: React.FC<ButtonLinkProps> = ({ row }) => {
  if (row.Edit && row.TypeIs === 'referral') {
    return <CloneReferralButton record={row} />
  }

  return null
}

export const ReferralModel = (context: {
  onArchive: (id: number) => void
}) => ({
  Descr: {
    name: 'Description',
    details: {
      sortName: 'Descr',
      options: { width: '100px' },
      dataFormat: (cell: any, row: any) => {
        return (
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            {!!row.IsUrgent && (
              <Grid item xs="auto">
                <Tooltip title="This referral is marked URGENT!">
                  <IconWarning
                    width={15}
                    style={{ marginRight: '0.5rem', color: '#da3e05' }}
                  />
                </Tooltip>
              </Grid>
            )}
            <Grid container item xs="auto" direction="column">
              <Grid item xs={12}>
                <p style={{ margin: 0 }}>{cell}</p>
              </Grid>
              {!!row.ReferralInvoiceID && (
                <Grid item xs={12}>
                  <InvoiceText
                    href={`/referral_invoice/${row.ReferralInvoiceID}`}
                    target="_blank">
                    Invoiced <IconOpenNew className="launch-icon" />
                  </InvoiceText>
                </Grid>
              )}
            </Grid>
          </Grid>
        )
      },
    },
  },
  EmployerMemberName: {
    name: 'Name',
    details: {
      sortName: 'EmployerMemberName',
    },
  },
  UpdatedAt: {
    name: 'Last Updated',
    details: {
      sortName: 'UpdatedAt',
      dataFormat(cell: any, row: any) {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {dateTime
              .parse(cell)
              .local()
              .format(dateTime.formats.PrettyVerbose)}
          </span>
        )
      },
    },
  },
  ServiceDate: {
    name: 'Service Date',
    details: {
      sortName: 'service_date',
      dataFormat: (col: any, row: any) => {
        return dateTime
          .parse(col)
          .format(dateTime.formats.AmericanDate, 'Not Scheduled')
      },
    },
  },
  OrganizationName: {
    name: 'Organization',
    details: {
      sortName: 'OrganizationName',
    },
  },
  PracticeFacilityName: {
    name: 'Facility',
    details: {
      sortName: 'PracticeFacilityName',
    },
  },
  StatusDescr: {
    name: 'Status',
    details: {
      sortName: 'StatusID',
    },
  },
  edit: {
    name: 'Actions',
    details: {
      dataFormat: (_col: any, row: any) => <ViewLink row={row} />,
    },
  },
  clone: {
    name: '',
    details: {
      dataFormat: (_col: any, row: any) => <CloneLink row={row} />,
    },
  },
  archive: {
    name: '',
    details: {
      dataFormat: (_col: any, row: any) => (
        <ArchiveButton
          data={row}
          onArchive={context.onArchive}
          iconButtonOnly={true}
        />
      ),
    },
  },
})
