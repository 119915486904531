// https://driverjs.com/docs
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import useLocalStorageCache from './useLocalStorageCache'
import { driver as tourDriver } from 'driver.js'
import DesignSuite24 from '../components/DesignSuite24'
import { Button } from '@material-ui/core'
import 'driver.js/dist/driver.css'

type stepOpts = {
  key: string
  title?: string
  descr?: string
  selector?: string
  side?: 'left' | 'right' | 'bottom'
}

type registeredStep = stepOpts & {
  ref: React.MutableRefObject<any>
}

export type tourDriverAPI = {
  makeKey: (key?: string) => string
  start(force?: boolean): void
  BtnReplayTour: any
  addStep(so: stepOpts): {
    ref: React.MutableRefObject<any>
  }
}

export default function useTourDriver(tourName: string): tourDriverAPI {
  const [hasViewed, setHasViewed] = useLocalStorageCache(
    `__tour.${tourName}`,
    false
  )
  const [steps, setSteps] = useState<{ [key: string]: registeredStep }>({})
  const [deferRetry, setDeferRetry] = useState(0)
  const [driverObj] = useState(() => tourDriver({ showProgress: true }))

  useEffect(() => {
    return () => {
      driverObj?.destroy?.()
      setSteps({})
      setDeferRetry(0)
    }
  }, [])

  useEffect(() => {
    if (deferRetry === 0) return
    if (deferRetry === 10) {
      console.warn(
        '[useTourDriver] max retries reached; aborting tour start. Did you register all the refs on nodes that are visible at the tour start?'
      )
      return
    }
    start()
  }, [deferRetry])

  const addStep = useCallback(
    function (so: stepOpts): { ref: React.MutableRefObject<any> } {
      if (steps[so.key]) {
        return { ref: steps[so.key].ref }
      }
      const step = { ref: React.createRef(), ...so }
      setSteps((curr: any) => ({ ...curr, [so.key]: step }))
      return { ref: step.ref }
    },
    [steps]
  )

  const start = useCallback(
    (force?: boolean) => {
      if (hasViewed && !force) return

      const registerable = []
      for (const [_, step] of Object.entries(steps)) {
        const el =
          step.ref.current ||
          (!!step.selector && document.querySelector(step.selector))
        if (!el) {
          setDeferRetry((v: number) => v + 1)
          return
        }
        const side = step.side || el.dataset.tourSide || 'left'
        registerable.push({
          element: el,
          popover: {
            title: step.title || el.dataset.tourTitle,
            description: step.descr || el.dataset.tourDescr,
            side,
            align: side === 'bottom' ? 'center' : 'start',
          },
        })
      }

      // console.info('[useTourDriver.start]', steps)
      // @ts-ignore
      driverObj.setSteps(registerable)
      driverObj.drive()
      setHasViewed(true)
      setDeferRetry(0)
    },
    [driverObj, steps, hasViewed]
  )

  return useMemo((): tourDriverAPI => {
    return {
      makeKey: (k?: string) => `${React.useId()}${k || ''}`,
      addStep,
      start,
      BtnReplayTour: () => (
        <DesignSuite24.Tooltip title="Start guided tour for this content">
          <Button
            className="tour-marker-play-btn"
            variant="outlined"
            size="small"
            onClick={() => start(true)}
            startIcon={
              <DesignSuite24.CommonIcons.IconVideo fontSize="inherit" />
            }
            endIcon={
              hasViewed && (
                <DesignSuite24.CommonIcons.IconCheckAll fontSize="inherit" />
              )
            }>
            Start Tour
          </Button>
        </DesignSuite24.Tooltip>
      ),
    }
  }, [addStep, start, hasViewed])
}
