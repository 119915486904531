import * as api from './ApiService'
import { ReferralInvoice } from '../components/ReferralInvoiceForm/types'

export const updateReferralInvoice = (
  id: number,
  body: Partial<ReferralInvoice>
) => {
  return api.put(`/referral_invoice/${id}`, body)
}

export const submitReferralInvoice = (
  id: number,
  body: Partial<ReferralInvoice>
) => {
  return api.post(`/referral_invoice/submit/${id}`, body)
}

export const acceptReferralInvoice = (
  id: number,
  body: Partial<ReferralInvoice>
) => {
  return api.post(`/referral_invoice/accept/${id}`, body)
}
