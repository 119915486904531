import React from 'react'
import SearchField from '../SearchField'
import AutocompleteField from '../AutocompleteField'
import SelectField from '../SelectField'
import useApi from '../../hooks/useApi'
import { useReferralInvoiceFilters } from './hooks'
import styled from 'styled-components'
import ManagedDateInput from '../Inputs/managedDateInput'
import { TextField as MUITextField } from '@material-ui/core'

const FiltersContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  // gap: 10px;
  // align-items: flex-start;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  // margin-top: 15px;
`

interface Props {
  refetchReferralInvoices: (params: any) => void
}

const ReferralInvoiceFilters: React.FC<Props> = (props: Props) => {
  const {
    searchText,
    setSearchText,
    searchDob,
    setSearchDob,
    selectedOrg,
    setSelectedOrg,
    selectedStatus,
    setSelectedStatus,
    selectedEmployer,
    setSelectedEmployer,
  } = useReferralInvoiceFilters(props.refetchReferralInvoices)

  const { data: organizations } = useApi({
    route: '/invoice_organization',
  })

  const { data: statuses } = useApi({
    route: '/referral_invoice_statuses',
  })

  const { data: employers } = useApi({
    route: '/referral_employers',
  })

  if (!organizations || !statuses || !employers) {
    return null
  }

  return (
    <FiltersContainer className="referral-invoice-filters">
      <div style={{ paddingBottom: '1rem' }}>
        <SearchField
          useV2Style
          label="Search Referrals"
          value={searchText}
          onChange={setSearchText}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ManagedDateInput
          label="DOB"
          value={searchDob}
          setter={({ name, value }) => setSearchDob(value)}
          fullWidth
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <AutocompleteField
          label="Organization"
          value={selectedOrg}
          onChange={setSelectedOrg}
          options={organizations.map((org: any) => ({
            name: org.OrganizationName,
            value: org.OrganizationID,
          }))}
          PassthroughProps={{
            renderInput: (params: any, label: any, error: any) => {
              return (
                <MUITextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="none"
                  label="Organization"
                  error={!!error}
                  helperText={error}
                  InputLabelProps={{ shrink: true }}
                />
              )
            },
          }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <SelectField
          // useV2Style
          multiple
          label="Status"
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={statuses.map((stat: any) => ({
            name: stat.Description,
            value: stat.ID,
          }))}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <SelectField
          // useV2Style
          multiple
          label="Employer"
          value={selectedEmployer}
          onChange={setSelectedEmployer}
          options={employers.map((stat: any) => ({
            name: stat.EmployerName,
            value: stat.EmployerID,
          }))}
        />
      </div>
    </FiltersContainer>
  )
}

export default ReferralInvoiceFilters
