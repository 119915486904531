import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { getPriceList } from '../../actions/PriceActions'
import dateTime from '../../utils/dateTime'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { Button } from '@material-ui/core'
import DesignSuite24 from '../DesignSuite24'
import formatters from '../../utils/formatters'
import useErrorHandlers from '../../hooks/useErrorHandlers'

const StyledPriceListContainer = styled.div`
  position: relative;
  padding: 1rem;
  min-height: 30px; // for loading spinner

  &.loading {
    .results {
      filter: blur(4px);
      opacity: 0.75;
    }

    .loading-spinner {
      text-align: center;
    }
  }
`

export interface Props {
  filterCPT?: string | null
  filterDRG?: string | null
  referralID?: number
  referralRequestID?: number
  serviceDate?: string | null
  onSelect?(rs: ShapePriceListItem): void
  onLoadingTriggered?(): void
  notFoundMessage?: any
}

export type ShapePriceListItem = {
  FeeSchedulePriceID: number
  CostKeyCodeID: number
  CostKeyCode: string
  Description: string
  BundleContents: string
  Price: string | null
  DRGCode: string | null
}

export default function PriceList({
  filterCPT,
  filterDRG,
  referralID,
  referralRequestID,
  serviceDate,
  onSelect,
  onLoadingTriggered,
  notFoundMessage = 'No results found',
}: Props): React.ReactElement | null {
  const [results, setResults] = useState<ShapePriceListItem[]>([])
  const [applyFilters, setApplyFilters] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const serviceDateIsValid = useMemo(() => {
    return dateTime.parse(serviceDate).isValid()
  }, [serviceDate])

  useEffect(() => {
    if (!serviceDateIsValid) {
      setResults([])
      setIsLoading(false)
      return
    }
    doLoad()
  }, [serviceDate, serviceDateIsValid, referralID, referralRequestID])

  useEffect(() => {
    setIsLoading(true)
    doFilter(filterCPT, filterDRG)
  }, [filterCPT, filterDRG])

  const doFilter = useCallback(
    debounce((filterCPT, filterDRG) => {
      if (!filterCPT && !filterDRG) {
        setApplyFilters(null)
        setIsLoading(false)
        onLoadingTriggered?.()
        return
      }
      setApplyFilters({ filterCPT, filterDRG })
      setIsLoading(false)
      onLoadingTriggered?.()
    }, 400),
    []
  )

  const filtered = useMemo(() => {
    if (!applyFilters) return results
    let res = results || []
    if (applyFilters?.filterCPT) {
      res = res.filter((r) =>
        r?.CostKeyCode?.toLowerCase().includes(
          applyFilters.filterCPT.toLowerCase()
        )
      )
    }
    if (applyFilters?.filterDRG) {
      res = res.filter((r) =>
        r?.CostKeyCode?.toLowerCase().includes(
          applyFilters.filterDRG.toLowerCase()
        )
      )
    }
    return res
  }, [results, applyFilters])

  function doLoad() {
    setIsLoading(true)
    getPriceList({
      referralID,
      referralRequestID,
      serviceDate,
    })
      .then((res: any) => {
        if (res.Error) throw res
        if (!Array.isArray(res.Data)) {
          setResults([])
          return
        }
        setResults(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to load price list',
        })
      )
      .finally(() => {
        setIsLoading(false)
        setTimeout(() => {
          onLoadingTriggered?.()
        }, 10)
      })
  }

  return (
    <StyledPriceListContainer className={isLoading ? 'loading' : ''}>
      <RenderPriceList items={filtered} onSelect={onSelect} />

      {filtered?.length === 0 && (
        <div style={{ textAlign: 'center' }}>{notFoundMessage}</div>
      )}

      {isLoading && (
        <div className="loading-spinner">
          <DesignSuite24.LoadingSpinner />
        </div>
      )}
    </StyledPriceListContainer>
  )
}

const StyledPriceList = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  font-size: 92%;

  .full {
    grid-column: 1 / -1;
  }

  .br-label {
    display: block;
  }

  code {
    display: inline-block;
    background: #f1f1f1;
    padding: 3px 4px;
    border-radius: 4px;
  }

  p {
    margin: 0.25rem 1rem;
  }

  hr {
    margin: 1rem 0;
  }

  button {
    white-space: nowrap;
  }
`

export function RenderPriceList({
  items,
  onSelect,
  onSelectButtonLabel = 'Use This Bundle',
}: {
  items: ShapePriceListItem[]
  onSelect?(rs: ShapePriceListItem): void
  onSelectButtonLabel?: any
}): React.ReactElement | null {
  if (!(items?.length > 0)) return null

  return (
    <StyledPriceList className="price-list-results">
      {items.map((r: ShapePriceListItem) => (
        <React.Fragment key={r.FeeSchedulePriceID}>
          <div>
            <Button
              size="small"
              variant="outlined"
              onClick={() => onSelect?.(r)}>
              {onSelectButtonLabel}
            </Button>
          </div>
          <div>
            <strong className="br-label">
              Bundle: <code>{r.CostKeyCode}</code>
            </strong>
            <strong className="br-label">Description</strong>
            <p>{r.Description}</p>
            <strong className="br-label">Bundle Contents</strong>
            <p>{r.BundleContents}</p>
          </div>
          <div>{formatters.formatCurrency(r.Price)}</div>
          <hr className="full" />
        </React.Fragment>
      ))}
    </StyledPriceList>
  )
}
