import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Grid, Typography } from '@material-ui/core'
import SelectPracticeFacility from '../ReferralForm/SelectPracticeFacility'
import SelectOrganization from '../ReferralForm/SelectOrganization'
import TextField from '../TextField'
import useApi from '../../hooks/useApi'
import Backlink from '../Backlink'
import ReferralRequestFileUpload from './ReferralRequestFileUpload'
import ReferrerDivisionSelect from '../ReferrerDivisionSelect'
import Autocomplete from '../AutocompleteField'
import { ReferralRequestForm } from './types'
import ManagedDateInput from '../Inputs/managedDateInput'

interface Props {
  form: ReferralRequestForm
  isEditing: boolean
  showErrors: boolean
  customErrors: any
}

const ErrorText = styled.p`
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.66;
`

const ReferralLink: React.FC<{
  referralId: number | null
}> = (props) => {
  // in this case we have a referral reqeust that was approved and has an associated referral,
  // we can link to that referral
  if (!!props.referralId) {
    return (
      <Backlink to={`/referral/${props.referralId}`}>
        View associated referral
      </Backlink>
    )
  }

  return null
}

const ReferralDetailsForm: React.FC<Props> = ({
  form,
  isEditing,
  showErrors,
  customErrors,
}) => {
  const getStatusDesc = () => {
    if (form.data.ReferralID) return 'Referral Created'
    if (form.data.CancelledAt) return 'Cancelled'
    return 'Requested'
  }

  const createdByMessage =
    form.data.CreatedAt &&
    form.data.CreatedByUser &&
    `Created by ${form.data.CreatedByUser.FirstName} ${
      form.data.CreatedByUser.LastName
    } ${moment(form.data.CreatedAt).calendar()}`

  const { data: referrersList } = useApi(
    form.data?.EmployerMember?.EmployerID
      ? {
          route: `/referrers`,
          filter: {
            employerID: form.data?.EmployerMember?.EmployerID,
          },
        }
      : undefined
  )

  const referrerOptions = referrersList
    ? referrersList.map((r: any) => ({
        name: r.Name,
        value: r.ID,
        disabled: !r.IsInEmployerAccessGroup,
      }))
    : []

  React.useEffect(() => {
    // automatically set ReferrerID if only one option exists
    // this handles the case of ReferralRequester role
    if (
      referrersList &&
      referrersList.length === 1 &&
      !referrersList[0].disabled
    ) {
      form.setters.ReferrerID(referrersList[0].ID)
    }
  }, [referrersList])

  return (
    <div>
      {/* <FormHeader
        title="Referral Request Details"
        description={
          <div>
            <p style={{ marginTop: '0px' }}>{createdByMessage || ''}</p>
            <ReferralLink referralId={form.data.ReferralID} />
          </div>
        }
      /> */}
      <Typography variant="h5">
        <span className="header-snazzy">Referral Request Details</span>
      </Typography>
      <Typography variant="caption">
        <>
          <p style={{ marginTop: '0px' }}>{createdByMessage || ''}</p>
          <ReferralLink referralId={form.data.ReferralID} />
        </>
      </Typography>

      <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
        <Grid item xs={4}>
          {!!form.data?.EmployerMember?.EmployerID && (
            <SelectOrganization
              useV2Style
              form={form}
              showErrors={showErrors}
              readOnly={!form.data.ContentEditable}
            />
          )}
          {showErrors &&
            customErrors.includes('OrganizationID') &&
            !form.data.OrganizationID && (
              <ErrorText>Organization is required.</ErrorText>
            )}
        </Grid>
        <Grid item xs={4}>
          <SelectPracticeFacility
            useV2Style
            form={form}
            showErrors={showErrors}
            readOnly={!form.data.ContentEditable}
          />
          {showErrors &&
            customErrors.includes('PracticeFacilityID') &&
            !form.data.PracticeFacilityID && (
              <ErrorText style={{ marginTop: '-17px' }}>
                Practice facility is required.
              </ErrorText>
            )}
        </Grid>
        <Grid item xs={4}>
          <Grid>
            <Autocomplete
              label="Referrer"
              options={referrerOptions}
              value={form.data.ReferrerID}
              error={showErrors ? form.errors.ReferrerID : null}
              onChange={(id: number) =>
                form.setData({
                  ...form.data,
                  ReferrerID: id,
                  ReferrerDivisionID: null,
                })
              }
              readOnly={
                !form.data.ContentEditable || referrerOptions.length === 1
              }
              PassthroughProps={{
                renderInput: (params: any, label: any, error: any) => {
                  return (
                    <TextField
                      {...params}
                      margin="none"
                      label={label}
                      error={!!error}
                      helperText={error}
                      InputLabelProps={{ shrink: true }}
                    />
                  )
                },
              }}
            />

            {form.data.ReferrerID && (
              <div style={{ paddingTop: '1rem' }}>
                <ReferrerDivisionSelect
                  value={form.data.ReferrerDivisionID}
                  onChange={form.setters.ReferrerDivisionID}
                  referrerID={form.data.ReferrerID}
                  disabled={!form.data.ContentEditable}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
        <Grid item xs={4}>
          <TextField
            label="Description"
            value={form.data.Descr || ''}
            onChange={form.setters.Descr}
            error={showErrors ? form.errors.Descr : null}
            disabled={!form.data.ContentEditable}
          />
        </Grid>
        <Grid item xs={3}>
          <ManagedDateInput
            label="Requested Service Date"
            value={form.data.RequestedServiceDate || ''}
            setter={({ name, value }) =>
              form.setters.RequestedServiceDate(value)
            }
            error={showErrors ? form.errors.RequestedServiceDate : null}
            disabled={!form.data.ContentEditable}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Main CPT"
            value={form.data.MainCptCode || ''}
            onChange={form.setters.MainCptCode}
            error={showErrors ? form.errors.MainCptCode : null}
            disabled={!form.data.ContentEditable}
          />
        </Grid>
        <Grid item xs={3}>
          {/* hide status for 'create' form */}
          {isEditing && (
            <TextField
              disabled
              label="Status"
              value={getStatusDesc()}
              onChange={() => {}} // no-op: display only
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
        <Grid item xs={12}>
          <TextField
            label="Notes"
            value={form.data.Notes || ''}
            onChange={form.setters.Notes}
            disabled={!form.data.ContentEditable}
            minRows={3}
            multiline
          />
        </Grid>
      </Grid>

      <hr />
      {form.data.ID ? (
        <ReferralRequestFileUpload
          referralRequestId={form.data.ID}
          disabled={!form.data.ContentEditable}
        />
      ) : (
        <span>*NOTE* You will be able upload files after saving</span>
      )}
    </div>
  )
}

export default ReferralDetailsForm
