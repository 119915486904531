import * as api from '../services/ApiService'
import { Props as PriceListProps } from '../components/PriceList'

export const getReferralPrices = (params: { referralID: number }) => {
  return api.get(`/referral/${params.referralID}/prices`)
}

export const saveReferralPrices = (params: any) => {
  const { referralId, priceIDs } = params
  return api.put(`/referral/${referralId}/prices`, { priceIDs })
}

export const getReferralRequestPrices = (params: { requestID: number }) => {
  return api.get(`/referral_request/${params.requestID}/prices`)
}

export const saveReferralRequestPrices = (params: any) => {
  const { requestID, priceIDs } = params
  return api.put(`/referral_request/${requestID}/prices`, { priceIDs })
}

export const getPriceList = (params: PriceListProps) => {
  return api.get(`/price_list`, null, params)
}
