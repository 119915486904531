import React from 'react'
import { ExitToApp as IconExit } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../providers/Auth'
import { useConfig } from '../../providers/Config'
import Logo from '../../assets/tzc-logo.png'
import styled from 'styled-components'
import { routes } from '../../Routes'
import AddNewReferralBtn from '../Referrals/AddNewReferralButton'

const StyledSidebar = styled.aside`
  position: fixed;
  top: 0;
  align-self: start;
  width: var(--sidebar-width);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  transition: all 0.45s ease-in-out;

  .logo-wrap {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;

    .zero-logo {
      object-fit: contain;
      width: auto;
      height: 30px;
    }
  }

  .sidebar-top {
    flex: 1 1 0%;

    .nav-menu {
      padding: 0;
      margin: 1rem 0 1.5rem 0;

      a.menu-link {
        position: relative;
        overflow: hidden;
        text-decoration: none;
        color: inherit;
        display: block;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 100%;
          top: 0;
          bottom: 0;
          background: #6fa0b1ad;
          width: 8px;
          transform: translateX(0);
          transition: all 0.3s;
        }

        .menu-inner {
          display: flex;
          align-items: center;
          line-height: 1;
          padding: 0.5rem;
          text-decoration: none;
          color: inherit;
          font-weight: 600;

          .MuiSvgIcon-root {
            margin-right: 0.5rem;
            opacity: 0.5;
          }
        }

        &:hover,
        &.active {
          &:before {
            transform: translateX(-100%);
          }

          .menu-inner {
            // background: rgba(0, 0, 0, 0.05);
            background: -webkit-linear-gradient(
              180deg,
              rgb(133 159 158 / 10%),
              rgb(18 0 94 / 10%) 80%
            );
          }
        }
      }
    }
  }

  .sidebar-bottom {
    padding: 1rem;
  }
`

export default function SideBar(): React.ReactElement | null {
  const { access, logout } = useAuth() as any
  const { env, gitHash } = useConfig() as any
  const showRoutes = React.useMemo(() => {
    const r = routes.filter((r: any) => !!r.label && r.permCheck?.(access))
    return r
  }, [routes, access])

  const supportEmail = access?.Roles?.ReferralCoordinator
    ? `providers@zero.health`
    : `help@zero.health`

  return (
    <StyledSidebar id="app-frame-sidebar">
      <div className="logo-wrap">
        <img className="zero-logo" alt="Zero Logo" src={Logo} />
      </div>

      <nav className="sidebar-top">
        <div className="nav-menu">
          {showRoutes.map((route, index) => (
            <NavLink
              className="menu-link"
              key={`${index}:${route.path}`}
              // @ts-ignore
              to={route.path}>
              <span className="menu-inner">
                {route.icon && <route.icon />}
                <span>{route.label}</span>
              </span>
            </NavLink>
          ))}
        </div>

        <hr style={{ margin: '1rem 0' }} />
        <div
          id="tour-marker-fast-actions"
          style={{ padding: '0 0.75rem 0 0.5rem' }}>
          <small
            style={{
              fontSize: '90%',
              fontWeight: 'bold',
              display: 'block',
              paddingBottom: '0.25rem',
              color: '#333',
            }}>
            Fast Actions
          </small>
          <AddNewReferralBtn
            stackButtons
            ButtonProps={{
              size: 'small',
              style: { justifyContent: 'flex-start' },
            }}
          />
        </div>

        <hr style={{ margin: '1rem 0' }} />
        <div style={{ padding: '0 0.75rem 0 0.5rem' }}>
          <Button
            size="small"
            onClick={logout}
            variant="outlined"
            fullWidth
            startIcon={<IconExit />}>
            Logout
          </Button>
        </div>

        {env !== 'prd' && (
          <>
            <hr style={{ margin: '1rem 0' }} />
            <small>Version: {gitHash}</small>
          </>
        )}
      </nav>

      <div className="sidebar-bottom">
        <span style={{ fontSize: '14px' }}>
          Questions? Please contact us at:&nbsp;
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </span>
      </div>
    </StyledSidebar>
  )
}
