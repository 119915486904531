import React, { useState } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {
  STATUS_DRAFT,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from '../ReferralInvoiceForm/statuses'
import { useAuth } from '../../providers/Auth'
import { ReferralInvoiceModel } from '../../models/referralInvoice'
import { ScheduledReferralModel } from '../../models/scheduledReferral'
import InvoiceStatusToolbar from './InvoiceStatusToolbar'
import DataTable from '../DataTable'
import useApi from '../../hooks/useApi'
import { useReadyReferrals } from './hooks'
import { COLORS } from '../../utils/colors'
import { SortDirection } from '@material-ui/core'
import { useSetFrameHeaderLeft } from '../AppFrame'

const StickyButtonContainer = styled.div`
  position: sticky;
  top: 75px;
  z-index: 1000;
  background: white;
`

const RoleLabel: React.FC<{ role: Role }> = ({ role }) => {
  const colorMap = {
    Submitter: COLORS.PROVIDERS.EGGPLANT,
    Reviewer: COLORS.PROVIDERS.BLUEBERRY,
  }

  const Label = styled.div`
    background-color: ${colorMap[role]};
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    top: 40px;
    margin-left: 15px;
    position: absolute;
  `

  return <Label>{role}</Label>
}

type Role = 'Submitter' | 'Reviewer'

interface TableHeaderProps {
  id?: string
  title: string
  role: Role
  showLabel: boolean
}

const TableHeader: React.FC<TableHeaderProps> = ({
  id,
  title,
  role,
  showLabel,
}) => {
  const Header = styled(Typography)`
    margin-top: 35px !important;
  `
  return (
    <div id={id} style={{ position: 'relative' }}>
      <Header variant="h5" style={{ display: 'inline-block' }}>
        {title}
      </Header>
      {showLabel && <RoleLabel role={role} />}
    </div>
  )
}

const ReferralInvoices: React.FC<{
  model: any
  filter?: any
  allowRowSelection?: boolean
  initPageSize?: number
  rowsPerPage?: number[]
}> = ({
  model,
  filter,
  allowRowSelection,
  rowsPerPage = [10, 25, 50],
  initPageSize = 10,
}) => {
  const { data, meta, refetch } = useApi({
    route: '/referral_invoice',
    ...filter,
    range: [1, initPageSize],
  })
  const defaultSortable = { col: 'UpdatedAt', dir: 'DESC' }
  const [sortable, setSortable] = React.useState<any>(defaultSortable)
  const [selectedRows, setSelectedRows] = React.useState<any[]>([])

  const onSelected = (selected: any[]) => {
    setSelectedRows(selected)
  }
  const onChangePage = (change: any, sort: any) => {
    const { range } = change
    const { col, dir } = sort
    if (col && dir) return refetch({ range, sort: [col, dir] })
    refetch({ range })
  }

  const sortHandler = (
    sortable: {
      col: string
      dir: 'ASC' | 'DESC' | SortDirection | false
    },
    range: [number, number] | undefined
  ) => {
    const { col, dir } = sortable
    setSortable(sortable)
    refetch({ sort: [col, dir], range })
  }

  if (!data || !meta) return null
  return (
    <DataTable
      keyProp="ID"
      data={data}
      columns={model}
      count={meta.Total}
      initPage={1}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePage}
      checkHandler={allowRowSelection ? onSelected : null}
      sortable={sortable}
      sortHandler={sortHandler}
      allowEditing={false}
      initPageSize={initPageSize}
      rowsPerPage={rowsPerPage}
    />
  )
}

const ScheduledReferrals = () => {
  const defaultSortable = { col: 'UpdatedAt', dir: 'DESC' }
  const { data, meta, refetch } = useReadyReferrals()
  const [sortable, setSortable] = React.useState<any>(defaultSortable)

  const onChangePage = (change: any, sort: any) => {
    const { range } = change
    const { col, dir } = sort
    if (col && dir) return refetch({ range, sort: [col, dir] })
    refetch({ range })
  }

  const sortHandler = (
    sortable: {
      col: string
      dir: 'ASC' | 'DESC' | SortDirection | false
    },
    range: [number, number] | undefined
  ) => {
    const { col, dir } = sortable
    setSortable(sortable)
    refetch({ sort: [col, dir], range })
  }

  if (!data || !meta) return null
  return (
    <DataTable
      keyProp="ID"
      data={data}
      columns={ScheduledReferralModel()}
      count={meta.Total}
      initPage={1}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePage}
      sortable={sortable}
      sortHandler={sortHandler}
      allowEditing={false}
      initPageSize={10}
    />
  )
}

const getStatusFilter = (status: number) => ({
  filter: { ReferralInvoiceStatusIDs: [status] },
})

const ReferralSubmitterDashboard: React.FC<{ showLabels: boolean }> = ({
  showLabels,
}) => {
  const { data: statuses } = useApi({
    route: '/referral_invoice_statuses',
  })

  if (!statuses) return null

  return (
    <>
      <TableHeader
        id="draft-submitter"
        title="Draft Invoices"
        role="Submitter"
        showLabel={showLabels}
      />
      <ReferralInvoices
        model={ReferralInvoiceModel(statuses)}
        filter={getStatusFilter(STATUS_DRAFT)}
      />

      <TableHeader
        id="ready-submitter"
        title="Ready to invoice"
        role="Submitter"
        showLabel={showLabels}
      />
      <ScheduledReferrals />

      <TableHeader
        id="rejected-submitter"
        title="Needs Review"
        role="Submitter"
        showLabel={showLabels}
      />
      <ReferralInvoices
        model={ReferralInvoiceModel(statuses)}
        filter={getStatusFilter(STATUS_REJECTED)}
      />

      <TableHeader
        id="submitted-submitter"
        title="Awaiting Approval"
        role="Submitter"
        showLabel={showLabels}
      />
      <ReferralInvoices
        model={ReferralInvoiceModel(statuses)}
        filter={getStatusFilter(STATUS_SUBMITTED)}
      />
    </>
  )
}

const ReferralReviewerDashboard: React.FC<{ showLabels: boolean }> = ({
  showLabels,
}) => {
  const { data: statuses } = useApi({
    route: '/referral_invoice_statuses',
  })

  if (!statuses) return null
  return (
    <>
      <TableHeader
        id="submitted-reviewer"
        title="Ready for Review"
        role="Reviewer"
        showLabel={showLabels}
      />
      <ReferralInvoices
        model={ReferralInvoiceModel(statuses)}
        filter={getStatusFilter(STATUS_SUBMITTED)}
      />

      <TableHeader
        id="rejected-reviewer"
        title="Rejected"
        role="Reviewer"
        showLabel={showLabels}
      />
      <ReferralInvoices
        model={ReferralInvoiceModel(statuses)}
        filter={getStatusFilter(STATUS_REJECTED)}
      />
    </>
  )
}

export const BillingDashboard = () => {
  const { access } = useAuth()

  useSetFrameHeaderLeft(() => (
    <Typography variant="h5">Billing Dashboard</Typography>
  ))

  const isSubmitter = access.Roles.ReferralInvoiceSubmitter
  const isReviewer = access.Roles.ReferralInvoiceReviewer

  return (
    <div>
      <InvoiceStatusToolbar />
      {isSubmitter && (
        <ReferralSubmitterDashboard showLabels={isSubmitter && isReviewer} />
      )}
      {isReviewer && (
        <ReferralReviewerDashboard showLabels={isSubmitter && isReviewer} />
      )}
    </div>
  )
}
