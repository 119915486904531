import React, { useState, useEffect, useMemo, useRef } from 'react'
import DesignSuite24 from '../../../components/DesignSuite24'
import { Button, Typography } from '@material-ui/core'
import ManagedDateInput from '../../Inputs/managedDateInput'
import TextField from '../../TextField'
import styled from 'styled-components'
import CptModifierCodeAutoComplete from '../../Inputs/cptModifierCodeAutoComplete'
import DRGCodeAutoComplete from '../../Inputs/drgCodeAutoComplete'
import PriceList, { ShapePriceListItem } from '../../PriceList'
import { ReferralInvoice } from '../types'
import dateTime from '../../../utils/dateTime'
import {
  postReferralInvoiceLine,
  putReferralInvoiceLine,
} from '../../../actions/ReferralInvoiceActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../hooks/useSnackbar'
import useTourDriver from '../../../hooks/useTourDriver'

const StyledAddEditLine = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  form.basics {
    display: grid;
    column-gap: 0.5rem;
    row-gap: 0.75rem;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    // margin: 0 -1rem -1rem;
    margin: 0;
    padding: 1.5rem 18%;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    border-bottom: 1px solid #ddd;

    .full {
      grid-column: 1 / -1;
    }

    .ext-label {
      display: block;
      margin-bottom: 0.15rem;
      white-space: nowrap;
      font-weight: 600;
      font-size: 90%;
      opacity: 0.8;
    }
  }

  .disp-price-list {
    flex: 1;
    overflow: hidden;
    overflow-y: scroll;
  }
`

interface Props {
  referralInvoice: ReferralInvoice | null
  defaultDateOfService?: string | null
  isEditable?: boolean
  onSave?(): void
  hideTrigger?: boolean
}

export type ShapeInvoiceLineData = {
  ID?: number
  DateOfService: string | null
  CptCode: string | null
  PlaceOfServiceCode: string | null
  Units: number | null
  CPTModifierCode: string | null
  DRGCode: string | null
  Description: string | null
  Cost: string | null
  DiagnosisPointer: string | null
  Notes: string | null
  FeeSchedulePriceID: number | null
  ReferralInvoiceID: number | null
}

type shapeSelectedBundle = {
  FeeSchedulePriceID: number
  CostKeyCode: string
}

const emptyDefault = (): ShapeInvoiceLineData => ({
  DateOfService: null,
  CptCode: null,
  PlaceOfServiceCode: null,
  Units: 1,
  CPTModifierCode: null,
  DRGCode: null,
  Description: null,
  Cost: null,
  DiagnosisPointer: 'A',
  Notes: null,
  FeeSchedulePriceID: null,
  ReferralInvoiceID: null,
})

const autoCompleteTextFieldProps = Object.freeze({
  label: '',
  placeholder: '',
})

const knownPOSCodes = [
  '02',
  '10',
  '11',
  '12',
  '21',
  '22',
  '23',
  '24',
  '49',
  '81',
]

export default React.forwardRef(function addEditInvoiceLine(
  {
    referralInvoice,
    isEditable = true,
    defaultDateOfService,
    onSave,
    hideTrigger = false,
  }: Props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [lineData, setLineData] = useState<ShapeInvoiceLineData>(emptyDefault)
  const [selectedPrice, setSelectedPrice] =
    useState<shapeSelectedBundle | null>(null)
  const [showDebug, setShowDebug] = useState(false)
  const [formErrs, setFormErrs] = useState<string[]>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbarDuration } = useSnackbar()
  const refDlg = useRef<any>(null)

  const serviceDateIsValid = useMemo(() => {
    return dateTime.parse(lineData.DateOfService).isValid()
  }, [lineData.DateOfService])

  const disableFieldBecauseSelectedPrice =
    !serviceDateIsValid || !!selectedPrice

  React.useImperativeHandle(
    ref,
    () => ({
      editLineData(l: ShapeInvoiceLineData) {
        setLineData(l)
        setIsOpen(true)
        if (l.FeeSchedulePriceID) {
          setSelectedPrice({
            FeeSchedulePriceID: l.FeeSchedulePriceID,
            CostKeyCode: l.CptCode || '',
          })
        }
      },
    }),
    []
  )

  useEffect(() => {
    if (!isOpen) return
    tourDriver.start()
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) {
      setLineData(emptyDefault())
      setSelectedPrice(null)
      setShowDebug(false)
      setFormErrs([])
      return
    }
    setLineData((curr: ShapeInvoiceLineData) => ({
      ...curr,
      DateOfService: curr.DateOfService || defaultDateOfService || null,
      ReferralInvoiceID: referralInvoice?.ID || null,
    }))
  }, [isOpen])

  const tourDriver = useTourDriver('invoiceLineAddEdit')
  const tourDOS = tourDriver.addStep({
    key: tourDriver.makeKey(),
  })
  const tourCPT = tourDriver.addStep({
    key: tourDriver.makeKey(),
  })
  const tourBundles = tourDriver.addStep({
    key: tourDriver.makeKey(),
  })

  function clearLinkedBundle() {
    setSelectedPrice(null)
    setLineData((ild: ShapeInvoiceLineData) => ({
      ...ild,
      FeeSchedulePriceID: null,
      Description: null,
      Cost: null,
      DRGCode: null,
      CptCode: null,
    }))
  }

  function doSave(ev: any) {
    ev.preventDefault()
    const errs = checkAndSetErrors()
    if (errs.length) {
      return
    }

    if (lineData.ID) {
      putReferralInvoiceLine(lineData)
        .then((res: any) => {
          if (res.error) throw res
          showSnackbarDuration(
            'Invoice line updated OK',
            SnackbarTypeSuccess,
            5000
          )
          setIsOpen(false)
          onSave?.()
        })
        .catch(catchAPIError({ defaultMessage: 'Failed saving line' }))
      return
    }

    postReferralInvoiceLine(lineData)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbarDuration('Invoice line added OK', SnackbarTypeSuccess, 5000)
        setIsOpen(false)
        onSave?.()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving line' }))
  }

  function checkAndSetErrors(): string[] {
    const coll = []
    if (!serviceDateIsValid) {
      coll.push('Date Of Service is required and must be a valid date.')
    }
    if (!knownPOSCodes.includes(lineData.PlaceOfServiceCode || '')) {
      coll.push(
        `POS Code is required and must be one of: ${knownPOSCodes.join(', ')}.`
      )
    }
    if (!lineData.DiagnosisPointer) {
      coll.push('Diagnosis Pointer is required.')
    }
    if (!lineData.FeeSchedulePriceID) {
      if (!lineData.Description) {
        coll.push('Description is required.')
      }
      if (!lineData.Cost) {
        coll.push('Cost is required.')
      }
    }
    setFormErrs(coll)
    return coll
  }

  if (!referralInvoice?.ID) return null

  return (
    <>
      {hideTrigger === false && (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setIsOpen((v) => !v)}
          disabled={!isEditable}>
          Add Line
        </Button>
      )}

      <DesignSuite24.Dialog
        ref={refDlg}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        // title="testing1212"
        title={
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              columnGap: '1rem',
            }}>
            <Typography variant="h6">Invoice Line</Typography>
            <tourDriver.BtnReplayTour />
          </div>
        }
        disableCloseOnBackdropClick
        disableCloseOnEsc
        noContentPadding
        fullHeight>
        {isOpen && (
          <StyledAddEditLine id="add-edit-line-container">
            <form className="basics" onSubmit={doSave}>
              {formErrs.length > 0 && (
                <div className="full">
                  <DesignSuite24.AlertError>
                    {formErrs.map((err, i) => (
                      <div key={i}>{err}</div>
                    ))}
                  </DesignSuite24.AlertError>
                </div>
              )}

              {selectedPrice && (
                <div className="full">
                  <DesignSuite24.AlertSuccess>
                    <strong>Selected Bundle:</strong>{' '}
                    {selectedPrice.CostKeyCode}
                    <div>
                      <small>
                        By selecting a pre-configured bundle, ZERO auto-fills
                        known values for you. To clear the currently linked
                        bundle and drop back to manual entry, click the button
                        below.
                      </small>
                    </div>
                    <div style={{ paddingTop: '0.5rem', textAlign: 'center' }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={clearLinkedBundle}>
                        Unlink Current Bundle
                      </Button>
                    </div>
                  </DesignSuite24.AlertSuccess>
                </div>
              )}

              <div
                className="full"
                ref={tourDOS.ref}
                data-tour-title="Date of Service"
                data-tour-descr="This field is required, and is used to search your organization's available fee schedule bundles. It must be filled in first.">
                <small className="ext-label">Date Of Service</small>
                <ManagedDateInput
                  // label="Date of Service"
                  name="DateOfService"
                  value={lineData.DateOfService}
                  // setter={({ name, value }) => {
                  //   console.log('setter was called?!')
                  // }}
                  setter={({ name, value }) =>
                    setLineData((v: ShapeInvoiceLineData) => ({
                      ...v,
                      [name]: value,
                    }))
                  }
                />
              </div>
              <div
                ref={tourCPT.ref}
                data-tour-title="CPT Code"
                data-tour-descr="Typing in the CPT code field here will filter the available bundles below. You should select a bundle if available, otherwise - you *can* use your own CPT code.">
                {selectedPrice ? (
                  <>
                    <small className="ext-label">
                      Selected Bundle{' '}
                      <DesignSuite24.CommonIcons.IconLock fontSize="inherit" />
                    </small>
                    <TextField
                      value={selectedPrice.CostKeyCode || ''}
                      disabled
                      inputProps={{ size: 9 }}
                      onChange={() => {}}
                    />
                  </>
                ) : (
                  <>
                    <small className="ext-label">CPT Code</small>
                    <DesignSuite24.Tooltip title="When you start typing a CPT code, available bundles will be automatically filtered below. You should always prefer choosing a bundle if one is available; otherwise, manually entering a CPT code is permitted.">
                      <TextField
                        value={lineData.CptCode || ''}
                        onChange={(CptCode: string) =>
                          setLineData((v: ShapeInvoiceLineData) => ({
                            ...v,
                            CptCode,
                          }))
                        }
                        inputProps={{ maxLength: 5, size: 9 }}
                        disabled={!serviceDateIsValid}
                      />
                    </DesignSuite24.Tooltip>
                  </>
                )}
              </div>
              <div>
                <small className="ext-label">CPT Modifier Code</small>
                <CptModifierCodeAutoComplete
                  selectedModifierCode={lineData.CPTModifierCode || ''}
                  onChange={(code: string, _: any) => {
                    setLineData((v: ShapeInvoiceLineData) => ({
                      ...v,
                      CPTModifierCode: code,
                    }))
                  }}
                  TextFieldProps={autoCompleteTextFieldProps}
                  disabled={!serviceDateIsValid}
                />
              </div>
              <div>
                <small className="ext-label">
                  Cost{' '}
                  {disableFieldBecauseSelectedPrice && (
                    <DesignSuite24.CommonIcons.IconLock fontSize="inherit" />
                  )}
                </small>
                <TextField
                  value={lineData.Cost || ''}
                  disabled={disableFieldBecauseSelectedPrice}
                  onChange={(Cost: string) =>
                    setLineData((v: ShapeInvoiceLineData) => ({ ...v, Cost }))
                  }
                  inputProps={{ maxLength: 9, size: 14 }}
                  InputProps={{
                    startAdornment: (
                      <span
                        style={{
                          opacity: '0.5',
                          fontSize: '92%',
                          fontWeight: 'bold',
                          display: 'inline-block',
                          paddingRight: '0.1rem',
                          marginLeft: '-4px',
                          fontFamily: 'monospace',
                        }}>
                        $
                      </span>
                    ),
                  }}
                />
              </div>
              <div style={{ display: 'inline-flex', columnGap: '1rem' }}>
                <div>
                  <small className="ext-label">Diagnosis Pointer</small>
                  <TextField
                    value={lineData.DiagnosisPointer || ''}
                    disabled={!serviceDateIsValid}
                    onChange={(DiagnosisPointer: string) =>
                      setLineData((v: ShapeInvoiceLineData) => ({
                        ...v,
                        DiagnosisPointer,
                      }))
                    }
                    inputProps={{ maxLength: 5, size: 5 }}
                  />
                </div>
                <div>
                  <small className="ext-label">Units</small>
                  <TextField
                    value={lineData.Units || ''}
                    disabled={!serviceDateIsValid}
                    onChange={(u: string) => {
                      let Units: any = Number.parseFloat(u)
                      if (!Number.isFinite(Units)) {
                        Units = null
                      }
                      setLineData((v: ShapeInvoiceLineData) => ({
                        ...v,
                        Units,
                      }))
                    }}
                    inputProps={{ maxLength: 3, size: 5 }}
                  />
                </div>
              </div>
              <div>
                <small className="ext-label">POS Code</small>
                <TextField
                  value={lineData.PlaceOfServiceCode || ''}
                  disabled={!serviceDateIsValid}
                  onChange={(PlaceOfServiceCode: string) =>
                    setLineData((v: ShapeInvoiceLineData) => ({
                      ...v,
                      PlaceOfServiceCode,
                    }))
                  }
                  inputProps={{ maxLength: 2, size: 2 }}
                />
              </div>
              <div>
                <small className="ext-label">
                  DRG Code{' '}
                  {disableFieldBecauseSelectedPrice && (
                    <DesignSuite24.CommonIcons.IconLock fontSize="inherit" />
                  )}
                </small>
                <DRGCodeAutoComplete
                  selectedDRGCode={lineData.DRGCode || ''}
                  disabled={disableFieldBecauseSelectedPrice}
                  onChange={(code: string, _: any) => {
                    setLineData((v: ShapeInvoiceLineData) => ({
                      ...v,
                      DRGCode: code,
                    }))
                  }}
                  TextFieldProps={autoCompleteTextFieldProps}
                />
              </div>
              <div className="full">
                <small className="ext-label">
                  Description{' '}
                  {disableFieldBecauseSelectedPrice && (
                    <DesignSuite24.CommonIcons.IconLock fontSize="inherit" />
                  )}
                </small>
                <TextField
                  value={lineData.Description || ''}
                  disabled={disableFieldBecauseSelectedPrice}
                  onChange={(Description: string) =>
                    setLineData((v: ShapeInvoiceLineData) => ({
                      ...v,
                      Description,
                    }))
                  }
                />
              </div>
              <div className="full">
                <small className="ext-label">Notes</small>
                <TextField
                  value={lineData.Notes || ''}
                  disabled={!serviceDateIsValid}
                  onChange={(Notes: string) =>
                    setLineData((v: ShapeInvoiceLineData) => ({ ...v, Notes }))
                  }
                  multiline
                  minRows={2}
                />
              </div>

              <div className="full">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={doSave}>
                  Save Line
                </Button>
              </div>
            </form>

            {/* <div style={{ textAlign: 'right' }}>
              <small onClick={() => setShowDebug((v) => !v)}>Debug</small>
              {showDebug && (
                <pre
                  style={{
                    textAlign: 'left',
                    background: '#eee',
                    padding: '0.5rem',
                    fontSize: 13,
                  }}>
                  {JSON.stringify({ lineData, selectedPrice }, null, 2)}
                </pre>
              )}
            </div> */}

            {!selectedPrice && (
              <div
                className="disp-price-list"
                ref={tourBundles.ref}
                data-tour-title="Fee Schedule Bundles"
                data-tour-descr="Bundles (procedures) available on the fee schedule will appear here.">
                <PriceList
                  referralID={referralInvoice.ReferralID}
                  serviceDate={lineData.DateOfService}
                  filterCPT={lineData.CptCode}
                  filterDRG={lineData.DRGCode}
                  onSelect={(sel: ShapePriceListItem) => {
                    setLineData((ild: ShapeInvoiceLineData) => ({
                      ...ild,
                      FeeSchedulePriceID: sel.FeeSchedulePriceID,
                      Description: sel.Description,
                      Cost: sel.Price,
                      DRGCode: sel.DRGCode,
                      CptCode: null,
                    }))
                    setSelectedPrice({
                      FeeSchedulePriceID: sel.FeeSchedulePriceID,
                      CostKeyCode: sel.CostKeyCode,
                    })
                  }}
                  onLoadingTriggered={() => {
                    // debugger
                    refDlg.current?.scrollToTop?.()
                  }}
                  notFoundMessage={
                    <span>
                      With "Date Of Service" filled in, populating CPT or DRG
                      fields above will automatically search available fee
                      schedule bundles here.
                    </span>
                  }
                />
              </div>
            )}
          </StyledAddEditLine>
        )}
      </DesignSuite24.Dialog>
    </>
  )
})
