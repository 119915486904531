import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import scrollToElement from 'scroll-to-element'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import { useAuth } from '../../providers/Auth'
import useApi from '../../hooks/useApi'
import { useReadyReferrals } from './hooks'
import * as REFERRAL_INVOICE_STATUSES from '../ReferralInvoiceForm/statuses'
import { stringifyParams } from '../../hooks/useQueryParams'

const { STATUS_SUBMITTED, STATUS_REJECTED, STATUS_ACCEPTED } =
  REFERRAL_INVOICE_STATUSES

const DashboardCard = styled(CardContent)`
  color: white;
  text-align: center;
  cursor: pointer;
`

const DisplayCard = styled(Card)`
  height: 100%;
`

const scrollTo = (id: string) => {
  return () => {
    scrollToElement(id, { duration: 250 })
  }
}

const getQsParams = (statusID: number) => {
  return stringifyParams({ filter: { ReferralInvoiceStatusIDs: [statusID] } })
}

const SubmitterCards = ({ referralTotal, amounts }: any) => {
  // shades of COLORS.PROVIDERS.EGGPLANT
  const lightColor = '#a35dce'
  const mediumLightColor = '#892ec0'
  const color = '#7015a7'
  const darkColor = '#571082'

  const ReadyCard = styled(DashboardCard)`
    background-color: ${lightColor};
    height: 100%;
  `

  const SubmittedCard = styled(DashboardCard)`
    background-color: ${mediumLightColor};
    height: 100%;
  `

  const RejectedCard = styled(DashboardCard)`
    background-color: ${color};
    height: 100%;
  `

  const AcceptedCard = styled(DashboardCard)`
    background-color: ${darkColor};
    height: 100%;
  `

  return (
    <>
      {/* Submitter - Ready to invoice (status: ?) */}
      <Grid item xs={3}>
        <DisplayCard onClick={scrollTo('#ready-submitter')}>
          <ReadyCard>
            <Typography variant="h3">{referralTotal || 0}</Typography>
            <Typography variant="h5">Ready to invoice</Typography>
          </ReadyCard>
        </DisplayCard>
      </Grid>

      {/* Submitter - Submitted for approval (status: submitted) */}
      <Grid item xs={3}>
        <DisplayCard onClick={scrollTo('#submitted-submitter')}>
          <SubmittedCard>
            <Typography variant="h3">
              {amounts[STATUS_SUBMITTED] || 0}
            </Typography>
            <Typography variant="h5">Submitted for approval</Typography>
          </SubmittedCard>
        </DisplayCard>
      </Grid>

      {/* Submitter - Needs your review (status: rejected) */}
      <Grid item xs={3}>
        <DisplayCard onClick={scrollTo('#rejected-submitter')}>
          <RejectedCard>
            <Typography variant="h3">
              {amounts[STATUS_REJECTED] || 0}
            </Typography>
            <Typography variant="h5">Needs your review</Typography>
          </RejectedCard>
        </DisplayCard>
      </Grid>

      {/* Submitter - Accepted (status: accepted) */}
      {/* <Grid item xs={3}>
        <Link
          to={{
            pathname: '/referral_invoices',
            search: getQsParams(STATUS_ACCEPTED),
          }}
          style={{ textDecoration: 'none' }}>
          <DisplayCard>
            <AcceptedCard>
              <Typography variant="h3">
                {amounts[STATUS_ACCEPTED] || 0}
              </Typography>
              <Typography variant="h5">Accepted</Typography>
            </AcceptedCard>
          </DisplayCard>
        </Link>
      </Grid> */}
    </>
  )
}

const ReviewerCards = ({ amounts }: any) => {
  // shades of COLORS.PROVIDERS.BLUEBERRY
  const lightColor = '#337ad4'
  const color = '#1a6ace'
  const darkColor = '#0047a1'

  const SubmittedCard = styled(DashboardCard)`
    background-color: ${lightColor};
    height: 100%;
  `

  const RejectedCard = styled(DashboardCard)`
    background-color: ${color};
    height: 100%;
  `

  const ReadyCard = styled(DashboardCard)`
    background-color: ${darkColor};
    height: 100%;
  `

  return (
    <>
      {/* Reviewer - Needs your review (status: submitted) */}
      <Grid item xs={3}>
        <DisplayCard onClick={scrollTo('#submitted-reviewer')}>
          <SubmittedCard>
            <Typography variant="h3">
              {amounts[STATUS_SUBMITTED] || 0}
            </Typography>
            <Typography variant="h5">Needs your review</Typography>
          </SubmittedCard>
        </DisplayCard>
      </Grid>

      {/* Reviewer - Rejected and awaiting submitter review (status: rejected) */}
      <Grid item xs={3}>
        <DisplayCard onClick={scrollTo('#rejected-reviewer')}>
          <RejectedCard>
            <Typography variant="h3">
              {amounts[STATUS_REJECTED] || 0}
            </Typography>
            <Typography variant="h5">
              Rejected and awaiting submitter review
            </Typography>
          </RejectedCard>
        </DisplayCard>
      </Grid>

      {/* Reviewer - Ready to invoice (status: accepted) */}
      <Grid item xs={3}>
        <DisplayCard onClick={scrollTo('#accepted-reviewer')}>
          <ReadyCard>
            <Typography variant="h3">
              {amounts[STATUS_ACCEPTED] || 0}
            </Typography>
            <Typography variant="h5">Ready To Invoice</Typography>
          </ReadyCard>
        </DisplayCard>
      </Grid>
    </>
  )
}

const InvoiceStatusToolbar = () => {
  const { meta } = useReadyReferrals()

  const { data: statuses } = useApi({
    route: '/referral_invoice_analytics/statuses',
    filter: {
      ReferralInvoiceStatusIDs: JSON.stringify(
        Object.values(REFERRAL_INVOICE_STATUSES)
      ),
    },
  })

  const { access } = useAuth()

  const isSubmitter = access.Roles.ReferralInvoiceSubmitter
  const isReviewer = access.Roles.ReferralInvoiceReviewer

  if (!meta || !statuses)
    return (
      <div style={{ height: '50%', width: '100%', textAlign: 'center' }}>
        <CircularProgress style={{ color: '#0059c9', marginTop: 50 }} />
      </div>
    )

  const referralTotal = meta.Total

  const amounts = statuses.reduce((map: any, stat: any) => {
    map[stat.StatusId] = stat.Amount
    return map
  }, {})

  return (
    <Grid container spacing={2}>
      {isSubmitter && (
        <SubmitterCards referralTotal={referralTotal} amounts={amounts} />
      )}
      {isReviewer && <ReviewerCards amounts={amounts} />}
    </Grid>
  )
}

export default InvoiceStatusToolbar
