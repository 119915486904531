import React from 'react'
import {
  REFERRAL_CREATED,
  REFERRAL_CREATED_FROM_REQUEST,
  REFERRAL_FILE_ADDED,
  REFERRAL_NEW_MSG,
  REFERRAL_REQUEST_CANCELLED,
  REFERRAL_REQUEST_CREATED,
  REFERRAL_REQUEST_FILE_ADDED,
  REFERRAL_REQUEST_UPDATED,
  REFERRAL_UPDATED,
} from '../../actions/ActivityActions'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@material-ui/core'
import dateTime from '../../utils/dateTime'
import { UserAvatar } from '../UserAvatar'
import { MentionDisplayer } from '../Mention'
import { ActivityActions } from '../../actions'

export interface Activity {
  ID: number
  CreatedAt: string
  ReferralID: number
  ReferralRequestID: number | null
  ActivityTypeID: number
  Content: string
  Raw: string
  ActivityPerformerUserID: number
  ActivityPerformerUsername: string
  ConversationMessageID: number
  AckdByUserID: number | null
  AckdByUsername: string | null
  AckdOn: string | null
  PracticeFacilityName: string
  ActivityTitle: string
  RequiresAck: boolean | null
}

interface Props {
  activity: Activity
  allowAck: boolean
  onAck: Function
  briefSubheader?: boolean
}

export default function ActivityItem(props: Props) {
  const hist = useHistory()
  const [isAckd, setIsAckd] = React.useState(false)
  const { activity, allowAck, onAck } = props
  const { ActivityTypeID, AckdOn, ActivityPerformerUsername, ActivityTitle } =
    activity

  const ackable = allowAck && AckdOn === null

  const showViewLink =
    ActivityTypeID === REFERRAL_NEW_MSG || ActivityTypeID === REFERRAL_CREATED

  const ackAndView =
    ActivityTypeID === REFERRAL_CREATED ||
    ActivityTypeID === REFERRAL_REQUEST_CREATED ||
    ActivityTypeID === REFERRAL_CREATED_FROM_REQUEST

  function subHeader(): React.ReactElement {
    if (props.briefSubheader) {
      return (
        <span>
          {dateTime
            .parse(props.activity.CreatedAt)
            .local()
            .format(dateTime.formats.Calendar)}
        </span>
      )
    }

    return (
      <span>
        {dateTime
          .parse(props.activity.CreatedAt)
          .local()
          .format(dateTime.formats.Calendar)}{' '}
        for <Link to={getURI()}>{ActivityTitle}</Link>{' '}
        {!!activity?.PracticeFacilityName &&
          `at ${activity?.PracticeFacilityName}`}
      </span>
    )
  }

  function getTitle(): string {
    const titleMap: { [key: number]: string } = {
      [REFERRAL_FILE_ADDED]: `${ActivityPerformerUsername} uploaded a new file.`,
      [REFERRAL_CREATED]: `${ActivityPerformerUsername} created a new referral.`,
      [REFERRAL_UPDATED]: `${ActivityPerformerUsername} updated an existing referral.`,
      [REFERRAL_NEW_MSG]: `${ActivityPerformerUsername} posted a new message.`,
      [REFERRAL_REQUEST_CREATED]: `${ActivityPerformerUsername} created a new referral request.`,
      [REFERRAL_REQUEST_UPDATED]: `${ActivityPerformerUsername} updated your referral request.`,
      [REFERRAL_REQUEST_CANCELLED]: `${ActivityPerformerUsername} cancelled your referral request.`,
      [REFERRAL_CREATED_FROM_REQUEST]: `${ActivityPerformerUsername} approved your referral request.`,
      [REFERRAL_REQUEST_FILE_ADDED]: `${ActivityPerformerUsername} uploaded a new file.`,
    }
    return titleMap[ActivityTypeID] || ''
  }

  function getURI(): string {
    if (activity.ReferralID) {
      return `/referral/${activity.ReferralID}`
    }
    if (activity.ReferralRequestID) {
      return `/referral_request/${activity.ReferralRequestID}`
    }
    return ``
  }

  async function onClickAck() {
    await ActivityActions.ackActivity({ id: activity.ID })
    setIsAckd(true)
    onAck(activity.ID)
  }

  async function onClickAckAndView() {
    await ActivityActions.ackActivity({ id: activity.ID })
    onAck(activity.ID)
    setIsAckd(true)
    hist.push(getURI())
    return onAck(activity.ID)
  }

  return (
    <Card style={{ margin: '0 0 5px' }}>
      <CardHeader
        avatar={
          <UserAvatar
            firstName={props.activity.ActivityPerformerUsername}
            lastName={props.activity.ActivityPerformerUsername}
          />
        }
        title={getTitle()}
        subheader={subHeader()}
        // titleTypographyProps={{ style: { fontSize: '16px' } }}
      />
      <CardContent style={{ paddingTop: '0px' }}>
        <MentionDisplayer
          variant={ackable ? 'body1' : 'body2'}
          content={activity.Raw}
        />
      </CardContent>
      {ackable && (
        <CardActions>
          {showViewLink && (
            <Button size="small" color="primary" component={Link} to={getURI()}>
              View
            </Button>
          )}
          {!isAckd && !ackAndView && (
            <Button size="small" color="primary" onClick={onClickAck}>
              Acknowledge
            </Button>
          )}
          {!isAckd && ackAndView && (
            <Button size="small" color="primary" onClick={onClickAckAndView}>
              Acknowledge and View
            </Button>
          )}
        </CardActions>
      )}
    </Card>
  )
}
