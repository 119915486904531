import React from 'react'
import { TextField, StandardTextFieldProps } from '@material-ui/core'

interface Props extends Omit<StandardTextFieldProps, 'onChange' | 'error'> {
  onChange(val: string): void
  error?: string | null | undefined
}

export default function PhoneExtField(props: Props) {
  return (
    <TextField
      {...props}
      variant="outlined"
      size="small"
      fullWidth
      onChange={(evt: any) => props.onChange(evt.target.value)}
      error={!!props.error}
      helperText={props.error || undefined}
    />
  )
}

// import styled from 'styled-components'
// import {
//   FormControl,
//   FormHelperText,
//   InputLabel,
//   Input,
//   OutlinedInput,
//   InputBaseComponentProps,
// } from '@material-ui/core'
// import ReferenceTextField from './ReferenceTextField'

// interface Props {
//   label: string | null
//   value: string
//   onChange: (val: string) => void
//   error?: string | null
//   readOnly?: boolean
//   useV2Style?: boolean
//   inputProps?: InputBaseComponentProps
// }

// const PhoneExtContainer = styled.div`
//   .MuiInputBase-input.Mui-disabled {
//     color: initial;
//   }

//   .MuiInput-underline.Mui-disabled:before {
//     border: none;
//   }
// `

// const PhoneExtField: React.FC<Props> = (props: Props) => {
//   const id = React.useId()
//   const { label, value, onChange, error, readOnly } = props

//   if (readOnly) {
//     return <ReferenceTextField label={label || ''} value={value} />
//   }

//   if (props.useV2Style) {
//     return (
//       <PhoneExtContainer>
//         <FormControl fullWidth error={!!error} variant="outlined" size="small">
//           {!!label && (
//             <InputLabel htmlFor={`component-${id}`}>{label}</InputLabel>
//           )}
//           <OutlinedInput
//             id={`component-${id}`}
//             value={value}
//             label={label || undefined}
//             onChange={(e: any) => onChange(e.target.value)}
//             disabled={readOnly}
//             inputProps={props.inputProps}
//             placeholder="Ext"
//           />
//           {error && <FormHelperText>{error}</FormHelperText>}
//         </FormControl>
//       </PhoneExtContainer>
//     )
//   }

//   return (
//     <PhoneExtContainer>
//       <FormControl fullWidth error={!!error}>
//         <InputLabel>{label}</InputLabel>
//         <Input
//           value={value}
//           onChange={(e: any) => onChange(e.target.value)}
//           disabled={readOnly}
//         />
//         {error && <FormHelperText>{error}</FormHelperText>}
//       </FormControl>
//     </PhoneExtContainer>
//   )
// }

// export default PhoneExtField
